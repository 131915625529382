import { handleApi } from "./api";
import { ApiGateWayEndPoint, definedRouteTypes, definedApiMethods } from ".";

export const postTispMaintenance = async (body) => {
    const url = `${ApiGateWayEndPoint}/${definedRouteTypes.tisp_maintenances}`;
    const resp = await handleApi(url, definedApiMethods.post, { form: body });
    return resp;
};

export const postTispQuery = async (query) => {
    const url = `${ApiGateWayEndPoint}/${definedRouteTypes.tisp_query}`;
    const resp = await handleApi(url, definedApiMethods.post, query);
    return resp;
};
