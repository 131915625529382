

import Container from '@mui/material/Container'
import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CloudDownload from '@mui/icons-material/CloudDownload';
import Button from "@mui/material/Button";

import PropTypes from "prop-types";
import { ListItemButton, Skeleton } from '@mui/material';

function FileDropZone({ loading, id, files = [], setFiles, onRemove, disabled }) {  
  
  const onDrop = useCallback(acceptedFiles => {
      setFiles(acceptedFiles)
  }, [setFiles])

  const { getRootProps, getInputProps, isDragActive} = useDropzone({ onDrop })
  if (loading) {
   return <Skeleton sx={{ margin: "auto" }} width="60%" height={200} />
  }

  if (disabled) {
    return (
      <Container >
        <List>
          {files.map((f) => (
            <ListItem key={f.name}>
                <ListItemIcon>
                    <CloudDownload />
                </ListItemIcon>
                <ListItemText primary={f.name} sx={{ color: "gray" }} />
                <Button disabled onClick={() => onRemove(f.name)}> Delete </Button>
            </ListItem>
          ))}
        </List>
        <Container {...getRootProps()} 
            sx={{ backgroundColor: "lightGray", color:"white", minWidth: "600x", minHeight:"150px", borderRadius: "8px"}}>
            <input id={id} {...getInputProps()}/>
            {
              isDragActive ?
                <p>Drop the files here ...</p> :
                <p>Drag and drop some files here, or click to select files</p>
            }
          </Container>
      </Container>
      )
  }

  return (
    <Container>
      <List>
        {files.map((f) => (
        <ListItem key={f.name}>
          <ListItemButton onClick={() => window.open(f.url)}>
          <ListItemIcon>
                <CloudDownload />
            </ListItemIcon>
            <ListItemText primary={f.name} />
        </ListItemButton>
            <Button onClick={() => onRemove(f.name)}> Delete </Button>
        </ListItem>
        ))}
      </List>
      <Container {...getRootProps()} 
          sx={{ backgroundColor: "#1e4d7d", color:"white", minWidth: "600x", minHeight:"150px", borderRadius: "8px"}}>
          <input  id={id} {...getInputProps()} onChange={(e) => setFiles(e.target.files)}/>
          {
            isDragActive ?
              <p>Drop the files here ...</p> :
              <p>Drag and drop some files here, or click to select files</p>
          }
        </Container>
    </Container>
   
  )
}

FileDropZone.propTypes = {
  id:         PropTypes.string,
  files:      PropTypes.array,
  setFiles:   PropTypes.func,
  onRemove:   PropTypes.func,
  remove:     PropTypes.func,
  submit:     PropTypes.func,
  disabled:   PropTypes.bool,
  loading:    PropTypes.bool,
};


export default FileDropZone;
