import { handleApi } from "./api";
import {
    ApiGateWayEndPoint,
    definedRouteTypes,
    definedApiMethods,
    definedWebProbeResources,
} from ".";

const getWebProbeData = async (body) => {
    const url = `${ApiGateWayEndPoint}/${definedRouteTypes.webprobe}/${definedWebProbeResources.entry}`;
    return await handleApi(url, definedApiMethods.post, body);
};

export const findWebProbeData = async (PK, SK) => {
    const url = `${ApiGateWayEndPoint}/${definedRouteTypes.webprobe}`;
    const body = {
        PK,
        SK,
    };
    const resp = await handleApi(url, definedApiMethods.post, body);
    return resp;
};

export const getWebProbeConfig = async () => {
    const url = `${ApiGateWayEndPoint}/${definedRouteTypes.webprobe}/${definedWebProbeResources.config}`;
    return await handleApi(url, definedApiMethods.get);
};

export default getWebProbeData;
