import React from "react";

import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";

import SimpleDateTimePicker from "components/atoms/simple_date_time_picker";
import SimpleSingleSelect from "components/atoms/simple_single_select";

import GridField from "components/molecules/grid_field";

import PropTypes from "prop-types";
import FileDropZone from "components/molecules/file_drop_zone";

import {
  NCE_OS_TYPE_SELECT_VALUES,
  NCE_OS_TRIGGER_SELECT_VALUES,
  NCE_OS_AREA_SELECT_VALUES,
  ON_CALL_SELECT_VALUES,
  CUSTOMER_IMPACT_SELECT_VALUES,
} from "constant_variables/drop_down_values";

import FIELD_SIZE from "constant_variables";
import SubmitButton from "components/atoms/submit_button";

const NceOSForm = ({
  submit,
  files,
  addFile,
  onRemove,
  loading,
  form,
  handleChange,
  disabled,
  editMode,
}) => {
  return (
    <form
      noValidate
      autoComplete="off"
      style={{ width: "80%", margin: "auto" }}
      onSubmit={submit}
    >
      <Grid container spacing={3} alignItems="center">
        <GridField loading={loading} size={FIELD_SIZE.large}>
          <SimpleDateTimePicker
            id="start-time-cetcest"
            label="Start date (UTC)"
            value={form["start-time-cetcest"]}
            handleChange={handleChange}
            disabled={disabled}
          />
        </GridField>
        <GridField loading={loading} size={FIELD_SIZE.large}>
          <SimpleDateTimePicker
            id="end-time-cetcest"
            label="End date (UTC)"
            value={form["end-time-cetcest"]}
            handleChange={handleChange}
            disabled={disabled}
          />
        </GridField>
        <GridField loading={loading} size={FIELD_SIZE.medium}>
          <SimpleSingleSelect
            id="entry_type"
            label="Entry Type"
            value={form["entry_type"]}
            handleChange={handleChange}
            options={NCE_OS_TYPE_SELECT_VALUES}
            disabled={disabled}
          />
        </GridField>
        <GridField loading={loading} size={FIELD_SIZE.medium}>
          <SimpleSingleSelect
            id="trigger"
            label="Trigger"
            value={form["trigger"]}
            handleChange={handleChange}
            options={NCE_OS_TRIGGER_SELECT_VALUES}
            disabled={disabled}
          />
        </GridField>
        <GridField loading={loading} size={FIELD_SIZE.medium}>
          <SimpleSingleSelect
            id="area"
            label="Affected Area"
            value={form["area"]}
            handleChange={handleChange}
            options={NCE_OS_AREA_SELECT_VALUES}
            disabled={disabled}
          />
        </GridField>
        {form["area"] === "Other (Manual Input)" ? (
          <GridField loading={loading} size={FIELD_SIZE.medium}>
            <TextField
              id="area-other"
              label="Area"
              value={form["area_other"]}
              onChange={(e) => {
                handleChange("area_other", e.target.value);
              }}
              fullWidth
              disabled={disabled}
              multiline
            />
          </GridField>
        ) : null}

        <GridField loading={loading} size={FIELD_SIZE.medium}>
          <SimpleSingleSelect
            id="on_call"
            label="On Call Overtime"
            value={form["on_call"]}
            handleChange={handleChange}
            options={ON_CALL_SELECT_VALUES}
            disabled={disabled}
          />
        </GridField>
        <GridField loading={loading} size={FIELD_SIZE.medium}>
          <SimpleSingleSelect
            id="customer_impact"
            label="Customer Impact"
            value={form["customer_impact"]}
            handleChange={handleChange}
            options={CUSTOMER_IMPACT_SELECT_VALUES}
            disabled={disabled}
          />
        </GridField>
        <GridField loading={loading} size={FIELD_SIZE.medium}>
          <SimpleSingleSelect
            id="runbook_needed"
            label="Runbook needed"
            value={form["runbook_needed"]}
            handleChange={handleChange}
            options={CUSTOMER_IMPACT_SELECT_VALUES}
            disabled={disabled}
          />
        </GridField>
        <GridField loading={loading} size={FIELD_SIZE.medium}>
          <TextField
            id="runbook_url"
            label="Runbook URL"
            value={form["runbook_url"]}
            onChange={(e) =>
              handleChange("runbook_url", e.target.value)
            }
            disabled={disabled}
            multiline
          />
        </GridField>
        <GridField loading={loading}>
          <TextField
            id="root_cause_comments"
            label="Root Couse / Comments"
            value={form["root_cause_comments"]}
            onChange={(e) =>
              handleChange("root_cause_comments", e.target.value)
            }
            fullWidth
            disabled={disabled}
            multiline
          />
        </GridField>
        <GridField loading={loading}>
          <TextField
            id="details"
            label="Details"
            value={form["details"]}
            onChange={(e) => handleChange("details", e.target.value)}
            fullWidth
            disabled={disabled}
            multiline
          />
        </GridField>
        <GridField loading={loading}>
          <TextField
            id="solve_closing_comments"
            label="Solve / Closing Comments"
            value={form["solve_closing_comments"]}
            onChange={(e) =>
              handleChange("solve_closing_comments", e.target.value)
            }
            fullWidth
            disabled={disabled}
            multiline
          />
        </GridField>
        <Grid item xs={12} sm={12} lg={12}>
          <FileDropZone
            loading={loading}
            disabled={disabled}
            id="files"
            files={files}
            setFiles={addFile}
            onRemove={onRemove}
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={12} sx={{ textAlign: "center" }}>
          <SubmitButton
            label="submit"
            onClick={submit}
            disabled={!editMode && disabled}
          />
        </Grid>
      </Grid>
    </form>
  );
};

NceOSForm.propTypes = {
  loading: PropTypes.bool,
  form: PropTypes.object,
  submit: PropTypes.func,
  handleChange: PropTypes.func,
  disabled: PropTypes.bool,
  editMode: PropTypes.bool,
  files: PropTypes.arrayOf(PropTypes.any),
  addFile: PropTypes.func,
  onRemove: PropTypes.func,
};

export default NceOSForm;
