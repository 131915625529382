import { handleApi } from "./api";
import {
    ApiGateWayEndPoint,
    definedRouteTypes,
    definedApiMethods,
    definedOnCallResources,
} from ".";

export const postOnCall = async (body) => {
    const url = `${ApiGateWayEndPoint}/${definedRouteTypes.on_call}/${definedOnCallResources.post}`;
    return handleApi(url, definedApiMethods.post, body);
};

export const postOnCallQuery = async (query) => {
    const url = `${ApiGateWayEndPoint}/${definedRouteTypes.on_call}/${definedOnCallResources.query}`;
    const resp = await handleApi(url, definedApiMethods.post, query);
    return resp;
};
