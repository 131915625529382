import React from "react";
import { Box } from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";

function RadioItem({ moveElement, deleteElement, index }) {
    return (
        <>
            <Box>
                <IconButton
                    onClick={() => {
                        moveElement(index, "UP")
                    }}
                >
                    <ArrowDropUpIcon />
                </IconButton>
                <IconButton
                    onClick={() => {
                        moveElement(index, "DOWN")
                    }}
                >
                    <ArrowDropDownIcon />
                </IconButton>
            </Box>
            <IconButton
                onClick={() => {
                    deleteElement(index)
                }}
            >
                <DeleteIcon />
            </IconButton>
        </>
    );
}

RadioItem.propTypes = {
    index: PropTypes.number,
    moveElement: PropTypes.func,
    deleteElement: PropTypes.func
};

export default RadioItem;
