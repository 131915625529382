
import React, { useState, useEffect } from "react";

import { sort_by_key } from "helper";
import SimpleDataGrid from "components/organisms/data_grid";
import SimplePageTitle from "components/atoms/simple_page_title";
import kpi_sla_order from "constant_variables/kpi_sla_fields";
import postQuery from "api/query";

export default function MaintenanceTable() {
  const [showData, setShowData] = useState([]);
  const [loading, setLoading] = useState(true);

  const getViewData = async () => {
    try {
      const resp = await postQuery({
        PK: "kpi-execution",
      }, false);
      const sorted = sort_by_key(resp, "SK", false);
      setShowData(sorted);
    } catch (err) {
      console.trace(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getViewData();
  }, []);
  
  return <React.Fragment>
      <SimplePageTitle title="KPI/SLA Update Table" />
      <SimpleDataGrid loading={loading}
        data={showData}
        order={kpi_sla_order}
        id={false} />
    </React.Fragment>
}
