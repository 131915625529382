import React from "react";

import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import SimpleSingleSelect from "components/atoms/simple_single_select";
import GridField from "components/molecules/grid_field";
import PropTypes from "prop-types";

import {
  ON_CALL_STATUS_SELECT_VALUES,
} from "constant_variables/drop_down_values";

import FIELD_SIZE  from "constant_variables";
import SubmitButton from "components/atoms/submit_button";
import { on_call_field_enum } from "constant_variables/on_call_fields";

const OnCallForm = ({ submit, loading, form, handleChange, disabled }) => {

  return (
    <form
    noValidate
    autoComplete="off"
    style={{ width: "80%", margin: "auto" }}
    onSubmit={submit}
  >
    <Grid container spacing={3} alignItems="center">
      <GridField size={FIELD_SIZE.medium}>
      <Box component="span" sx={{ display: 'block' }}>{`Log author: ${form[on_call_field_enum.email]}`}</Box>
      </GridField>
      <GridField loading={loading} size={FIELD_SIZE.medium}>
        <SimpleSingleSelect
          id="status"
          label="Status"
          value={form[on_call_field_enum.status]}
          handleChange={handleChange}
          options={ON_CALL_STATUS_SELECT_VALUES}
          disabled={disabled}
        />
      </GridField>
      <GridField loading={loading}>
        <TextField
          id="comment"
          label="Comment"
          value={form[on_call_field_enum.comment]}
          multiline
          onChange={(e) =>
            handleChange(on_call_field_enum.comment, e.target.value)
          }
          fullWidth
          disabled={disabled}
        />
      </GridField>
      <Grid item xs={12} sm={12} lg={12} sx={{ textAlign: "center"}}>
          <SubmitButton 
            label="submit"
            onClick={submit}
            disabled={disabled}
          />
      </Grid>
    </Grid>
  </form>
  );
};

OnCallForm.propTypes = {
    loading: PropTypes.bool,
    form: PropTypes.object,
    submit: PropTypes.func,
    handleChange: PropTypes.func,
    disabled: PropTypes.bool,
  };
  

export default OnCallForm;
