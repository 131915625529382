import React, { useState } from "react";

import { toast } from "react-toastify";
import { useRecoilState } from "recoil";
import Container from "@mui/material/Container";

import {
  tisp_maintenance_form_state,
  tisp_maintenance_multiple_form_state,
  user_state,
} from "components/root";
import SimplePageTitle from "components/atoms/simple_page_title";
import TispMaintenanceForm from "components/organisms/tisp_maintenance_form";
import { postTispMaintenance } from "api/tisp_maintenance";
import PropTypes from "prop-types";

import {
  TISP_MAINTENANCE_FIELD_ENUM,
  PROBE_SERVICES,
  WEB_SERVICES_OPTIONS,
  NETWORK_SERVICES_OPTIONS
} from "constant_variables/tisp_maintenance";

import SubmitButton from "components/atoms/submit_button";
import MaintenanceOverviewTable from "components/organisms/overview_table";
import { get_duration_value } from "helper";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#e20074",
    }
  },
})


const TispMaintenanceMultiple = ({ outage = false }) => {
  const [form, setForm] = useRecoilState(tisp_maintenance_form_state);
  const [userState] = useRecoilState(user_state);
  const [maintenances, setMaintenances] = useRecoilState(
    tisp_maintenance_multiple_form_state
  );
  const [loading, setLoading] = useState(false);

  const disable_url  = form["probe"] !== "tisp_web_probes"
  const service_options = disable_url ? NETWORK_SERVICES_OPTIONS[form["probe"]] : WEB_SERVICES_OPTIONS;
  const urls = PROBE_SERVICES[form["service"]] || [];

  let read_only = true;
  if (userState !== null && userState.permissions) {
    read_only = userState.permissions.read_only;
  }

  const handle_form_change = (key, value) => {
    setForm((old) => ({
      ...old,
      [key]: value,
    }));
  };

  const handle_all_change = () => {
    const urls_values = urls.map((u) => u.key);
    setForm((old) => ({
      ...old,
      [TISP_MAINTENANCE_FIELD_ENUM.url_affected]: urls_values,
    }));
  };

  const clear_form = () => {
    setForm(() => ({
      maintenance_starttime: new Date(),
      maintenance_endtime: new Date(),
      comments: "",
      url_affected: [],
      service: "",
      probe: "tisp_web_probes",
      outage,
      duration: 0,
    }));
  };

  const row_value_maker = (form) => {
    return {
      id: `${form.maintenance_starttime.toString()}-${form.probe}`,
      duration: get_duration_value(
        form[TISP_MAINTENANCE_FIELD_ENUM.maintenance_starttime],
        form[TISP_MAINTENANCE_FIELD_ENUM.maintenance_endtime]
      ),
    };
  };

  const onAdd = () => {
    const duration = get_duration_value(
      form[TISP_MAINTENANCE_FIELD_ENUM.maintenance_starttime],
      form[TISP_MAINTENANCE_FIELD_ENUM.maintenance_endtime]
    );
    let allow_add = true;
    if (duration > 86399) {
      allow_add = confirm(
        "Maintenance lasts more that one day! Press confirm to add!"
      );
    }
    if (allow_add) {
      setMaintenances((old) => [...old, { ...form, ...row_value_maker(form) }]);
      setForm((old) => ({
        ...old,
        url_affected: [],
      }));
    }
  };

  const onDelete = (index) => {
    const newMaintenances = maintenances.filter((m, i) => i !== index);
    setMaintenances(newMaintenances);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let error = false;
    try {
      await postTispMaintenance(maintenances);
    } catch (err) {
      console.trace(err);
      error = true;
    } finally {
      setLoading(false);
      setMaintenances([]);
      if (error) toast.error("Failed");
      else toast.success("Success");

      if (!error)
        setForm(() => ({
          maintenance_starttime: new Date(),
          maintenance_endtime: new Date(),
          comments: "",
          url_affected: [],
          service: "",
          probe: "tisp_web_probes",
          outage,
        }));
    }
  };

  return (
    <Container maxWidth="false" sx={{ maxWidth: "1920px" }}>
      <SimplePageTitle
        title={`Add ${outage ? "Tisp Outage" : "Tisp Maintenance"} Entries`}
        color="#e20074"
      />
      <ThemeProvider theme={theme}>
        <TispMaintenanceForm
          form={form}
          loading={loading}
          submit={onAdd}
          handleChange={handle_form_change}
          handleAllChange={handle_all_change}
          disable_url={disable_url}
          service_options={service_options}
          urls={urls}
          disabled={read_only}
          clearForm={clear_form}
        />
        {maintenances && maintenances.length > 0 && (
          <>
            <MaintenanceOverviewTable
              loading={loading}
              maintenances={maintenances}
              handleDelete={onDelete}
            />
            <SubmitButton
              label="Submit maintenances"
              onClick={onSubmit}
              backgroundColor="#e20074"
              disabled={read_only || loading || maintenances.length === 0}
            />
          </>
        )}
      </ThemeProvider>
    </Container>
  );
};

TispMaintenanceMultiple.propTypes = {
  outage: PropTypes.bool,
};

export default TispMaintenanceMultiple;
