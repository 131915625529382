const ibs_api = "https://global.telekom.com/gcp-web-api";
const aax_api = "https://ccint2api-genesis.dev.aax2.net";

const app_clients = {
    bmw: "_20_bmw_",
    rr: "_22_rr_",
    vps: "_26_vps_",
    bmwna: "_27_bmw_",
    dagna: "_29_dagna_",
}

const defiened_tests = (client, country, stage="int") => {
    const app_client = app_clients[client]
    return [
        {
            url: `${ibs_api}/{mandant}/registration/status?dienstId={dienst_id}&email={email}`,
            provider: "ibs",
            method: "get",
            app_client,
            country,
            route_params: {
                email: "nce111@mailinator.com",
                secrets: {
                    dienst_id: {
                        secret_key: `${country}${app_client}${stage}_service_id`
                    },
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                }
            },
        },
        {
            url: `${ibs_api}/{mandant}/registration/register`,
            provider: "ibs",
            method: "post",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                }
            },
            request_params: {
                body: JSON.stringify({
                    email: `1590135@mailinator.com`,
                    address1: "Test street",
                    address2: "1-11",
                    city: "Test City",
                    country: "AT",
                    dateOfBirth: "12/12/1999",
                    name: "1nceTest",
                    password: "123_test_!_TEST_123",
                    salutation: "MR",
                    surname: "GenesisTest",
                    zipCode: "TC-0000",
                    dienstId: "OYHQI3OG",
                    externalCustomerId: "user_token",
                    dienstAgreement: true,
                    mandantAgreement: true,
                    returnUrl: `https://bmw.dev-genesis.easyconnectiot.de/en/at/registration/complete?confirmationToken=test`,
                    userLanguage: "en",
                })
            }
        },
        {
            url: `${ibs_api}/{mandant}/registration/confirm/{confirmation_token}`,
            provider: "ibs",
            method: "get",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
                confirmation_token: "test_token",
            },
        },
        {
            url: `${ibs_api}/{mandant}/services/communication/email/{customer}/confirmationmail`,
            provider: "ibs",
            method: "post",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
                customer: "test_customer",
            },
        },
        {
            url: `${ibs_api}/{mandant}/registration/invite/{dienst_id}/`,
            provider: "ibs",
            method: "put",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    },
                    dienst_id: {
                        secret_key: `${country}${app_client}${stage}_service_id`
                    },
                },
                customer: "test_customer",
            },
        },
        {
            url: `${ibs_api}/{mandant}/registration/customerattributes`,
            provider: "ibs",
            method: "get",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
            },
        },
        {
            url: `${ibs_api}/{mandant}/customers/search/`,
            provider: "ibs",
            method: "post",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
            },
        },
        {
            url: `${ibs_api}/{mandant}/customers/{customer}`,
            provider: "ibs",
            method: "post",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
                customer: "test_customer"
            },
        },
        {
            url: `${ibs_api}/{mandant}/customers/{customer}/constraints`,
            provider: "ibs",
            method: "delete",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
                customer: "test_customer"
            },
        },
        {
            url: `${ibs_api}/{mandant}/customers/{customer}`,
            provider: "ibs",
            method: "delete",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
                customer: "test_customer"
            },
        },
        {
            url: `${ibs_api}/{mandant}/customers/{customer}/accounts/`,
            provider: "ibs",
            method: "get",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
                customer: "test_customer"
            },
        },
        {
            url: `${ibs_api}/{mandant}/customers/{customer}/accounts/{third_party_id}`,
            provider: "ibs",
            method: "delete",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
                customer: "test_customer",
                third_party_id: "test_id"
            },
        },
        {
            url: `${ibs_api}/{mandant}/customers/{customer}/{mobile_phone_number}`,
            provider: "ibs",
            method: "get",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
                customer: "test_customer",
                mobile_phone_number: "11111111"
            },
        },
        {
            url: `${ibs_api}/{mandant}/customers/{customer}/{mobile_phone_number}`,
            provider: "ibs",
            method: "put",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
                customer: "test_customer",
                mobile_phone_number: "11111111"
            },
        },
        {
            url: `${ibs_api}/{mandant}/customers/{customer}/{mobile_phone_number}`,
            provider: "ibs",
            method: "put",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
                customer: "test_customer",
                mobile_phone_number: "11111111"
            },
        },
        {
            url: `${ibs_api}/{mandant}/customers/{customer}/{mobile_phone_number}/{token}`,
            provider: "ibs",
            method: "put",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
                customer: "test_customer",
                mobile_phone_number: "11111111",
                token: "test_token"
            },
        },
        {
            url: `${ibs_api}/{mandant}/customers/{customer}/requiredAttributes`,
            provider: "ibs",
            method: "get",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
                customer: "test_customer",
            },
        },
        {
            url: `${ibs_api}/{mandant}/customers/{customer}/missingAttributes`,
            provider: "ibs",
            method: "get",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
                customer: "test_customer",
            },
        },
        {
            url: `${ibs_api}/{mandant}/customers/{customer}/attributeConfiguration`,
            provider: "ibs",
            method: "get",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
                customer: "test_customer",
            },
        },
        {
            url: `${ibs_api}/{mandant}/customers/{customer}/addresses/`,
            provider: "ibs",
            method: "get",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
                customer: "test_customer",
            },
        },
        {
            url: `${ibs_api}/{mandant}/customers/{customer}/addresses/{address_id}`,
            provider: "ibs",
            method: "get",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
                customer: "test_customer",
                address_id: "test_address_id"
            },
        },
        {
            url: `${ibs_api}/{mandant}/customers/{customer}/addresses/{address_id}`,
            provider: "ibs",
            method: "get",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
                customer: "test_customer",
                address_id: "test_address_id"
            },
        },
        {
            url: `${ibs_api}/{mandant}/customers/{customer}/addresses/`,
            provider: "ibs",
            method: "post",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
                customer: "test_customer",
            },
        },
        {
            url: `${ibs_api}/{mandant}/customers/{customer}/addresses/{address_id}`,
            provider: "ibs",
            method: "put",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
                customer: "test_customer",
                address_id: "test_address_id"
            },
        },
        {
            url: `${ibs_api}/{mandant}/customers/{customer}/addresses/{address_id}`,
            provider: "ibs",
            method: "delete",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
                customer: "test_customer",
                address_id: "test_address_id"
            },
        },
        {
            url: `${ibs_api}/{mandant}/customers/{customer}/agreements/`,
            provider: "ibs",
            method: "get",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
                customer: "test_customer",
            },
        },
        {
            url: `${ibs_api}/{mandant}/customers/{customer}/agreements/{agreement_id}`,
            provider: "ibs",
            method: "get",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
                customer: "test_customer",
                agreement_id: "test_id"
            },
        },
        {
            url: `${ibs_api}/{mandant}/customers/{customer}/agreements/{agreement_id}`,
            provider: "ibs",
            method: "put",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
                customer: "test_customer",
                agreement_id: "test_id"
            },
        },
        {
            url: `${ibs_api}/{mandant}/customers/{customer}/agreements/{agreement_id}`,
            provider: "ibs",
            method: "delete",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
                customer: "test_customer",
                agreement_id: "test_id"
            },
        },
        {
            url: `${ibs_api}/{mandant}/customers/{customer}/registrations/`,
            provider: "ibs",
            method: "get",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
                customer: "test_customer",
            },
        },
        {
            url: `${ibs_api}/{mandant}/customers/{customer}/registrationstatus`,
            provider: "ibs",
            method: "get",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
                customer: "test_customer",
            },
        },
        {
            url: `${ibs_api}/{mandant}/customers/{customer}/registrations/{registration_id}`,
            provider: "ibs",
            method: "delete",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
                customer: "test_customer",
                registration_id: "test_id"
            },
        },
        {
            url: `${ibs_api}/{mandant}/customers/{customer}/accountAttributes`,
            provider: "ibs",
            method: "get",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
                customer: "test_customer",
            },
        },
        {
            url: `${ibs_api}/{mandant}/customers/{customer}/accountAttributes/{account_attribute_id}`,
            provider: "ibs",
            method: "get",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
                customer: "test_customer",
                account_attribute_id: "test_id"
            },
        },
        {
            url: `${ibs_api}/{mandant}/customers/{customer}/accountAttributes`,
            provider: "ibs",
            method: "post",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
                customer: "test_customer",
            },
        },
        {
            url: `${ibs_api}/{mandant}/customers/{customer}/accountAttributes/{account_attribute_id}`,
            provider: "ibs",
            method: "put",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
                customer: "test_customer",
                account_attribute_id: "test_id"
            },
        },
        {
            url: `${ibs_api}/{mandant}/customers/{customer}/accountAttributes/{account_attribute_id}`,
            provider: "ibs",
            method: "delete",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
                customer: "test_customer",
                account_attribute_id: "test_id"
            },
        },
        {
            url: `${ibs_api}/{mandant}/accountAttributes/{dienstId}/search/`,
            provider: "ibs",
            method: "post",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    },
                    dienst_id: {
                        secret_key: `${country}${app_client}${stage}_service_id`
                    },
                },
            },
        },
        {
            url: `${ibs_api}/{mandant}/customers/{customer}/lock`,
            provider: "ibs",
            method: "put",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
                customer: "test_customer"
            },
        },
        {
            url: `${ibs_api}/{mandant}/customers/{customer}/lock`,
            provider: "ibs",
            method: "delete",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
                customer: "test_customer"
            },
        },
        {
            url: `${ibs_api}/{mandant}/password/validate`,
            provider: "ibs",
            method: "post",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
            },
        },
        {
            url: `${ibs_api}/{mandant}/password/recover`,
            provider: "ibs",
            method: "post",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
            },
        },
        {
            url: `${ibs_api}/{mandant}/password/confirm`,
            provider: "ibs",
            method: "post",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
            },
        },
        {
            url: `${ibs_api}/{mandant}/password/policy`,
            provider: "ibs",
            method: "post",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
            },
        },
        {
            url: `${ibs_api}/{mandant}/customers/{customer}/password`,
            provider: "ibs",
            method: "put",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
            },
        },
        {
            url: `${ibs_api}/{mandant}/customers/{customer}/login`,
            provider: "ibs",
            method: "put",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
                customer: "test_customer"
            },
        },
        {
            url: `${ibs_api}/{mandant}/customers/{customer}/login/{token}`,
            provider: "ibs",
            method: "put",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
                customer: "test_customer"
            },
        },
        {
            url: `${ibs_api}/{mandant}/customers/{customer}/twofactor/status`,
            provider: "ibs",
            method: "get",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
                customer: "test_customer"
            },
        },
        {
            url: `${ibs_api}/{mandant}/authentication/twofactor/totp`,
            provider: "ibs",
            method: "get",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
            },
        },
        {
            url: `${ibs_api}/{mandant}/customers/{customer}/twofactor/totp/uri`,
            provider: "ibs",
            method: "post",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
            },
        },
        {
            url: `${ibs_api}/{mandant}/customers/{customer}/twofactor/totp/`,
            provider: "ibs",
            method: "post",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
            },
        },
        {
            url: `${ibs_api}/{mandant}/authentication/twofactor/totp/{code}`,
            provider: "ibs",
            method: "post",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
                code: "123"
            },
        },
        {
            url: `${ibs_api}/{mandant}/customers/{customer}/attributes/`,
            provider: "ibs",
            method: "get",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
                customer: "test_customer"
            },
        },
        {
            url: `${ibs_api}/{mandant}/customers/{customer}/attributes/{attributeType}/{attributeKey}/`,
            provider: "ibs",
            method: "get",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
                customer: "test_customer",
                attributeType: "test_type",
                attributeKey: "123"
            },
        },
        {
            url: `${ibs_api}/{mandant}/customers/{customer}/attributes/{attributeType}/`,
            provider: "ibs",
            method: "get",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
                customer: "test_customer",
                attributeType: "test_type"
            },
        },
        {
            url: `${ibs_api}/{mandant}/customers/{customer}/attributes/`,
            provider: "ibs",
            method: "post",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
                customer: "test_customer",
            },
        },
        {
            url: `${ibs_api}/{mandant}/customers/{customer}/attributes/{attributeType}/`,
            provider: "ibs",
            method: "post",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
                customer: "test_customer",
                attributeType: "test_type"
            },
        },
        {
            url: `${ibs_api}/{mandant}/customers/{customer}/attributes/{attributeType}/`,
            provider: "ibs",
            method: "delete",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
                customer: "test_customer",
                attributeType: "test_type"
            },
        },
        {
            url: `${ibs_api}/{mandant}/customers/{customer}/attributes/{attributeType}/{attributeKey}/`,
            provider: "ibs",
            method: "delete",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
                customer: "test_customer",
                attributeType: "test_type",
                attributeKey: "123"
            },
        },
        {
            url: `${ibs_api}/{mandant}/customers/{customer}/contactdata/`,
            provider: "ibs",
            method: "get",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
                customer: "test_customer",
            },
        },
        {
            url: `${ibs_api}/{mandant}/customers/{customer}/contactdata/`,
            provider: "ibs",
            method: "post",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
                customer: "test_customer",
            },
        },
        {
            url: `${ibs_api}/{mandant}/customers/{customer}/paymentdata/`,
            provider: "ibs",
            method: "get",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
                customer: "test_customer",
            },
        },
        {
            url: `${ibs_api}/{mandant}/customers/{customer}/paymentdata/`,
            provider: "ibs",
            method: "post",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
                customer: "test_customer",
            },
        },
        {
            url: `${ibs_api}/{mandant}/customers/{customer}/authenticationInfo`,
            provider: "ibs",
            method: "get",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
                customer: "test_customer",
            },
        },
        {
            url: `${ibs_api}/{mandant}/products/`,
            provider: "ibs",
            method: "get",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
            },
        },
        {
            url: `${ibs_api}/{mandant}/products/{dienst}`,
            provider: "ibs",
            method: "get",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
                deinst: "test_dienst"
            },
        },
        {
            url: `${ibs_api}/{mandant}/products/{dienst}/{productId}`,
            provider: "ibs",
            method: "get",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
                deinst: "test_dienst",
                productId: "123"
            },
        },
        {
            url: `${ibs_api}/{mandant}/products/{dienst}/bookableProducts/{productId}`,
            provider: "ibs",
            method: "get",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
                deinst: "test_dienst",
                productId: "123"
            },
        },
        {
            url: `${ibs_api}/{mandant}/products/{dienst}`,
            provider: "ibs",
            method: "post",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
                deinst: "test_dienst"
            },
        },
        {
            url: `${ibs_api}/{mandant}/products/{dienst}/{productId}`,
            provider: "ibs",
            method: "put",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
                deinst: "test_dienst",
                productId: "123"
            },
        },
        {
            url: `${ibs_api}/{mandant}/products/{dienst}/{productId}/bookingvalidity`,
            provider: "ibs",
            method: "get",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
                deinst: "test_dienst",
                productId: "123"
            },
        },
        {
            url: `${ibs_api}/{mandant}/products/{dienst}/{productId}`,
            provider: "ibs",
            method: "delete",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
                deinst: "test_dienst",
                productId: "123"
            },
        },
        {
            url: `${ibs_api}/{mandant}/products/{dienst}/{productId}/constraints`,
            provider: "ibs",
            method: "delete",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
                deinst: "test_dienst",
                productId: "123"
            },
        },
        {
            url: `${ibs_api}/{mandant}/products/{dienst}/{productId}/bookingUrl`,
            provider: "ibs",
            method: "get",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
                deinst: "test_dienst",
                productId: "123"
            },
        },
        {
            url: `${ibs_api}/{mandant}/checkout/transactions/`,
            provider: "ibs",
            method: "post",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
            },
        },
        {
            url: `${ibs_api}/{mandant}/checkout/transactions/reserve`,
            provider: "ibs",
            method: "post",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
            },
        },
        {
            url: `${ibs_api}/{mandant}/checkout/transactions/{tid}/finalize`,
            provider: "ibs",
            method: "get",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
                tid: "123"
            },
        },
        {
            url: `${ibs_api}/{mandant}/checkout/transactions/{tid}/result`,
            provider: "ibs",
            method: "get",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
                tid: "123"
            },
        },
        {
            url: `${ibs_api}/{mandant}/checkout/transactions/{tid}/cancel`,
            provider: "ibs",
            method: "delete",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
                tid: "123"
            },
        },
        {
            url: `${ibs_api}/{mandant}/checkout/transactions/{tid}`,
            provider: "ibs",
            method: "get",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
                tid: "123"
            },
        },
        {
            url: `${ibs_api}/{mandant}/contracts/{customer}/`,
            provider: "ibs",
            method: "get",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
                customer: "test_customer"
            },
        },
        {
            url: `${ibs_api}/{mandant}/contracts/{customer}/{contractId}`,
            provider: "ibs",
            method: "get",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
                customer: "test_customer",
                constractId: "123"
            },
        },
        {
            url: `${ibs_api}/{mandant}/contracts/{customer}/{contractId}/cancellation`,
            provider: "ibs",
            method: "post",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
                customer: "test_customer",
                constractId: "123"
            },
        },
        {
            url: `${ibs_api}/{mandant}/contracts/{customer}/{contractId}/cancellation`,
            provider: "ibs",
            method: "delete",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
                customer: "test_customer",
                constractId: "123"
            },
        },
        {
            url: `${ibs_api}/{mandant}/contracts/{customer}/{contractId}/lock`,
            provider: "ibs",
            method: "put",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
                customer: "test_customer",
                constractId: "123"
            },
        },
        {
            url: `${ibs_api}/{mandant}/contracts/{customer}/{contractId}/lock`,
            provider: "ibs",
            method: "delete",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
                customer: "test_customer"
            },
        },
        {
            url: `${ibs_api}/{mandant}/contracts/{customer}/{contractId}/payments/`,
            provider: "ibs",
            method: "get",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
                customer: "test_customer",
                constractId: "123"
            },
        },
        {
            url: `${ibs_api}/{mandant}/contracts/{customer}/{contractId}/payments/{claimId}/collections/`,
            provider: "ibs",
            method: "get",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
                customer: "test_customer",
                constractId: "123",
                claimId: "123"
            },
        },
        {
            url: `${ibs_api}/{mandant}/authentication/validatePassword/`,
            provider: "ibs",
            method: "post",
            app_client,
            country,
            route_params: {
                secrets: {
                    mandant: {
                        secret_key: `${client}_${stage}`
                    }
                },
                customer: "test_customer",
                constractId: "123",
                claimId: "123"
            },
        },
        {
            url: `${aax_api}/genesis/subscribers?vin={vin}`,
            provider: "aax",
            method: "get",
            app_client,
            country,
            route_params: {
                "vin": "TR231418755000011900842035",
            },
        },
        {
            url: `${aax_api}/genesis/countries/{country}/oem/{oem_id}/1ca-availability`,
            provider: "aax",
            method: "get",
            app_client,
            country,
            route_params: {
                country,
                oem_id: "test_id",
            },
        },
        {
            url: `${aax_api}/genesis/countries/{country}/oem/{oem_id}/registration-type`,
            provider: "aax",
            method: "get",
            app_client,
            country,
            route_params: {
                country,
                oem_id: "test_id",
            },
        },
        {
            url: `${aax_api}/genesis/subscribers/{subscriber_id}`,
            provider: "aax",
            method: "get",
            app_client,
            country,
            route_params: {
                subscriber_id: "test_id",
            },
        },
        {
            url: `${aax_api}/genesis/subscribers/{subscriber_id}`,
            provider: "aax",
            method: "patch",
            app_client,
            country,
            route_params: {
                subscriber_id: "test_id",
            },
        },
        {
            url: `${aax_api}/genesis/subscribers/{subscriber_id}/1ca`,
            provider: "aax",
            method: "post",
            app_client,
            country,
            route_params: {
                subscriber_id: "test_id",
            },
        },
        {
            url: `${aax_api}/genesis/subscribers/{subscriber_id}/oer`,
            provider: "aax",
            method: "post",
            app_client,
            country,
            route_params: {
                subscriber_id: "test_id",
            },
        },
    ]
}


export default defiened_tests;