/* eslint-disable no-unused-vars */

import React from "react";
import { Box, Button, FormControl } from "@mui/material";
import TextFieldItem from "../../molecules/customer_satisfaction/textFieldItem";
import RatingItem from "../../molecules/customer_satisfaction/ratingItem";
import BuilderHelper from "../../molecules/customer_satisfaction/builderHelper";
import DropdownItem from "../../molecules/customer_satisfaction/dropdownItem";
import RadioItem from "../../molecules/customer_satisfaction/radioItem"
import AddIcon from "@mui/icons-material/Add";
import Grid from "@mui/material/Grid";
import FormGroup from "@mui/material/FormGroup";

import IconButton from "@mui/material/IconButton";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import FormLabel from "@mui/material/FormLabel";
import { saveTemplate, getTemplate } from "../../../api/customer_satisfaction";
import { useLocation, useHistory } from "react-router-dom";
import DropDownOptions from "../../molecules/customer_satisfaction/options";
import OptionAddBlock from "../../molecules/customer_satisfaction/optionAddBlock";

const uuid = require("uuid");

function Builder() {
    const [questions, setQuestions] = React.useState([]);
    const [templateName, setTemplateName] = React.useState("");
    const [option, setOption] = React.useState("");
    const [questionType, setQuestionType] = React.useState("Text");
    const [questionLabel, setQuestionLabel] = React.useState("");
    const [options, setOptions] = React.useState([]);

    const location = useLocation();
    const history = useHistory();

    const params = new URLSearchParams(location.search);
    const id = params.get("id");

    React.useEffect(() => {
        const handleGetTemplate = async () => {
            try {
                const resp = await getTemplate({
                  id,
                });
                setQuestions(resp.questions);
                setTemplateName(`${resp.templateName} `);
            } catch (err) {
                console.log(err);
            }
        };
        if (id) {
            handleGetTemplate();
        }
    }, []);

    const updateQuestion = (question) => {
        const { questionId } = question;
        questions.forEach((element, index) => {
            if (element.questionId == questionId) {
                questions[index] = question;
                setQuestions([...questions]);
                return;
            }
        });
    }

    const addQuestion = () => {
        setQuestions(old => [
            ...old,
            {
                questionId: uuid.v4(),
                options: options ? options : undefined,
                questionLabel,
                questionType,
                value: options[0] ? options[0] : "",
                setValue: function (value) {
                    this.value = value;
                },
            },
        ]);
    };

    const deleteQuestion = (index) => {
        const buffer = [...questions];
        buffer.splice(index, 1);
        setQuestions(buffer);
    };

    const deleteOption = (index) => {
        const buffer = [...options];
        buffer.splice(index, 1);
        setOptions(buffer);
    };

    const moveOption = (index, direction) => {
        const buffer = [...options];
        const temp = buffer[index];
        switch (direction) {
            case "DOWN":
                if (index != buffer.length - 1) {
                    buffer[index] = buffer[index + 1];
                    buffer[index + 1] = temp;
                }
                break;
            case "UP":
                if (index != 0) {
                    buffer[index] = buffer[index - 1];
                    buffer[index - 1] = temp;
                }
                break;
        }
        setOptions([...buffer]);
    };

    const moveQuestion = (index, direction) => {
        const buffer = [...questions];
        const temp = buffer[index];
        switch (direction) {
            case "DOWN":
                if (index != buffer.length - 1) {
                    buffer[index] = buffer[index + 1];
                    buffer[index + 1] = temp;
                }
                break;
            case "UP":
                if (index != 0) {
                    buffer[index] = buffer[index - 1];
                    buffer[index - 1] = temp;
                }
                break;
        }
        setQuestions([...buffer]);
    };


    const handleSaveTemplate = async () => {
        await saveTemplate({
          templateName,
          questions,
          id
        });
    };

    return (

        <Box
            sx={{
                // position: "relative",
                // left: "50%",
                display: "flex",
                flexDirection: "column",
                marginTop: "35px",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <Box
                sx={{
                    color: "rgba(0, 0, 0, 0.6)",
                    borderRadius: "10px",
                    backgroundColor: "rgb(255, 255, 255)",
                    padding: ["45px", "45px"],
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    minWidth: 500,
                    maxWidth: 500,
                }}
            >
                <img src="/ncelogo.png" width={150} height={150}></img>
                <Box
                    sx={{
                        fontSize: "1.2rem",
                        fontFamily: ["Arial"],
                        fontWeight: 350,
                        letterSpacing: "0.02em",
                    }}
                >
                    CUSTOMER SATISFACTION FORM BUILDER
                </Box>
                <Box
                    sx={{
                        width: "100%"
                    }}>
                    <form
                    >
                        <FormGroup >

                            <FormControl sx={{
                                marginTop: "20px"
                            }}>
                                <FormLabel
                                    sx={{
                                        color: "rgba(0, 0, 0, 0.6) !important",
                                    }}
                                >
                                    Template name
                                </FormLabel>
                                <TextField
                                    sx={{
                                        marginBottom: "30px",
                                    }}
                                    id="standard-textarea"
                                    multiline
                                    inputProps={{ maxLength: 4000 }}
                                    variant="standard"
                                    defaultValue={templateName}
                                    onChange={(e) => {
                                        setTemplateName(e.target.value);
                                    }}
                                />
                            </FormControl>


                            {questions.map((question, index) => {
                                switch (question.questionType) {
                                    case "Text":
                                        return (
                                            <FormControl key={`${index}-form-control`}>
                                                <Grid
                                                    container
                                                    rowSpacing={2}
                                                    columns={{ xs: 6, md: 6 }}
                                                    columnSpacing={{ xs: 3, sm: 3, md: 3 }}
                                                >
                                                    <Grid item xs={5} key={`${index}-text-field`}>
                                                        <TextFieldItem question={question} updateQuestion={updateQuestion} />
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        key={`${index}-text-field-helper`}
                                                        xs={1}
                                                        sx={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                        }}
                                                    >
                                                        <BuilderHelper
                                                            moveElement={moveQuestion}
                                                            deleteElement={deleteQuestion}
                                                            index={index}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </FormControl>
                                        );
                                    case "Rating":
                                        return (
                                            <FormControl key={`${index}-form-control`}>
                                                <Grid
                                                    container
                                                    rowSpacing={2}
                                                    columns={{ xs: 6, md: 6 }}
                                                    columnSpacing={{ xs: 3, sm: 3, md: 3 }}
                                                >
                                                    <Grid item xs={5} key={`${index}-Rating`}>
                                                        <RatingItem question={question} updateQuestion={updateQuestion} />
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={1}
                                                        key={`${index}-Rating-helper`}
                                                        sx={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                        }}
                                                    >
                                                        <BuilderHelper
                                                            moveElement={moveQuestion}
                                                            deleteElement={deleteQuestion}
                                                            index={index}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </FormControl>
                                        );
                                    case "Dropdown":
                                        return (
                                            <FormControl key={`${index}-form-control`}>
                                                <Grid
                                                    container
                                                    rowSpacing={2}
                                                    columns={{ xs: 6, md: 6 }}
                                                    columnSpacing={{ xs: 3, sm: 3, md: 3 }}
                                                >
                                                    <Grid item xs={5} key={`${index}-dropdown`}>
                                                        <DropdownItem question={question} updateQuestion={updateQuestion} />
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        key={index}
                                                        xs={1}
                                                        sx={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                        }}
                                                    >
                                                        <BuilderHelper
                                                            moveElement={moveQuestion}
                                                            deleteElement={deleteQuestion}
                                                            index={index}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </FormControl>
                                        );
                                    case "Radio":
                                        return (
                                            <FormControl key={`${index}-form-control`}>
                                                <Grid
                                                    container
                                                    rowSpacing={2}
                                                    columns={{ xs: 6, md: 6 }}
                                                    columnSpacing={{ xs: 3, sm: 3, md: 3 }}
                                                >
                                                    <Grid item xs={5} key={`${index}-dropdown`}>
                                                        <RadioItem question={question} updateQuestion={updateQuestion} />
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        key={index}
                                                        xs={1}
                                                        sx={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                        }}
                                                    >
                                                        <BuilderHelper
                                                            moveElement={moveQuestion}
                                                            deleteElement={deleteQuestion}
                                                            index={index}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </FormControl>
                                        );
                                }
                            })}
                            <FormControl>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    sx={{ marginTop: "5px" }}
                                    onClick={async (e) => {
                                        e.preventDefault();
                                        await handleSaveTemplate();
                                        history.push("/customer-satisfaction/templates");
                                    }}
                                >
                                    Save Template
                                </Button>
                            </FormControl>
                        </FormGroup>
                    </form>
                </Box>
            </Box>
            <Box
                sx={{
                    color: "rgba(0, 0, 0, 0.6)",
                    borderRadius: "10px",
                    backgroundColor: "rgb(255, 255, 255)",
                    padding: ["45px", "45px"],
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "40px",

                    minWidth: 500,
                    maxWidth: 500,
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%"
                    }}
                >
                    <TextField
                        id="outlined-basic"
                        label="Label"
                        variant="outlined"
                        sx={{
                            marginRight: "10px",
                            width: "100%"
                        }}
                        onChange={(event) => {
                            setQuestionLabel(event.target.value);
                        }}
                    />
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={questionType}
                        onChange={(event) => {
                            setQuestionType(event.target.value);
                        }}
                    >
                        <MenuItem value={"Text"}>Text</MenuItem>
                        <MenuItem value={"Rating"}>Rating</MenuItem>
                        <MenuItem value={"Dropdown"}>Dropdown</MenuItem>
                        <MenuItem value={"Radio"}>Radio</MenuItem>
                    </Select>
                    <IconButton
                        onClick={() => {
                            setOptions([])
                            addQuestion();
                        }}
                    >
                        <AddIcon />
                    </IconButton>
                </Box>
                {(questionType == "Dropdown" || questionType == "Radio") ? (
                    <>
                        {options.map((item, index) => {
                            return (
                                <DropDownOptions
                                    item={item}
                                    key={`${index}-dropdown-option`}
                                    index={index}
                                    moveOption={moveOption}
                                    deleteOption={deleteOption}

                                />
                            )
                        })}
                        <OptionAddBlock
                            options={options}
                            option={option}
                            setOptions={setOptions}
                            setOption={setOption}
                        />
                    </>
                ) : null}

            </Box>

        </Box>
    );
}

export default Builder;