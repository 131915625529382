import React, { useEffect, useState} from "react";
import { utcToZonedTime, format } from "date-fns-tz";
import PropTypes from 'prop-types'
import ReactCountryFlag from "react-country-flag";
import Clock from "react-clock";
import 'react-clock/dist/Clock.css';

const ClockComponent = ({ timezone, flag, city, appBarClock = false }) => {
    const [clockState, setClockState] = useState(new Date());
    const pattern = appBarClock ? 'HH:mm' :'EEEE HH:mm';
    useEffect(() => {
        const interval = setInterval(() => setClockState(new Date()), 1000);
        return () => {
          clearInterval(interval);
        };
      }, []);
    
    const zoned_time = utcToZonedTime(clockState, timezone)
    const output = format(zoned_time, pattern, { timeZone: timezone});
    const gmt_output = format(zoned_time, '\'GMT\' XXX', {timeZone: timezone})


    // const seconds = zoned_time.getSeconds();
    // console.log(seconds)
    // const secondsStyle = {
    //     transform: `rotate(${seconds * 6 + 90}deg)`
    //   };

    if (appBarClock) {
        return (
            <div style={{margin: 10}}>
                <span>
                    {`${flag} ${output}`}
                </span>
            </div>
        )
    }
    return (
    <div style={{margin: "0px auto", display: "flex", flexDirection: "column", alignItems: "center"}}>
        <Clock value={zoned_time} />
        <div style={{fontSize: "20px", textAlign: "center"}}>
            {output}
        </div>
        <div style={{textAlign: "center"}}>
            <ReactCountryFlag style ={{ lineHeight: "0px", fontSize: "20px"}} countryCode={flag}/> <span style={{fontSize: "20px"}}>{city}, {gmt_output}</span> 
        </div>
    </div>
    )

}

ClockComponent.propTypes = {
    timezone: PropTypes.string,
    flag: PropTypes.string,
    city: PropTypes.string,
    appBarClock: PropTypes.bool,
  }

export default ClockComponent;