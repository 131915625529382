import React, { useEffect, useState } from "react";

import { useRecoilState } from "recoil";

import { on_call_form_state, user_state } from "components/root";
import OnCallForm from "components/organisms/on_call_form";
import SimplePageTitle from "components/atoms/simple_page_title";
import { postOnCall } from "api/on_call";

import { toast } from "react-toastify";
import { Alert, AlertTitle, Link, List, ListItem } from "@mui/material";
import { Box } from "@mui/system";


const OnCall = () => {

  const [loading, setLoading] = useState(false);
  const [form, setForm] = useRecoilState(on_call_form_state);
  const [userState] = useRecoilState(user_state);
  const { permissions: {read_only}, user } = userState;

  useEffect(() => {
    setForm((prevState) => {
        return {...prevState, email: user}
    })
  }, [user, setForm]);

  const handle_form_change = (key, value) => {
    setForm((old) => ({
      ...old,
      [key]: value
    }));
  };

  const submit = async (e) => {
      e.preventDefault();
      if(form.status === 'Outage' && !form.comment) {
        return toast.error('Please enter comment!')
      }
      setLoading(true);
      try {
          await postOnCall(form)
          setForm(() => on_call_form_state);
          toast.success("On Call entry added") 
        } catch(err) {
            console.trace(err)
            toast.error("Failed") 
        } finally {
            setLoading(false);
        }
    };

  return (
    <React.Fragment>
      <SimplePageTitle title="New On Call Entry" />
      <Box sx={{mb: 3}}>
          <Alert severity="info">
            <AlertTitle>
              Important information
            </AlertTitle>
              <span>
              Before submitting the log make sure you have checked:
              <List>
                <ListItem>
                  Grafana dashboards for any outages.
                </ListItem>
                <ListItem>
                  Frontend on-call slack channel for any alerts.
                </ListItem>
              </List>
              Also useful information for on-call can be found at this <Link target="_blank" href="https://miro.com/app/board/uXjVPyc2Ec4=/">Miro board</Link>
              </span>
          </Alert>
        </Box>
      <OnCallForm
        form={form}
        loading={loading}
        submit={submit}
        handleChange={handle_form_change}
        disabled={read_only}
      /> 
    </React.Fragment>

  );
};

export default OnCall;
