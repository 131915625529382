import * as React from "react";
import { Switch, Route } from "react-router-dom";

import { createTheme, ThemeProvider } from "@mui/material/styles";

import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";

import MenuDrawer from "components/organisms/drawer";
import NavigationBar from "components/organisms/app_bar";

import HomePage from "components/pages/home";
import Traces from "components/pages/trace";
import MaintenancePage from "components/pages/maintenance";
import MaintenanceTable from "components/pages/maintenance_table";
import UpdateKpiSla from "components/pages/update_kpi_sla";
// tisp maintenance
import TispMaintenancePage from "components/pages/tisp_maintenance";
import TispMaintenanceTable from "components/pages/tisp_maintenance_table";

import LogbookPage from "components/pages/logbook";
import LogbookTable from "components/pages/logbook_table";
import LogbookView from "components/pages/logbook_view";
import WebProbe from "components/pages/webprobe";
import ProbeView from "components/pages/webprobe_view";
// import Route from 'components/atoms/private_route';
import LoginPage from "components/pages/login";
import LogbookDownPage from "components/pages/login/logbook_update";

import { useRecoilValue } from "recoil";
import { user_state } from "components/root";
import JiraImport from "components/pages/import";
import MarketingPage from "components/pages/marketing";
import MarketingView from "components/pages/marketing_view";
import MarketingTable from "components/pages/marketing_table";
import MarketingImport from "components/pages/marketing_import";
import RecentTraceTable from "components/pages/recent_traces";
import TraceView from "components/pages/traces_view";
import AdminTable from "components/pages/admin_table";
//forecast
import ForecastPage from "components/pages/forecast";
import ForecastView from "components/pages/forecast_view";
import ForecastTable from "components/pages/forecast_table";
import ForecastImport from "components/pages/forecast_import";
import JiraPage from "components/pages/jira_page";
//Contract
import CotractImport from "components/pages/contract_import";
//API Probes
import ApiProbes from "components/pages/api_probes";
import ApiProbeResults from "components/pages/api_probe_results";
// NceChanges
import NceChangesTable from "components/pages/nce_changes_table";
// NceOS 
import NceOS from "components/pages/nce_os";
import NceOSTable from "components/pages/nce_os_table";
import NceOSView from "components/pages/nce_os_view";

//Customer satisfaction
import SatisfactionTemplates from "components/pages/customer_satisfaction_templates";
import Builder from "components/pages/customer_satisfaction_template_builder";
import OnCall from "components/pages/on_call";
import OnCallTable from "components/pages/on_call_table";

const down = false;

const hosts = ["www.logbook.1nce-tech.net", "logbook.1nce-tech.net"];
const mdTheme = createTheme({
  navigationBar: {
    background: hosts.includes(window.location.hostname)
      ? "#1e4d7d"
      : "repeating-linear-gradient(-45deg, black, black 15px, #d99110 15px, #d99110 30px)",
  },
});

function DashboardContent() {
  const ACTIVE_LOGIN = down ? LogbookDownPage : LoginPage;
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const user = useRecoilValue(user_state);

  if (!user) {
    return (
      <ThemeProvider theme={mdTheme}>
        <Route path={["/login?", "/"]} render={() => <ACTIVE_LOGIN />} />
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={mdTheme}>
      <Switch>
        <Route path={"/login?"}>
          <ACTIVE_LOGIN />
        </Route>
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <NavigationBar toggleDrawer={toggleDrawer} open={open} />
          <MenuDrawer toggleDrawer={toggleDrawer} open={open} />
          <Box
            component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === "light"
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              flexGrow: 1,
              minHeight: "100vh",
              overflow: "off",
            }}
          >
            <Toolbar />
            <Box sx={{ marginLeft: "1em", marginRight: "1em" }}>
              <Route exact path="/logbook">
                <LogbookPage />
              </Route>
              <Route exact path="/logbook/table">
                <LogbookTable />
              </Route>
              <Route exact path="/logbook/view/:id">
                <LogbookView />
              </Route>
              <Route exact path="/logbook/import">
                <JiraImport />
              </Route>
                <Route exact path="/nce-changes/table">
                <NceChangesTable />
              </Route>
              <Route exact path="/nce-os">
                <NceOS />
              </Route>
              <Route exact path="/nce-os/view/:id">
                <NceOSView />
              </Route>
              <Route exact path="/nce-os/table">
                <NceOSTable />
              </Route>
              <Route exact path="/maintenance">
                <MaintenancePage />
              </Route>
              <Route exact path="/maintenance/table">
                <MaintenanceTable />
              </Route>
              <Route exact path="/maintenance/kpi-sla">
                <UpdateKpiSla />
              </Route>
              <Route exact path="/tisp-maintenance">
                <TispMaintenancePage />
              </Route>
              <Route exact path="/tisp-maintenance/table">
                <TispMaintenanceTable />
              </Route>
              <Route exact path="/trace">
                <Traces />
              </Route>
              <Route exact path="/trace/recent">
                <RecentTraceTable />
              </Route>
              <Route exact path="/trace/request/:id">
                <TraceView />
              </Route>
              <Route exact path="/probe">
                <WebProbe />
              </Route>
              <Route exact path="/probe/view/:id">
                <ProbeView />
              </Route>
              <Route exact path="/marketing">
                <MarketingPage />
              </Route>
              <Route exact path="/marketing/table">
                <MarketingTable />
              </Route>
              <Route exact path="/marketing/view/:id">
                <MarketingView />
              </Route>
              <Route exact path="/marketing/import">
                <MarketingImport />
              </Route>
              <Route exact path="/contract/import">
                <CotractImport />
              </Route>
              <Route exact path="/forecast">
                <ForecastPage />
              </Route>
              <Route exact path="/forecast/table">
                <ForecastTable />
              </Route>
              <Route exact path="/forecast/import">
                <ForecastImport />
              </Route>
              <Route exact path="/forecast/view/:id">
                <ForecastView />
              </Route>
              <Route exact path="/admin/table">
                <AdminTable />
              </Route>
              <Route exact path="/customer-satisfaction/templates">
                <SatisfactionTemplates />
              </Route>
              <Route exact path="/customer-satisfaction/builder">
                <Builder />
              </Route>
              <Route exact path="/jira">
                <JiraPage />
              </Route>
              <Route exact path="/api-probes">
                <ApiProbes />
              </Route>
              <Route exact path="/api-probes/results">
                <ApiProbeResults />
              </Route>
              <Route exact path="/on-call">
                <OnCall />
              </Route>
              <Route exact path="/on-call/table">
                <OnCallTable />
              </Route>
              <Route exact path="/">
                <HomePage />
              </Route>
            </Box>
          </Box>
        </Box>
      </Switch>
    </ThemeProvider>
  );
}

export default function Dashboard() {
  return <DashboardContent />;
}
