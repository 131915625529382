import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import React from "react";
import { useSetRecoilState } from "recoil";
import { forecast_table_state, logbook_table_state, marketing_table_state, on_call_table_state } from "components/root";
import delete_entry from "api/delete";
import { toast } from "react-toastify";

import PropTypes from 'prop-types'

const DeleteCell = ({ props, source }) => {
  const { row } = props;
  const { PK, SK, createdBy } = row;

  const [open, setOpen] = React.useState(false);
  const setLogbookState = useSetRecoilState(logbook_table_state);
  const setMarketingState = useSetRecoilState(marketing_table_state);
  const setForecastState = useSetRecoilState(forecast_table_state);
  const setOnCallState = useSetRecoilState(on_call_table_state);
  const handle_delete = async(PK, SK) => {
    let to_del;
    if (source === "logbook") {
      setLogbookState((old) => {
        const new_state = old.filter((f) => f.SK !== SK);
        to_del = old.find((i) => i.SK === SK);
        return new_state;
    });
    } else if (source === "marketing") {
      setMarketingState((old) => {
        const new_state = old.filter((f) => f.SK !== SK);
        to_del = old.find((i) => i.SK === SK);
        return new_state;
    });
    } else if (source === "forecast") {
      setForecastState((old) => {
        const new_state = old.filter((f) => f.SK !== SK);
        to_del = old.find((i) => i.SK === SK);
        return new_state;
    });
    } else if (source=== 'on_call') {
      setOnCallState((old) => {
        const new_state = old.filter((f) => f.SK !== SK);
        to_del = old.find((i) => i.SK === SK);
        return new_state;
    });
    }
    try {
      await delete_entry(to_del);
      toast.success("Entry deleted!")
    } catch (err) {
      toast.error("Failed to delete!")
    }
  
    setOpen(false)
  }
    
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (createdBy === "jira-webhook") {
    return (
      <IconButton disabled>
        <DeleteForeverIcon sx={{ color: "greay" }}/>
      </IconButton>
    )
  }

 return (
      <div>
        <IconButton onClick={handleClickOpen}>
            <DeleteForeverIcon sx={{ color: "red" }}/>
        </IconButton>
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
            {"Are you sure you want to delete the entry?"}
            </DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                Entry will be irrecoverably deleted from the logbook!
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleClose} sx={{ color: "green" }}>Cancel</Button>
            <Button onClick={() => handle_delete(PK, SK)} sx={{ color: "red" }} autoFocus>
                Delete
            </Button>
            </DialogActions>
        </Dialog>
    </div>
 )
};

DeleteCell.propTypes = {
  props: PropTypes.object,
  row: PropTypes.object,
  source: PropTypes.string,
}

export default DeleteCell;
