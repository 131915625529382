import React from "react";
import TripleStateCell from "components/atoms/triple_state_cell";
import ActionCell from "components/atoms/action_cell";
import AttachmentCell from "components/atoms/attachment_cell";
import { intervalToDuration, isValid, format } from "date-fns";
import DeleteCell from "components/atoms/delete_cell";
import MarkCell from "components/atoms/mak_cell";
import DateTimeCell from "components/atoms/date_time_cell";
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
const padTo2Digits = (num) => {
  return num.toString().padStart(2, '0');
}

const formatDate = (date) => {
  return [
    padTo2Digits(date.getDate()),
    padTo2Digits(date.getMonth() + 1),
    date.getFullYear(),
  ].join('/');
}

export const sort_by_key = (array, key, doReverse) => {
  const sorted = array.sort((a, b) => a[key] - b[key]);

  if (doReverse) return sorted.reverse();

  return sorted;
};

export const field_maker = (field, header, fieldType) => {
  return {
    [field]: {
      field,
      header,
      fieldType,
    }
  };
};

export const admin_order_maker = ({
  field, header, fieldType,
}, minWidth) => {
  const base = {
    field,
    headerName: header,
    type: fieldType,
    minWidth
  };

  return base;
}
 
export const marketing_order_maker = ({
  field,
  header,
  fieldType,
}, minWidth) => {
  const base = {
    field,
    headerName: header,
    type: fieldType,
    minWidth,
  };
  if (field === "id") {
    base.renderCell = (e) => <ActionCell props={e} source="marketing" />;
  }
  else if (field === "files") {
    base.renderCell = (e) => <AttachmentCell props={e} />;
  }
  else if (field == "__delete__") {
    base.renderCell = (e) => <DeleteCell props={e} source="marketing" />;
  } 
  return base;
};

export const logbook_order_maker = ({
  field,
  header,
  fieldType,
}, minWidth) => {
  const base = {
    field,
    headerName: header,
    type: fieldType,
    minWidth,
  };
  if (field === "dur-hhmm") {
    base.valueFormatter = (props) => {
      const { value } = props;
      if (value) {
        const duration = intervalToDuration({ start: 0, end: value * 1000 });
        const { days, hours, minutes, seconds } = duration;
        let h = hours;
        const m = minutes;
        const s = seconds;
        if (days) h = h + days * 24;
    
        if (hours) return `${h} h. ${m} min. ${s} sec.`
    
        return `${m} min. ${s} sec.`
      } else {
        return "0 min. 0 sec."
      }
    }
  }
  else if (field === "id") {
    base.renderCell = (e) => <ActionCell props={e} source="logbook" />;
  }
  else if (field === "nce_os_id") {
    base.renderCell = (e) => <ActionCell props={e} source="nce-os" />;
  }
  else if (field === "files") {
    base.renderCell = (e) => <AttachmentCell props={e} />;
  }
  else if (field === "on-call") {
    base.renderCell = (e) => <TripleStateCell props={e} />;
  }else if (field === "customer-impact") {
    base.renderCell = (e) => <TripleStateCell props={e} />;
  } else if (field === "creator") {
      base.hide = true;
  } else if (field == "__delete__") {
    base.renderCell = (e) => <DeleteCell props={e} source="logbook" />;
  } 
  return base;
};

export const nce_changes_order_maker = ({
  field,
  header,
  fieldType,
}, minWidth) => {
  const base = {
    field,
    headerName: header,
    type: fieldType,
    minWidth,
  };
  
  if (field === "id") {
    base.renderCell = (e) => <ActionCell props={e} source="logbook" />;
  }
  else if (field === "nce_os_id") {
    base.renderCell = (e) => <ActionCell props={e} source="nce-os" />;
  }
  else if (field === "files") {
    base.renderCell = (e) => <AttachmentCell props={e} />;
  }
  else if (field === "on-call") {
    base.renderCell = (e) => <TripleStateCell props={e} />;
  }else if (field === "customer-impact") {
    base.renderCell = (e) => <TripleStateCell props={e} />;
  } else if (field === "creator") {
      base.hide = true;
  } 
  return base;
};

export const forecast_order_maker = ({
  field,
  header,
  fieldType,
}, minWidth) => {
  const base = {
    field,
    headerName: header,
    type: fieldType,
    minWidth,
  };
  if (field === "__view__") {
    base.renderCell = (e) => <ActionCell props={e} source="forecast" />;
  }
  if (field === "month") {
    base.valueFormatter = (props) => {

      const { value } = props;
      if (value) {
        const forecast_months = [
          "January",
          "February",
          "March",
          "April",
          "May" ,
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];
        return forecast_months[value-1]
      }
    }
  }
  if (field.includes("_fc")) {
    base.valueFormatter = (props) => {
      const { value } = props;
      if (value) {
        return `${value} €`
      }
    }
  }
  else if (field === "date_key") {
    base.valueFormatter = (props) => {
      const { value } = props;
      if (value) {
        const date = new Date(value)
        return formatDate(date);
      }
    } 
   } else if (field == "__delete__") {
    base.renderCell = (e) => <DeleteCell props={e} source="forecast" />;
  } 
  return base;
};


export const tisp_api_probes_order_maker = ({
  field,
  header,
  fieldType,
}, minWidth) => {
  const base = {
    field,
    headerName: header,
    type: fieldType,
    minWidth,
  };

  if(field === "availability"){
        base.renderCell = (e) => e.value ? <CheckIcon sx={{color: "green"}}/> : <CloseIcon sx={{color: "red"}}/>;
  }
  
  return base;
};


export const web_probe_order_maker = ({
  field,
  header,
  fieldType,
}, minWidth) => {
  const base = {
    field,
    headerName: header,
    type: fieldType,
    minWidth,
  };

    if (field === "id") {
      base.renderCell = (e) => <ActionCell props={e} source="probe"/>;
    }

  return base;
};

export const maintenance_outage_order_maker = ({
  field,
  header,
  fieldType,
}, minWidth, 
  editable = true,
  outage = false
  ) => {
  const base = {
    field,
    headerName: header,
    type: fieldType,
    minWidth,
    editable,
    hide: false,
  };
  if (field === "maintenance_starttime" || field === "maintenance_endtime") {
    base.valueParser = (value) => {
      if(value instanceof Date && isValid(value)) {
        const formattedValue = format(value, "yyyy-MM-dd HH:mm:ss.SSS")
        return formattedValue;
      }
      return value
    }
    base.renderEditCell = (e) => <DateTimeCell props={e} />
  } else 
  if (field == "__delete__") {
    base.renderCell = (e) => <DeleteCell props={e} source={outage ? "outage" : "maintenance"} />;
  } else if (field == "__mark__") {
    base.editable = false;
    base.hide = true;
    base.renderCell = (e) => <MarkCell props={e} />;
  }

  return base;
};

export const on_call_order_maker = ({
  field,
  header,
  fieldType,
}, minWidth, 
  editable =  false) => {
  const base = {
    field,
    headerName: header,
    type: fieldType,
    minWidth,
    editable
  };
  
  if (field == "__delete__") {
    base.renderCell = (e) => <DeleteCell props={e} source="on_call" />;
  } 

  return base;
};

export const base_order_maker = ({
  field,
  header,
  fieldType,
}, minWidth, 
  editable =  false) => {
  const base = {
    field,
    headerName: header,
    type: fieldType,
    minWidth,
    editable
  };
  
  if (field == "__delete__") {
    base.renderCell = (e) => <DeleteCell props={e} />;
  } 

  return base;
};

export const check_selected = (route) => {
  return route === window.location.pathname;
};

export const select_options_maker = (data) => {
  const mapped_data = [];

  data.forEach((d) => {
    const { key, alias } = d;
    if (key) {
      mapped_data.push({
        key,
        alias: alias || key
      })
    } else {
      mapped_data.push({
        key: d,
        alias: d
      })
    }
  })

  return mapped_data;
}

export const alias_maker = (key, alias) => {
  return {
    key,
    alias: alias || key,
  }
}

export const get_duration = (start_date, end_date) => {
  if (start_date && end_date) {
    if((start_date instanceof Date && !isValid(start_date)) || (end_date instanceof Date && !isValid(end_date))) return;
    
    let startDate = typeof start_date === "string" ? new Date(start_date) : start_date;
    let endDate = typeof end_date === "string" ? new Date(end_date) : end_date;

    const duration = intervalToDuration({
      start: startDate,
      end: endDate
    });
    const { days, hours, minutes, seconds } = duration;
    let h = hours;
    const m = minutes;
    const s = seconds;
    if (days) h = h + days * 24;

    if (hours > -1) return `${h} h. ${m} min. ${s} sec.`

    return `${m} min. ${s} sec.`
  }
  return;
}



// import { formatDistance } from 'date-fns'

// const duration = s => formatDistance(0, s * 1000, { includeSeconds: true })

// duration(50) // 'less than a minute'
// duration(1000) // '17 minutes'

export const get_duration_value = (start_date, end_date) => {
  if (start_date && end_date) {
    if((start_date instanceof Date && !isValid(start_date)) || (end_date instanceof Date && !isValid(end_date))) return;
    
    let startDate = typeof start_date === "string" ? new Date(start_date) : start_date;
    let endDate = typeof end_date === "string" ? new Date(end_date) : end_date;

    const duration = intervalToDuration({
      start: startDate,
      end: endDate
    })
    const { days, hours, minutes, seconds } = duration;
    const durr = days * 24 * 3600 + hours * 3600 + minutes  * 60 + seconds;
    return durr;
  }
  return 0;
}

const objectsEqual = (o1, o2) => 
    typeof o1 === 'object' && Object.keys(o1).length > 0 
        ? Object.keys(o1).length === Object.keys(o2).length 
            && Object.keys(o1).every(p => objectsEqual(o1[p], o2[p]))
        : o1 === o2;


export const get_venn_difference = (set1, set2) => {
  const difference = [];
  set1.forEach((o, idx) =>  {
    if (idx <= set2.length && !objectsEqual(o, set2[idx])) {
      difference.push(o)
    }
  })
  return difference;
}


export const trace_order_maker = ({
  field,
  header,
  fieldType,
}, minWidth) => {
  const base = {
    field,
    headerName: header,
    type: fieldType,
    minWidth,
  };
  
  if (field === "request_id") {
    base.renderCell = (e) => <ActionCell props={e} source="trace"  />;
  }
  return base;
};