import {  base_order_maker, field_maker } from "helper";

export const kpi_sla_field_enum = {
    date: "SK",
    user: "user",

}

const kpi_sla_fields = {
  ...field_maker("id", "-", "string"),
  ...field_maker(kpi_sla_field_enum.date, "Date", "date"),
}


const kpi_sla_order = [
  base_order_maker(kpi_sla_fields["id"], 100),
  base_order_maker(kpi_sla_fields[kpi_sla_field_enum.date], 200),
]

export default kpi_sla_order ;