/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect, useCallback } from "react";

import SimpleDataGrid from "components/organisms/data_grid";
import SimplePageTitle from "components/atoms/simple_page_title";
import { logbook_table_state } from "components/root";
import { useRecoilState } from "recoil";
import { toast } from "react-toastify";
import { getTraceRequestData } from "api/trace";
import trace_order from "constant_variables/trace_fields";

export default function RecentTraceTable() {
  const [showData, setShowData] = useRecoilState(logbook_table_state);
  const [loading, setLoading] = useState(true);


  const getViewData = useCallback(() => {
    const request = async() => {
      try {
        setLoading(true)
        const resp = await getTraceRequestData({
          PK: "request",
        });

        setShowData(resp.reverse());
      } catch (err) {
        console.trace(err);
        toast.error("Failed to gather data")
      } finally {
        setLoading(false);
      }
    }

    request();
   
  }, []);
  


  useEffect(() => {
    getViewData();
  }, [getViewData]);

  return (
    <>
      <SimplePageTitle  title="Recent Trace Queries" />
      <SimpleDataGrid
        loading={loading}
        data={showData}
        order={trace_order}
        id={false}
      />
    </>
  );
}
