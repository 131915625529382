import React, { useState } from "react";

import { useRecoilState } from "recoil";

import { logbook_form_state, user_state } from "components/root";
import LogbookForm from "components/organisms/logbook_form";
import SimplePageTitle from "components/atoms/simple_page_title";
import postLogbook from "api/logbook";

import { toast } from "react-toastify";


const Logbook = () => {


  const [loading, setLoading] = useState(false);
  const [form, setForm] = useRecoilState(logbook_form_state);
  const [userState] = useRecoilState(user_state);
  const { permissions: {read_only} } = userState;
  const [files, setFiles] = useState([]);

  const handle_form_change = (key, value) => {
    setForm((old) => ({
      ...old,
      [key]: value,
    }));
  };

  const handle_add_file= (value) => {
    setFiles((old) => ([
        ...old,
        ...value
    ]));
  };

  const remove_file= (name) => {
    const newFiles = files.filter((f) => f.name !== name)
    setFiles(newFiles);
    };

  const submit = async (e) => {
    e.preventDefault();
    if(!form["start-time-cetcest"] || !form["end-time-cetcest"]) {
      toast.error("Start Date and End Date are mandatory!")
      return;
    }
    setLoading(true);
    try {
      await postLogbook(form, files)
      setForm(() => logbook_form_state);
      setFiles([]);
      toast.success("Logbook entry added") 
    } catch(err) {
      console.trace(err)
      toast.error("Failed") 
    } finally {
      setLoading(false);
    }
  };
  return (
    <React.Fragment>
      <SimplePageTitle title="New Logbook Entry" />


      <LogbookForm
        form={form}
        files={files}
        addFile={handle_add_file}
        loading={loading}
        submit={submit}
        handleChange={handle_form_change}
        onRemove={remove_file}
        disabled={read_only}
      /> 
    </React.Fragment>

  );
};

export default Logbook;
