/* eslint-disable react-hooks/exhaustive-deps */

// import Button from "@mui/material/Button";
import React, { useState, useEffect, useCallback } from "react";

import postQuery from "../../../api/query";

import SimpleDataGrid from "components/organisms/data_grid";
import logbook_order from "constant_variables/logbook_fields";
import SimplePageTitle from "components/atoms/simple_page_title";
import { logbook_table_state, user_state } from "components/root";
import { useRecoilState } from "recoil";
import { toast } from "react-toastify";

export default function LogbookTable() {
  const [showData, setShowData] = useRecoilState(logbook_table_state);
  const [userState] = useRecoilState(user_state);
  const [loading, setLoading] = useState(true);
  const [limit, setLimit] = useState(101);
  const [page, setPage] = useState(0);
  let read_only = true;
    if (userState !== null && userState.permissions) {
        read_only = userState.permissions.read_only;
    }

  const getViewData = useCallback(() => {
    const request = async() => {
      try {
        setLoading(true)
        const resp = await postQuery({
          PK: "MainLog",
          DataAdjuster: "logbook",
          limit,
        });
        setShowData(resp);
      } catch (err) {
        console.trace(err);
        toast.error("Failed to gather data")
      } finally {
        setLoading(false);
      }
    }

    request();
   
  }, [limit]);
  
  const getAllData = () => {
    if (limit === 101) {
      setLimit(0);
      setPage(1)
    }
  }
  

  useEffect(() => {
    getViewData();
  }, [getViewData]);

  let adjustedOrder = logbook_order
  if (read_only) {
    adjustedOrder = logbook_order.filter(item => item.field !== "__delete__");
  }
  return (
    <>
      <SimplePageTitle  title="Logbook Table" />
      <SimpleDataGrid
        loading={loading}
        data={showData}
        order={adjustedOrder}
        id={false}
        getAllData={getAllData}
        page={page}
        setPage={setPage}
      />
    </>
  );
}
