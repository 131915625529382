import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import FormSkeleton from "components/atoms/form_skeleton";
import MarketingForm from "components/organisms/marketing_form";

import postLogbook from "api/logbook";
import  { postFind } from "api/find";

import { useRecoilState } from "recoil";
import { user_state } from "components/root";

const MarketingView = () => {
  const { id } = useParams();
  const [form, setForm] = useState(null);
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  const [userState] = useRecoilState(user_state);
  let read_only = true;
    if (userState !== null && userState.permissions) {
        read_only = userState.permissions.read_only;
    }

  useEffect(() => {
    const get_entry = async(route_id) => {
        const SK = route_id.split("SK=")[1];
        const PK = route_id.split("PK=")[1].split("&")[0];
        try {
          const resp = await postFind(PK, SK)
          setForm(resp)
        } catch(err) {
          toast.error("Failed");
          setLoading(false);
        }
    }
    if (id) get_entry(id)
     
    }, [id])

    const handle_form_change = (key, value) => {
      setForm((old) => ({
        ...old,
        [key]: value,
      }));
    };

  const submit = async(e) => {
    setLoading(true);
    try {
      e.preventDefault();
      await postLogbook(form, [])
      toast.success("Logbook entry updated") 
    } catch(err) {
      toast.error("Failed!") 
    } finally {

      setLoading(false);
    }
  };

  if (!form || loading) {
    return <FormSkeleton />
  }

  return (
   <div style={{ textAlign: "center"}}>
      <h3>Viewing entry <strong>{form.SK}</strong> </h3>
      <FormControlLabel disabled={read_only} control={<Switch checked={edit} onChange={() => setEdit(!edit)} />} label="Edit entry" />  
      <MarketingForm disabled={!edit} submit={submit} loading={loading} form={form} handleChange={handle_form_change} editMode={edit} />
   </div>
  );
};

export default MarketingView;