import { handleApi } from "./api";
import {
    ApiGateWayEndPoint,
    definedRouteTypes,
    definedApiMethods,
    definedLogbookResources,
    definedLogbookImportResources,
} from ".";
import uploadFiles from "./file_api";
import { logbook_field_enum } from "constant_variables/logbook_fields";
import { get_duration_value } from "helper";

const postLogbook = async (body, files) => {
    const url = `${ApiGateWayEndPoint}/${definedRouteTypes.logbook}`;
    let add_files = [];
    let new_body = { ...body };
    if (body[logbook_field_enum.end_time_cetcest] && body[logbook_field_enum.start_time_cetcest]) {
        new_body = {
            ...body,
            [logbook_field_enum.dur_hhmm]: get_duration_value(
                body[logbook_field_enum.start_time_cetcest],
                body[logbook_field_enum.end_time_cetcest]
            ),
        };
    }

    if (files && files.length > 0) {
        const { upload_files } = await uploadFiles(files);
        add_files = upload_files;
    }
    return handleApi(url, definedApiMethods.post, { ...new_body, files: add_files });
};

export const importJiraEntry = async (body) => {
    const url = `${ApiGateWayEndPoint}/${definedRouteTypes.logbook}/${definedLogbookResources.import}/${definedLogbookImportResources.jira}`;
    const resp = await handleApi(url, definedApiMethods.post, body);
    return resp;
};

export const importMarketing = async (files) => {
    let add_files = [];
    if (files && files.length > 0) {
        const { upload_files } = await uploadFiles(files);
        add_files = upload_files;
    }
    const url = `${ApiGateWayEndPoint}/${definedRouteTypes.logbook}/${definedLogbookResources.import}/${definedLogbookImportResources.marketing}`;
    const resp = await handleApi(url, definedApiMethods.post, { files: add_files });
    return resp;
};

export const add_multiple_entries = async (body) => {
    const url = `${ApiGateWayEndPoint}/${definedRouteTypes.logbook}/${definedLogbookResources.multiple}`;
    const resp = await handleApi(url, definedApiMethods.post, body);
    return resp;
};

export default postLogbook;
