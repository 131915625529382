import { field_maker, admin_order_maker } from "helper";

export const admin_field_enum = {
  email: "email",
  read_only: "read_only",
  logbook: "logbook",
  nce_changes: "nce_changes",
  nce_os_logbook: "nce_os_logbook",
  marketing: "marketing",
  maintenance: "maintenance",
  tisp_maintenance: "tisp_maintenance",
  kpi: "kpi",
  trace: "trace",
  forecast: "forecast",
  web_probe: "web_probe",
  admin: "admin",
  contracts: "contracts",
  customer_satisfaction: "customer_satisfaction",
  api_probes: "api_probes",
  redshift_exports: "redshift_exports",
  on_call: "on_call",
};

const admin_fields = {
  ...field_maker(admin_field_enum.email, "Email", "string"),
  ...field_maker(admin_field_enum.read_only, "Read Only", "boolean"),
  ...field_maker(admin_field_enum.admin, "Admin", "boolean"),
  ...field_maker(admin_field_enum.nce_os_logbook, "1NCE OS", "boolean"),
  ...field_maker(admin_field_enum.logbook, "Logbook", "boolean"),
  ...field_maker(admin_field_enum.nce_changes, "1NCE Changes", "boolean"),
  ...field_maker(admin_field_enum.marketing, "Marketing", "boolean"),
  ...field_maker(admin_field_enum.maintenance, "Maintenance", "boolean"),
  ...field_maker(
    admin_field_enum.tisp_maintenance,
    "Tisp Maintenance",
    "boolean"
  ),
  ...field_maker(admin_field_enum.kpi, "KPI", "boolean"),
  ...field_maker(admin_field_enum.trace, "Trace", "boolean"),
  ...field_maker(admin_field_enum.forecast, "Forecast", "boolean"),
  ...field_maker(admin_field_enum.web_probe, "Web Probe", "boolean"),
  ...field_maker(admin_field_enum.contracts, "Contracts", "boolean"),
  ...field_maker(
    admin_field_enum.customer_satisfaction,
    "Customer Satisfaction",
    "boolean"
  ),
  ...field_maker(
    admin_field_enum.redshift_exports,
    "Redshift exports",
    "boolean"
  ),
  ...field_maker(admin_field_enum.api_probes, "API Probes", "boolean"),
  ...field_maker(admin_field_enum.on_call, "On Call", "boolean"),
};

const admin_order = [
  admin_order_maker(admin_fields[admin_field_enum.email], 300),
  admin_order_maker(admin_fields[admin_field_enum.admin], 150),
  admin_order_maker(admin_fields[admin_field_enum.read_only], 150),
  admin_order_maker(admin_fields[admin_field_enum.logbook], 150),
  admin_order_maker(admin_fields[admin_field_enum.nce_changes], 150),
  admin_order_maker(admin_fields[admin_field_enum.nce_os_logbook], 150),
  admin_order_maker(admin_fields[admin_field_enum.marketing], 150),
  admin_order_maker(admin_fields[admin_field_enum.maintenance], 150),
  admin_order_maker(admin_fields[admin_field_enum.tisp_maintenance], 150),
  admin_order_maker(admin_fields[admin_field_enum.kpi], 150),
  admin_order_maker(admin_fields[admin_field_enum.trace], 150),
  admin_order_maker(admin_fields[admin_field_enum.forecast], 150),
  admin_order_maker(admin_fields[admin_field_enum.web_probe], 150),
  admin_order_maker(admin_fields[admin_field_enum.api_probes], 150),
  admin_order_maker(admin_fields[admin_field_enum.contracts], 150),
  admin_order_maker(admin_fields[admin_field_enum.customer_satisfaction], 150),
  admin_order_maker(admin_fields[admin_field_enum.redshift_exports], 150),
  admin_order_maker(admin_fields[admin_field_enum.on_call], 150),
];

export default admin_order;
