/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import Button from "@mui/material/Button";
import TextField from '@mui/material/TextField';
import SimplePageTitle from 'components/atoms/simple_page_title';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import GridField from 'components/molecules/grid_field';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

import { importJiraEntry } from 'api/logbook';
import { toast } from 'react-toastify';

import { useRecoilState } from "recoil";
import { user_state } from "components/root";

const JiraImport = () => {
    const [loading, setLoading] = useState(false);
    const [jiraUrlList, setJiraUrlList] = useState([]);
    const [jiraUrl, setJiraUrl] = useState();
    const [userState] = useRecoilState(user_state); 
    let read_only = true;
    if (userState !== null && userState.permissions) {
        read_only = userState.permissions.read_only;
    }

    const handle_import = async() => {
      setLoading(true);
      try {
        await importJiraEntry({ url: jiraUrlList })
        toast.success("Jira entry imported to logbook") 
      } catch(err) {
        console.trace(err)
        toast.error("Failed") 
      } finally {
        setLoading(false);
        setJiraUrlList([])
      }
    }

    const handle_add = async() => {
      if (jiraUrl ) {
        setJiraUrlList((old) => [...old, jiraUrl])
        setJiraUrl("");
      }
    }

    const handle_remove = async(index) => {
      const new_list = jiraUrlList.filter((el, i) => i !== index)
      setJiraUrlList(new_list)
    }
    
  return (
      <React.Fragment>
          <SimplePageTitle title="Import Jira Entry" />
          <Alert severity="info">
              <AlertTitle><b>Please provide JIRA tikcet URL in format.</b></AlertTitle>
                  <p> This works for all JIRA projects not just NCETS </p>
                  <b>https://service-desk.1nce.com/projects/NCETS/queues/custom/182/NCETS-249</b> or<b> https://service-desk.1nce.com/browse/DEVTESTBSC-490 </b> or similiar, important is that ticket ends with /TICKET-KEY
          </Alert>
          <GridField loading={loading}>
              <TextField
                  sx={{ marginTop: "1em" }}
                  id="jira-ticket-ur;"
                  label="Jira Ticket URL"
                  value={jiraUrl}
                  onChange={(e) => setJiraUrl(e.target.value)}
                  fullWidth
              />
          </GridField>
          
          <Button disabled={read_only || loading} variant="contained" onClick={() => handle_add()} sx={{ marginTop: "1em"}}>
              Add
          </Button>
          { jiraUrlList.length > 0 && <>
          <List dense sx={{ maxWidth: "800px", bgcolor: 'background.paper'}}>
              {jiraUrlList.map((url, index) =>
              <ListItem key={url}  secondaryAction={
                <IconButton edge="end" aria-label="delete" onClick={() => handle_remove(index)}>
                  <DeleteIcon sx={{color: "red" }}/>
                </IconButton>
              }>
                  <ListItemText primary={url} />
                </ListItem>
              )}
            </List>
           <Button sx={{ backgroundColor: "#1e4d7d",  marginTop: "1em" }}  disabled={loading} variant="contained" onClick={() => handle_import()} >
              Import
          </Button> 
          </>}

    </React.Fragment>
  );
}
 
export default JiraImport;