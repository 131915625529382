/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useCallback } from "react";
import { useHistory, useLocation  } from "react-router-dom";

import Avatar from '@mui/material/Avatar';
import Alert from '@mui/material/Alert';
import LoadingButton from '@mui/lab/LoadingButton';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Container from '@mui/material/Container';
import Copyright from "../../molecules/copyright";

import { ReactComponent as NceLogo } from "../../../assets/main.svg";
import GetSSORedirect, { VerifySSO } from "api/sso_api";
import { useSetRecoilState } from "recoil";
import { user_state, version_state, customer_feedback_url } from "components/root";
// import { toast } from "react-toastify";
import getVersion from "api/version";
import { getCustomerFeedbackUrl } from "api/customer_satisfaction";
import { toast } from "react-toastify";


const LoginPage = () => {
  const location = useLocation();
  const history = useHistory();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const setUserState = useSetRecoilState(user_state);
  const setVersionState = useSetRecoilState(version_state);

  const getVersionCallback = useCallback(()=> {
    const fetchVersion = async() => {
      try {
        const value = await getVersion()
        setVersionState({ version: value })
      }
      catch(err) {
        console.trace(err)
      }
    }
    fetchVersion()
  }, [setVersionState])

  const setNewUserCallback = useCallback(()=> {
    const setNewUser = async () => {
      const params = new URLSearchParams(location.search)
      const token = params.get("token");
      const email = params.get("email");
      console.trace(email)
      if (token && email) {
        try {
            setUserState({ token, user: email })
            sessionStorage.setItem("user", email)
            sessionStorage.setItem("token", token)
        } catch (e) {
          setError("Existing token expired, please sign-in again")
          console.trace(e)
        }
        setLoading(false);
        if (token) {
          const path = localStorage.getItem("path");
          if (path) {
            history.push(path);
            localStorage.removeItem("path")
          } else {
            history.push("/")
          }
          
        }
      }
    };
    setNewUser()
  }, [setUserState, history, location.search ])


const verifyUserCallBack = useCallback((token, user) => {
  const verifyUser = async() => {
    try {
      const { valid , permissions } = await VerifySSO();
      if(valid) {
        setUserState({ token, user, permissions })
        history.push(location.pathname) 
      } else {
        setUserState(null)
        sessionStorage.removeItem("token")
        sessionStorage.removeItem("user")
        history.push("/")
      }
    } catch (err) {
      toast.error("Failed to verify login!")
      console.trace(err)
    }

  }
  verifyUser()
}, [history, location.pathname, setUserState ])

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    const email = sessionStorage.getItem("user");

    if (!token || !email) {
      setNewUserCallback();
    } else {
      verifyUserCallBack(token, email)
    }
    getVersionCallback();
  }, [
    setNewUserCallback,
    getVersionCallback,
    verifyUserCallBack,
  ]);


  const handleSSOLogin = async () => {
    try {
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("user");
      localStorage.setItem("path", location.pathname);
      await GetSSORedirect();
    } catch (e) {
      setLoading(false);
      setError("Failed to login! Please reach out to Data analytics team!");
    }
  };
  
  return (
      <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <NceLogo />
              <Box component="form" noValidate sx={{ mt: 1 }}>
                <LoadingButton
                  loading={loading}
                  onClick={() => handleSSOLogin()}
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                > 
                Sign in with SSO
              </LoadingButton>
            </Box>
            { error && <Alert severity="error">{error}</Alert> } 
          </Box>
          <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
  );
};

export default LoginPage;
