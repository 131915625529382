import { handleApi } from "./api";
import {
  ApiGateWayEndPoint,
  definedApiMethods,
  definedRouteTypes,
  definedTracesResources,
} from ".";

const postTraceQuery = async (query) => {
  const url = `${ApiGateWayEndPoint}/${definedRouteTypes.trace}/${definedTracesResources.request}`;
  const resp = await handleApi(url, definedApiMethods.post, query);
  return resp;
};

export const getQueryStatus = async (query) => {
  const url = `${ApiGateWayEndPoint}/${definedRouteTypes.trace}/${definedTracesResources.status}`;
  const resp = await handleApi(url, definedApiMethods.post, query);
  return resp;
};

export const getQueryData = async (query) => {
  const url = `${ApiGateWayEndPoint}/${definedRouteTypes.trace}/${definedTracesResources.query}`;
  const resp = await handleApi(url, definedApiMethods.post, query);
  return resp;
};

export const getTraceRequestData = async (query) => {
  const url = `${ApiGateWayEndPoint}/${definedRouteTypes.trace}/${definedTracesResources.request_query}`;
  const resp = await handleApi(url, definedApiMethods.post, query);
  return resp;
};

export default postTraceQuery;
