import React from "react";
import { NavLink } from "react-router-dom";

import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Tooltip from "@mui/material/Tooltip";
import PropTypes from "prop-types";
import DownloadIcon from "@mui/icons-material/Download";
import { getRedshiftExport } from "api/redshift_exports";
const SecondaryListItems = ({ list }) => {

  const onDownload = async (route, isRedshift) => {
    let url = route;
    if (isRedshift) {
      const resp = await getRedshiftExport(route);
      url = resp;
    }
    const link = document.createElement("a");
    link.href = url;
    link.click();
    link.remove();
  };

  return (
    <React.Fragment>
      {list.map((m) => (
        <Tooltip key={m.title} title={m.title} placement="right">
          {m.type === "download" ? (
            <ListItem
              key="download"
              button
              onClick={(e) => {
                e.preventDefault();
                onDownload(m.url, m.redshift);
              }}
            >
              <ListItemIcon>
                <DownloadIcon />
              </ListItemIcon>
              <ListItemText primary={m.title} />
            </ListItem>
          ) : (
            <ListItem
              key={m.route}
              button
              component={NavLink}
              to={m.route}
              exact
              activeStyle={{
                fontWeight: "bold",
                backgroundColor: "#e6f2ff",
              }}
            >
              <ListItemIcon>
                <m.icon />
              </ListItemIcon>
              <ListItemText primary={m.title} />
            </ListItem>
          )}
        </Tooltip>
      ))}
    </React.Fragment>
  );
};

SecondaryListItems.propTypes = {
  list: PropTypes.array.isRequired,
};

export default SecondaryListItems;
