import AddBox from "@mui/icons-material/AddBox";
import ArrowUpward from "@mui/icons-material/ArrowUpward";
import TableChart from "@mui/icons-material/TableChart";
import AccountTree from "@mui/icons-material/AccountTree";
import Update from "@mui/icons-material/Update";
import Language from "@mui/icons-material/Language";
import SavedSearch from "@mui/icons-material/SavedSearch";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import DownloadIcon from "@mui/icons-material/Download";

const logbook_list = [
  {
    title: "Import",
    icon: ArrowUpward,
    route: "/logbook/import",
  },
  {
    title: "New Entry",
    icon: AddBox,
    route: "/logbook",
  },
  {
    title: "Log Table",
    icon: TableChart,
    route: "/logbook/table",
  },
];

const maintenance_list = [
  {
    title: "New Maintenance",
    icon: AddBox,
    route: "/maintenance",
  },
  {
    title: "Maintenance Table",
    icon: TableChart,
    route: "/maintenance/table",
  },
];

const tisp_maintenance_list = [
  {
    title: "New Maintenance",
    icon: AddBox,
    route: "/tisp-maintenance",
  },
  {
    title: "TISP Maintenances",
    icon: TableChart,
    route: "/tisp-maintenance/table",
  },
];

const traces_list = [
  {
    title: "Find trace",
    icon: AccountTree,
    route: "/trace",
  },
  {
    title: "Recent Searches",
    icon: SavedSearch,
    route: "/trace/recent",
  },
];

const web_probes_list = [
  {
    title: "Web Probe",
    icon: Language,
    route: "/probe",
  },
];

const kpi_list = [
  {
    title: "KPI/SLA updates",
    icon: Update,
    route: "/maintenance/kpi-sla",
  },
];

const marketing_list = [
  {
    title: "Import",
    icon: ArrowUpward,
    route: "/marketing/Import",
  },
  {
    title: "Marketing Entry",
    icon: AddBox,
    route: "/marketing",
  },
  {
    title: "Marketing Table",
    icon: TableChart,
    route: "/marketing/table",
  },
];

const forecast_list = [
  {
    title: "Forecast Entry",
    icon: AddBox,
    route: "/forecast",
  },
  {
    title: "Import",
    icon: ArrowUpward,
    route: "/forecast/import",
  },
  {
    title: "Forecast Table",
    icon: TableChart,
    route: "/forecast/table",
  },
];

const contract_list = [
  {
    title: "Import",
    icon: ArrowUpward,
    route: "/contract/import",
  },
];

const admin_list = [
  {
    title: "Admin",
    icon: AdminPanelSettingsIcon,
    route: "/admin/table",
  },
];

const customer_feedback_list = [
  //ToDO: Should be reworked!
  {
    title: "Download",
    type: "download",
    icon: DownloadIcon,
    url: undefined,
    route: "/",
  },
  {
    title: "Manage Templates",
    icon: TableChart,
    route: "/customer-satisfaction/templates",
  },
];

const api_probes_list = [
  {
    title: "API Probes",
    icon: Language,
    route: "/api-probes",
  },
  {
    title: "Probe Results",
    icon: TableChart,
    route: "/api-probes/results",
  },
];

const redshift_exports = [
  {
    title: "CTG customers",
    type: "download",
    redshift: true,
    icon: DownloadIcon,
    url: `ctg`,
  },
  {
    title: "Open VPN customers",
    type: "download",
    redshift: true,
    icon: DownloadIcon,
    url: `openvpn`,
  },
];

const nce_changes = [
  {
    title: "Log Table",
    icon: TableChart,
    route: "/nce-changes/table",
  },
];

const nce_os_logbook = [
  {
    title: "New Entry",
    icon: AddBox,
    route: "/nce-os",
  },
  {
    title: "Log Table",
    icon: TableChart,
    route: "/nce-os/table",
  },
];

const on_call = [
  {
    title: "New Log",
    icon: AddBox,
    route: "/on-call",
  },
  {
    title: "Log Table",
    icon: TableChart,
    route: "/on-call/table",
  },
];

const secondaryLists = [
  { name: "Logbook", items: logbook_list, permission_name: "logbook" },
  { name: "1NCE Changes", items: nce_changes, permission_name: "nce_changes" },

  {
    name: "1NCE OS Logbook",
    items: nce_os_logbook,
    permission_name: "nce_os_logbook",
  },
  { name: "Marketing", items: marketing_list, permission_name: "marketing" },
  {
    name: "Maintenance",
    items: maintenance_list,
    permission_name: "maintenance",
  },
  {
    name: "Tisp Maintenance",
    items: tisp_maintenance_list,
    permission_name: "tisp_maintenance",
  },
  { name: "KPI status", items: kpi_list, permission_name: "kpi" },
  { name: "Trace", items: traces_list, permission_name: "trace" },
  { name: "Web Probes", items: web_probes_list, permission_name: "web_probe" },
  { name: "API Probes", items: api_probes_list, permission_name: "api_probes" },
  { name: "Sales Forecast", items: forecast_list, permission_name: "forecast" },
  { name: "Contracts", items: contract_list, permission_name: "contracts" },
  {
    name: "Customer Satisfaction",
    items: customer_feedback_list,
    permission_name: "customer_satisfaction",
  },
  {
    name: "Redshift exports",
    items: redshift_exports,
    permission_name: "redshift_exports",
  },
  { name: "On Call", items: on_call, permission_name: "on_call" },
  { name: "Admin", items: admin_list, permission_name: "admin" },
];

export default secondaryLists;
