/* eslint-disable no-unused-vars */

import React from "react";
import { Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import { useLocation, useHistory } from "react-router-dom";
import {
  loadTemplates,
  saveTemplate,
  deleteTemplate
} from "../../../api/customer_satisfaction";
import { NavLink } from "react-router-dom";

const uuid = require("uuid");

function SatisfactionTemplates() {
  const navigate = useHistory();
  const [templates, setTemplates] = React.useState([]);

  const handleLoadTemplates = async () => {
    try {
      const { Items } = await loadTemplates();
      setTemplates((old) => [...Items]);
    } catch (err) {
      console.log(err);
    }
  };

  React.useEffect(() => {
    handleLoadTemplates();
  }, []);

  const handleSaveTemplate = async (template) => {
    template.questions.forEach((question) => {
      question.questionId = uuid.v4();
    });
    await saveTemplate({
      ...template,
      duplicatedFrom: template.sk,
      templateName: `${template.templateName} Copy`,
    });
  };

  return (
    <Box
      sx={{
        color: "rgba(0, 0, 0, 0.6)",
        borderRadius: "10px",
        backgroundColor: "rgb(255, 255, 255)",
        padding: ["45px", "45px"],
        marginTop: "35px",
        // minWidth: 1100,
        minHeight: 600,
        // maxWidth: 1100,
        maxHeight: 800,
        overflowY: "scroll",
      }}
    >
      <Grid container rowSpacing={2} columnSpacing={{ xs: 8, sm: 8, md: 8 }}>
        <Grid item xs={3}>
          <Card
            sx={{
              minWidth: 125,
              minHeight: 300,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <IconButton
              component={NavLink}
              to={"/customer-satisfaction/builder"}
            >
              <AddIcon fontSize="large" />
            </IconButton>
          </Card>
        </Grid>
        {templates.map((template, index) => {
          return (
            <Grid item xs={3} key={`${index}-template`}>
              <Card
                sx={{
                  minWidth: 125,
                  minHeight: 300,
                  display: "flex",
                  position: "relative",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box>{template.templateName}</Box>
                <Box sx={{
                  position: "absolute",
                  bottom: 0,
                  marginBottom: "30px"
                }}>
                  <Button
                    variant="text"
                    onClick={async () => {
                      await handleSaveTemplate(template);
                      await handleLoadTemplates()
                    }}
                  >
                    Duplicate
                  </Button>
                  <Button
                    variant="text"
                    component={NavLink}
                    to={`/customer-satisfaction/builder?id=${template.SK}`}
                  >
                    View
                  </Button>
                  <Button
                    onClick={async () => {
                      await deleteTemplate({ templateId: template.SK });
                      navigate.push("/customer-satisfaction/templates");
                      setTemplates(templates.filter(filteredTemplate => filteredTemplate.SK != template.SK))
                    }}
                    variant="text"
                  >
                    Delete
                  </Button>
                </Box>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}

export default SatisfactionTemplates;
