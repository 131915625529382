import React, { useState } from "react";

import { useRecoilState } from "recoil";

import { forecast_form_state, user_state } from "components/root";
import ForecastForm from "components/organisms/forecast_form";
import SimplePageTitle from "components/atoms/simple_page_title";
import { postForecast } from "api/forecast";

import { toast } from "react-toastify";

const Forecast = () => {
    const [loading, setLoading] = useState(false);
    const [form, setForm] = useRecoilState(forecast_form_state);
    const [userState] = useRecoilState(user_state);
    let read_only = true;
    if (userState !== null && userState.permissions) {
        read_only = userState.permissions.read_only;
    }

    const handle_form_change = (key, value) => {
        setForm((old) => ({
            ...old,
            [key]: value,
        }));
    };

    const submit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            await postForecast(form);
            setForm(() => forecast_form_state);
            toast.success("Forecast entry added");
        } catch (err) {
            console.trace(err);
            toast.error("Failed");
        } finally {
            setLoading(false);
        }
    };
    return (
        <React.Fragment>
            <SimplePageTitle title="New Forecast Entry" />

            <ForecastForm
                form={form}
                loading={loading}
                submit={submit}
                handleChange={handle_form_change}
                disabled={read_only}
            />
        </React.Fragment>
    );
};

export default Forecast;
