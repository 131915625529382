/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";

import { toast } from "react-toastify";
import {
  Button,
  ButtonGroup,
  CssBaseline,
  FormControlLabel,
  Switch,
} from "@mui/material";

import maintenance_order, {
  PROBE_OPTIONS,
} from "constant_variables/tisp_maintenance";
import SimplePageTitle from "components/atoms/simple_page_title";
import _cloneDeep from "lodash.clonedeep";
import DialogButton from "components/molecules/dialog_button";
import { get_venn_difference } from "helper";
import { postTispMaintenance , postTispQuery } from "api/tisp_maintenance";
import EditableDataGrid from "components/organisms/editable_data_grid";
import MarkCell from "components/atoms/mak_cell";
import { user_state } from "components/root";
import { useRecoilState } from "recoil";
import PropTypes from "prop-types";
import { createTheme, ThemeProvider } from "@mui/material/styles";

TispMaintenanceTable.propTypes = {
  outage: PropTypes.bool,
};

const theme = createTheme({
  palette: {
    primary: {
      main: "#e20074",
    }
  },
})

export default function TispMaintenanceTable() {
  const [editData, setEditData] = useState([]);
  const [showData, setShowData] = useState([]);
  // const [limit, setLimit] = useState(0);
  const limit = 0;
  const [loading, setLoading] = useState(true);
  const [outage, setOutage] = useState(false);
  const [key, setKey] = useState("tisp_web_probes");
  const [edit, setEdit] = useState(false);
  const [userState] = useRecoilState(user_state);

  let read_only = true;
  if (userState !== null && userState.permissions) {
    read_only = userState.permissions.read_only;
  }

  const handle_discard = () => {
    setEditData(showData);
  };

  const handle_save = async () => {
    const updated_elements = get_venn_difference(editData, showData);
    if (updated_elements.length > 0) {
      let elements_for_post = _cloneDeep(updated_elements);
      elements_for_post = elements_for_post.map((e) => {
        delete e.id;
        return e;
      });
      try {
        setLoading(true);
        await postTispMaintenance(elements_for_post);
        toast.success("Entries updated!");
      } catch (err) {
        toast.error("Failed to update entries!");
      } finally {
        setLoading(false);
        getViewData();
        setEdit(false);
      }
    }
  };

  const getViewData = React.useCallback(async () => {
    setLoading(true);
    let error = false;
    try {
      const resp = await postTispQuery(
        {
          PK: key,
          queries: [{ id: "outage", values: [outage], attribute: "=" }],
          limit,
        },
      );

      const rows = resp.map((e, i) => ({ id: i, ...e, __mark__: false }));
      setShowData(rows);
      setEditData(_cloneDeep(rows));
    } catch (err) {
      error = true;
      console.trace(err);
    } finally {
      setLoading(false);
      if (error) toast.error("Failed");
    }
  }, [key, outage, limit]);

  const handle_edit = (edit_change) => {
    setEditData((old) => {
      Object.keys(edit_change).forEach((index_key) => {
        Object.keys(edit_change[index_key]).forEach((k) => {
          old[index_key][k] = edit_change[index_key][k].value;
        });
      });
      return old;
    });
  };

  const handle_mark = (value, props) => {
    const {
      row: { id },
    } = props;
    const new_element = { ...editData[id], __mark__: value };
    const newData = [...editData];
    newData[id] = new_element;
    setEditData(newData);
  };

  useEffect(() => {
    getViewData();
  }, [getViewData]);

  const adjusted_order = [
    ...maintenance_order,
    {
      field: "Mark for Delete",
      id: "__mark__",
      minWidth: 160,
      hide: !edit,
      renderCell: (e) => <MarkCell props={e} onMark={handle_mark} />,
    },
  ];

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <SimplePageTitle title={"Tisp Maintenance / Outage Table"} color="#e20074" />
        <div>
          <FormControlLabel
            control={<Switch id="view_outages" />}
            checked={outage}
            label="View Outages"
            onClick={() => setOutage(!outage)}
          />
          <div>
            <FormControlLabel
              control={<Switch id="edit_mode" />}
              disabled={read_only}
              checked={edit}
              label="Edit"
              onClick={() => setEdit(!edit)}
            />
          </div>
          {edit && (
            <ButtonGroup>
              <DialogButton
                callToAction="Save Changes"
                textColor="limegreen"
                title="Save changes"
                text="Are you sure you want to save changes?"
                onConfirm={handle_save}
              />
              <DialogButton
                callToAction="Discard"
                textColor="red"
                title="Discard changes"
                text="Are you sure you want to discard changes?"
                onConfirm={handle_discard}
              />
            </ButtonGroup>
          )}
        </div>
        {/* <div>
          <Button onClick={() => setLimit(0)} sx={{ backgroundColor: "#1e4d7d !important", color: "white !important", margin: "1em 0 1em 0em" }} > Request all data </Button>
        </div> */}
        <ButtonGroup>
          {PROBE_OPTIONS.map((k) => (
            <Button
              variant="contained"
              key={k.key}
              disabled={edit}
              onClick={() => setKey(k.key)}
              sx={{
                backgroundColor: k.key === key ? "#ff0083" : "#e20074",
                color: "white",
              }}
            >
              {k.alias}
            </Button>
          ))}
        </ButtonGroup>
        <EditableDataGrid
          edit={edit}
          loading={loading}
          rows={edit ? editData : showData}
          columns={adjusted_order}
          id={false}
          onEdit={handle_edit}
          onMark={handle_mark}
        />
      </ThemeProvider>

    </React.Fragment>
  );
}
