import { handleApi } from "./api";
import { ApiGateWayEndPoint, definedApiMethods, definedRouteTypes, } from ".";

export async function uploadFiles(files) {
  const upUrl = `${ApiGateWayEndPoint}/${definedRouteTypes.file}`;
  const file_map = files.map(({ name, type}) => ({ name: encodeURIComponent(name), type }));
  try {
    const response = await handleApi(
      upUrl,
      definedApiMethods.post,
      { file_map, signedUrlType: "putObject" },
    );
    const file_promises = response.map((r) => {
      const file = files.find((f) => encodeURIComponent(f.name) === r.name);
      return fetch(r.url, {
        method: "PUT",
        body: file,
      });
    })
    await Promise.all(file_promises);
    const upload_files= file_map.map((f) => ({ name: f.name, url: `https://logbook-files-upload.s3.eu-central-1.amazonaws.com/${f.name}`}))
    return  { success: true, upload_files };
  } catch (e) {
    throw new Error(e);
  } 
}

export async function getFiles(files) {
  const upUrl = `${ApiGateWayEndPoint}/${definedRouteTypes.file}`;
  const file_map = files.map(({ name, type}) => ({ name: encodeURIComponent(name), type }))
  try {
    const response = await handleApi(
      upUrl,
      definedApiMethods.post,
      { file_map, signedUrlType: "getObject" },
    );

    return response;
  } catch (e) {
    throw new Error(e);
  } 
}

export default uploadFiles;
