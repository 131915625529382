import { on_call_order_maker, field_maker } from "helper";

export const on_call_field_enum = {
    email: "email",
    status: "status",
    comment: "comment",
    log_time: "log_time",
};

const on_call_fields = {
    ...field_maker("email", "Email", "string"),
    ...field_maker("status", "Status", "string"),
    ...field_maker("comment", "Comment", "string"),
    ...field_maker("log_time", "Log Time (UTC)", "date"),
    ...field_maker("__delete__", "-", "string"),
};

const on_call_order = [
    on_call_order_maker(on_call_fields[on_call_field_enum.email], 300),
    on_call_order_maker(on_call_fields[on_call_field_enum.status], 100),
    on_call_order_maker(on_call_fields[on_call_field_enum.comment], 500),
    on_call_order_maker(on_call_fields[on_call_field_enum.log_time], 250),
    on_call_order_maker(on_call_fields["__delete__"], 80),
];

export default on_call_order;
