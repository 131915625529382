import { forecast_order_maker, field_maker } from "helper";


const forecast_fields = {
    ...field_maker("__view__", "-", "string"),
    ...field_maker("name", "Name", "string"),
    ...field_maker("team", "Team", "string"),
    ...field_maker("role", "Role", "string"),
    ...field_maker("goal_type", "Goal Type", "string"),
    ...field_maker("target_type", "Target Type", "string"),
    ...field_maker("year", "Year", "number"),
    ...field_maker("month", "Month", "number"),
    ...field_maker("value", "Value", "number"),
    ...field_maker("__delete__", "-", "string"),
};


const forecast_order = [
    forecast_order_maker(forecast_fields["__view__"], 100),
    forecast_order_maker(forecast_fields.name, 200),
    forecast_order_maker(forecast_fields.team, 100),
    forecast_order_maker(forecast_fields.role, 100),
    forecast_order_maker(forecast_fields.goal_type, 100),
    forecast_order_maker(forecast_fields.target_type, 140),
    forecast_order_maker(forecast_fields.year, 150),
    forecast_order_maker(forecast_fields.month, 140),
    forecast_order_maker(forecast_fields.value, 140),
    forecast_order_maker(forecast_fields["__delete__"], 30),
];

export default forecast_order;
