import React from "react";
import { Box } from "@mui/material";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";

function TextFieldItem({ question, updateQuestion }) {

  return (
    <>
      <FormLabel
        sx={{
          color: "rgba(0, 0, 0, 0.6) !important",
        }}
      >
        {question.questionLabel}
      </FormLabel>
      <Box>
        <TextField
          sx={{
            marginBottom: "30px",
            width: "100%"
          }}
          id="standard-textarea"
          multiline
          inputProps={{ maxLength: 4000 }}
          variant="standard"
          onChange={(e) => {
            question.value = e.target.value
            updateQuestion(question)

          }}
        />
      </Box>
    </>
  );
}

TextFieldItem.propTypes = {
  question: PropTypes.object,
  updateQuestion: PropTypes.func
};

export default TextFieldItem;
