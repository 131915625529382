import { handleApi } from "./api";
import {
  ApiGateWayEndPoint,
  definedApiMethods,
  definedRedshiftResources,
  definedRouteTypes,
} from ".";
  


export const getRedshiftExport = (type) => {
  const url =`${ApiGateWayEndPoint}/${definedRouteTypes.redshift}/${definedRedshiftResources.customers}?type=${type}`;
  return handleApi(url, definedApiMethods.get);
};

