import React from "react";
import AttachmentIcon from "@mui/icons-material/AttachFile"
import PropTypes from 'prop-types'



const AttachmentCell = ({ props }) => {
  const { value } = props;

  const color = (value && value.length > 0) ? "green" : "lightGray";
    return (<AttachmentIcon sx={{
        color,
    }} />)
};

AttachmentCell.propTypes = {
  props: PropTypes.object,
  value: PropTypes.array,
}

export default AttachmentCell;
