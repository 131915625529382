
import React from "react";

import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import PropTypes from "prop-types";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const SimpleSelect = ({ label, id, value, handleChange, options, disabled }) => {
  return (
    <FormControl fullWidth>
      <InputLabel id={label}>{label}</InputLabel>
      <Select
        labelId={label}
        id={id}
        multiple
        value={value}
        onChange={(e) => handleChange(id, e.target.value)}
        input={<Input id={`select=${label}`} />}
        MenuProps={MenuProps}
        disabled={disabled}
      >
        {options.map(({ key, alias }) => (
          <MenuItem key={key} value={key} >
            {alias}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

SimpleSelect.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.string),
  options: PropTypes.arrayOf(PropTypes.object),
  disabled: PropTypes.bool,
  handleChange: PropTypes.func,
};

export default SimpleSelect;
