

import React  from "react";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import { isValid, format } from "date-fns";

import { ReactComponent as NceLogo } from "../../../assets/light-nce-logo.svg";

import PropTypes from "prop-types";
import StyledAppBar from "./styles";
import { useRecoilValue } from "recoil";
import { version_state } from "components/root";
import Clock from "components/atoms/clock";

const NavigationBar = ({ open, toggleDrawer }) => {
  const { version } = useRecoilValue(version_state);
  let date = version;
  const cologne_timezone = 'Europe/Berlin';
  const riga_timezone = 'Europe/Riga';
  const miami_timezone = 'America/New_York';
  const uruguay_timezone = 'America/Montevideo';
  const tokyo_timezone = 'Asia/Tokyo';

  if(isValid(new Date(date))) {
    date = format(new Date(version), "do MMM yyyy")
  }
  return (
    <StyledAppBar position="absolute" open={open}>
      <Toolbar
          sx={{
            pr: '24px', // keep right padding when drawer closed
          }}
        >
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={toggleDrawer}
          sx={{
            marginRight: '36px',
            ...(open && { display: 'none' }),
          }}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          sx={{ flexGrow: 1 }}
        >
            {`Internal Applications  - Last Update ${date ? date : ""}`}
  
        </Typography>
        <Typography 
          sx={{display: 'flex'}}
          component="h1"
          variant="h6"
          color="inherit"
        >
        <Clock flag="US" city="Miami" timezone={miami_timezone} appBarClock={true} />
        <Clock flag="UY" city="Montevideo" timezone={uruguay_timezone} appBarClock={true} />
        <Clock flag="DE" city="Cologne" timezone={cologne_timezone} appBarClock={true} />
        <Clock flag="LV" city="Riga" timezone={riga_timezone} appBarClock={true} />
        <Clock flag="JP" city="Tokyo" timezone={tokyo_timezone} appBarClock={true} />
        

        </Typography>
        <NceLogo />
      </Toolbar>
    </StyledAppBar>
  );
};

NavigationBar.propTypes = {
  toggleDrawer: PropTypes.func,
  open: PropTypes.bool,
};

export default NavigationBar;
