import { tisp_api_probes_order_maker, field_maker } from "helper";


const forecast_fields = {
    ...field_maker("url", "URL", "string"),
    ...field_maker("status_code", "Status Code", "string"),
    ...field_maker("execution_time", "Execution Time", "string"),
    ...field_maker("availability", "Availability", "string"),
    ...field_maker("execution_timestamp", "Timestamp", "string"),
};


const forecast_order = [
    tisp_api_probes_order_maker(forecast_fields.url, 650),
    tisp_api_probes_order_maker(forecast_fields.execution_time, 200),
    tisp_api_probes_order_maker(forecast_fields.status_code, 200),
    tisp_api_probes_order_maker(forecast_fields.availability, 200),
    tisp_api_probes_order_maker(forecast_fields.execution_timestamp, 200),
];

export default forecast_order;
