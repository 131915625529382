/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import React, { useState, useEffect, useCallback } from "react";

// import postQuery from "../../../api/query";
import getNceChanges from "api/nce_changes";

import SimpleDataGrid from "components/organisms/data_grid";
import SimplePageTitle from "components/atoms/simple_page_title";
import { nce_changes_table_state, user_state } from "components/root";
import { useRecoilState } from "recoil";
import { toast } from "react-toastify";
import nce_changes_order from "constant_variables/nce_changes_fields";


export default function NceChangesTable() {
  const [showData, setShowData] = useRecoilState(nce_changes_table_state);
  const [userState] = useRecoilState(user_state);
  const [loading, setLoading] = useState(true);
  const [limit, setLimit] = useState(101);
  const [page, setPage] = useState(0);
  let read_only = true;
    if (userState !== null && userState.permissions) {
        read_only = userState.permissions.read_only;
    }

  const getViewData = useCallback(() => {
    const request = async() => {
      try {
        setLoading(true)
        const resp = await getNceChanges(
        );
        console.log(resp)
        setShowData(resp);
      } catch (err) {
        console.trace(err);
        toast.error("Failed to gather data")
      } finally {
        setLoading(false);
      }
    }

    request();
   
  }, [limit]);
  
  const getAllData = () => {
    if (limit === 101) {
      setLimit(0);
      setPage(1)
    }
  }
  

  useEffect(() => {
    getViewData();
  }, [getViewData]);


  return (
    <>
      <SimplePageTitle  title="Change Management Jira" />
      <SimpleDataGrid
        loading={loading}
        data={showData}
        order={nce_changes_order}
        id={false}
        getAllData={getAllData}
        page={page}
        setPage={setPage}
      />
    </>
  );
}
