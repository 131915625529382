import React from "react";

import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";

// import SimpleDatePicker from "components/atoms/simple_date_picker";
import SimpleSingleSelect from "components/atoms/simple_single_select";

import GridField from "components/molecules/grid_field";

import PropTypes from "prop-types";

import {
  FORECAST_MONTH_SELECT_VALUES,
  FORECAST_REGION_SELECT_VALUES

} from "constant_variables/drop_down_values";

import FIELD_SIZE from "constant_variables";
import SubmitButton from "components/atoms/submit_button";

//     "area": [],
//     "sub-area": [],
//     "main-region": [],
//     "main-vertical": [],
//     "main-topic": [],
//     "main-goal": [],

const ForecastForm = ({ submit, loading, form, handleChange, disabled, editMode }) => {

  return (
    <form
      noValidate
      autoComplete="off"
      style={{ width: "80%", margin: "auto" }}
      onSubmit={submit}
    >
      <Grid container spacing={3} alignItems="center">
        <GridField loading={loading} size={FIELD_SIZE.half}>
          <TextField
            id="name"
            label="Name"
            value={form["name"]}
            onChange={(e) => handleChange("name", e.target.value)}
            fullWidth
            disabled={disabled}
            multiline
          />
        </GridField>
        <GridField loading={loading} size={FIELD_SIZE.large}>
          <SimpleSingleSelect
            id="team"
            label="Team"
            value={form["team"]}
            handleChange={handleChange}
            options={FORECAST_REGION_SELECT_VALUES}
            disabled={disabled}
          />
        </GridField>
        <GridField loading={loading} size={FIELD_SIZE.half}>
          <SimpleSingleSelect
            id="month"
            label="Month"
            value={form["month"]}
            handleChange={handleChange}
            options={FORECAST_MONTH_SELECT_VALUES}
            disabled={disabled}
          />
        </GridField>
        <GridField loading={loading} size={FIELD_SIZE.half}>
          <TextField
            id="year"
            label="Year"
            type="number"
            value={form["year"]}
            onChange={(e) => handleChange("year", e.target.value)}
            fullWidth
            disabled={disabled}
          />
        </GridField>
        <GridField loading={loading} size={FIELD_SIZE.large}>
          <TextField
            id="role"
            label="Role"
            value={form["role"]}
            onChange={(e) => handleChange("role", e.target.value)}
            fullWidth
            disabled={disabled}
          />
        </GridField>
        <GridField loading={loading} size={FIELD_SIZE.large}>
          <TextField
            id="goal_type"
            label="Goal Type"
            value={form["goal_type"]}
            onChange={(e) => handleChange("goal_type", e.target.value)}
            fullWidth
            disabled={disabled}
          />
        </GridField>
        <GridField loading={loading} size={FIELD_SIZE.half}>
          <TextField
            id="target_type"
            label="Target Type"
            value={form["target_type"]}
            onChange={(e) => handleChange("target_type", e.target.value)}
            fullWidth
            disabled={disabled}
          />
        </GridField>
        <GridField loading={loading} size={FIELD_SIZE.half}>
          <TextField
            id="value"
            label="Value"
            type="number"
            value={form["value"]}
            onChange={(e) => handleChange("value", e.target.value)}
            fullWidth
            disabled={disabled}
          />
        </GridField>
        <Grid item xs={12} sm={12} lg={12} sx={{ textAlign: "center" }}>
          <SubmitButton
            label="submit"
            onClick={submit}
            disabled={!editMode && disabled}
          />
        </Grid>
      </Grid>
    </form>
  );
};

ForecastForm.propTypes = {
  loading: PropTypes.bool,
  form: PropTypes.object,
  submit: PropTypes.func,
  handleChange: PropTypes.func,
  disabled: PropTypes.bool,
  editMode: PropTypes.bool,
};


export default ForecastForm;
