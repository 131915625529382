import { trace_order_maker, field_maker } from "helper";

export const trace_field_enum = {
    id: "request_id",
    imsi: "imsi",
    status: "status",
    start_time: "start_time",
    end_time: "end_time",
    protocol: "protocol",
    export_type: "export_type",
    by: "user",
    created: 'created',
}


const trace_fields = {
    ...field_maker(trace_field_enum.id, "-", "string"),
    ...field_maker(trace_field_enum.imsi, "Imsi", "string"),
    ...field_maker(trace_field_enum.status, "Status", "string"),
    ...field_maker(trace_field_enum.start_time, "Start Time (UTC)", "date"),
    ...field_maker(trace_field_enum.end_time, "End Time (UTC)", "date"),
    ...field_maker(trace_field_enum.protocol, "Protocol", "string"),
    ...field_maker(trace_field_enum.export_type, "Requested Format", "string"),
    ...field_maker(trace_field_enum.by, "User", "string"),
    ...field_maker(trace_field_enum.created, "Searched", "date"),
}

// export const trace_root_maker = () => {
//     Object.keys(trace_fields).forEach((f) => {

//     })
// }

const trace_order = [
    trace_order_maker(trace_fields[trace_field_enum.id], 160),
    trace_order_maker(trace_fields[trace_field_enum.imsi], 200),
    trace_order_maker(trace_fields[trace_field_enum.status], 200),
    trace_order_maker(trace_fields[trace_field_enum.start_time], 200),
    trace_order_maker(trace_fields[trace_field_enum.end_time], 200),
    trace_order_maker(trace_fields[trace_field_enum.protocol], 200),
    trace_order_maker(trace_fields[trace_field_enum.export_type], 200),
    trace_order_maker(trace_fields[trace_field_enum.by], 300),
    trace_order_maker(trace_fields[trace_field_enum.created], 300),
  ]

  export default trace_order;




