/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import FormControlLabel from '@mui/material/FormControlLabel';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Switch from '@mui/material/Switch';

import FormSkeleton from "components/atoms/form_skeleton";
import LogbookForm from "components/organisms/logbook_form";

import postLogbook from "api/logbook";
import { postFind } from "api/find";

import { useRecoilState } from "recoil";
import { user_state } from "components/root";
import { getFiles } from "../../../api/file_api"

const img_formats = ["jpg", "svg", "png", "jpeg"];

const get_images = async (files = []) => {
  const images = [];
  const resp = await getFiles(files);
  resp.forEach(f => {
    if (img_formats.some((i) => f.url.includes(i))) {
      images.push(f)
    }
  })
  return images;
}

const LogbookView = () => {
  const { id } = useParams();
  const [form, setForm] = useState(null);
  const [edit, setEdit] = useState(false);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);

  const [userState] = useRecoilState(user_state);
  const { permissions: { read_only } } = userState;

  useEffect(() => {
    const get_entry = async (route_id) => {
      const SK = route_id.split("SK=")[1];
      const PK = route_id.split("PK=")[1].split("&")[0];
      try {
        const resp = await postFind(PK, SK)
        if (resp.files) {
          setImages(await get_images(resp.files))
          setFiles(resp.files)
        }
        setForm(resp)
      } catch (err) {
        toast.error("Failed");
        setLoading(false);
      }
    }
    if (id) get_entry(id)

  }, [id])

  const handle_form_change = (key, value) => {
    setForm((old) => ({
      ...old,
      [key]: value,
    }));
  };

  const handle_add_file = (value) => {
    setFiles((old) => ([
      ...old,
      ...value
    ]));
  };

  const submit = async (e) => {
    setLoading(true);
    try {
      e.preventDefault();
      await postLogbook(form, files)
      toast.success("Logbook entry updated")
    } catch (err) {
      toast.error("Failed!")
    } finally {

      setLoading(false);
    }
  };

  if (!form || loading) {
    return <FormSkeleton />
  }

  return (
    <div style={{ textAlign: "center" }}>
      <h3>Viewing entry <strong>{form.SK}</strong> </h3>
      <ImageList cols={3} gap={8} sx={{ margin: "auto", width: "80%" }}>
        {images.map((item) => (
          <ImageListItem key={item.url} onClick={() => window.open(item.url)} >
            <img
              src={`${item.url}`}
              srcSet={`${item.url}`}
              alt={item.name}
              loading="lazy"
            />
          </ImageListItem>
        ))}
      </ImageList>
      <FormControlLabel disabled={read_only} control={<Switch checked={edit} onChange={() => setEdit(!edit)} />} label="Edit entry" />
      <LogbookForm disabled={!edit} submit={submit} loading={loading} files={files} addFile={handle_add_file} form={form} handleChange={handle_form_change} editMode={edit} />
    </div>
  );
};

export default LogbookView;
