import { handleApi } from "./api";
import {
    ApiGateWayEndPoint,
    definedRouteTypes,
    definedApiMethods,
    definedForecastResources,
} from ".";
import uploadFiles from "./file_api";

export const postForecast = async (body) => {
    const url = `${ApiGateWayEndPoint}/${definedRouteTypes.forecast}/${definedForecastResources.post}`;
    return handleApi(url, definedApiMethods.post, { ...body });
};

export const importForecast = async (files) => {
    let add_files = [];
    if (files && files.length > 0) {
        const { upload_files } = await uploadFiles(files);
        add_files = upload_files;
    }
    const url = `${ApiGateWayEndPoint}/${definedRouteTypes.forecast}/${definedForecastResources.import}`;
    const resp = await handleApi(url, definedApiMethods.post, { files: add_files });
    return resp;
};

export const forecast_post_batch = async (body) => {
    const url = `${ApiGateWayEndPoint}/${definedRouteTypes.forecast}/${definedForecastResources.post_batch}`;
    const resp = await handleApi(url, definedApiMethods.post, body);
    return resp;
};

export default postForecast;
