import { handleApi  } from "./api";
import { definedApiMethods, definedLoginResources, SsoEndpoint } from ".";

const GetSSORedirect = () => {
    //const { context } = sp.createLoginRequest(idp, "redirect");
    //https://d-996723e5f2.awsapps.com/start/#/saml/default/Logbook-DEV/ins-8d30ad5ed4883e6d"
    //"https://d-996723e5f2.awsapps.com/start/#/saml/default/Internal/ins-d7481beadbdda8f3"
    window.location.assign(process.env.REACT_APP_AWS_APP_URL);
};

export const VerifySSO = async () => {
    const url = `${SsoEndpoint}/${definedLoginResources.verify}`;
    return handleApi(url, definedApiMethods.get);
};

export default GetSSORedirect;
