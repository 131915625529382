import { nce_changes_order_maker, field_maker } from "helper";

export const nce_changes_field_enum = {
  id: "id",
  start_time_cetcest: "start-time-cetcest",
  end_time_cetcest: "end-time-cetcest",
  dur_hhmm: "dur-hhmm",
  area: "area",
  entry_type: "entry-type",
  tenant: "tenant",
  creator: "creator",
  on_call: "on-call",
  where: "where",
  customer_impact: "customer-impact",
  detected_by: "detected-by",
  ticket_change_id: "ticket-change-id",
  what: "what",
  root_cause_comments: "root-cause-comments",
  followed_up_by: "followed-up-by",
  created_by: "createdBy",
};

const nce_changes_fields = {
  ...field_maker("id", "-", "string"),
  ...field_maker("start-time-cetcest", "Start Time (UTC)", "date"),
  ...field_maker("end-time-cetcest", "End Time (UTC)", "date"),
  ...field_maker("dur-hhmm", "Duration", "string"),
  ...field_maker("area", "Area", "string"),
  ...field_maker("entry-type", "Entry Type", "string"),
  ...field_maker("tenant", "Tenant", "string"),
  ...field_maker("creator", "Creator", "string"),
  ...field_maker("on-call", "On-Call", "boolean"),
  ...field_maker("where", "Where", "string"),
  ...field_maker("customer-impact", "Cust. Impact", "boolean"),
  ...field_maker("detected-by", "Detected By", "string"),
  ...field_maker("ticket-change-id", "Ticket / Change Id"),
  ...field_maker("what", "What", "string"),
  ...field_maker("root-cause-comments", "Root Cause Comments", "string"),
  ...field_maker("followed-up-by", "Followed Up By", "string"),
  ...field_maker("createdBy", "Created By", "string"),
};

const nce_changes_order = [
  nce_changes_order_maker(nce_changes_fields["id"], 160),
  nce_changes_order_maker(nce_changes_fields["start-time-cetcest"], 180),
  nce_changes_order_maker(nce_changes_fields["end-time-cetcest"], 180),
  nce_changes_order_maker(nce_changes_fields["dur-hhmm"], 100),
  nce_changes_order_maker(nce_changes_fields["area"], 160),
  nce_changes_order_maker(nce_changes_fields["entry-type"], 140),
  nce_changes_order_maker(nce_changes_fields["tenant"], 130),
  nce_changes_order_maker(nce_changes_fields["what"], 500),
  nce_changes_order_maker(nce_changes_fields["on-call"], 140),
  nce_changes_order_maker(nce_changes_fields["where"], 120),
  nce_changes_order_maker(nce_changes_fields["customer-impact"], 140),
  nce_changes_order_maker(nce_changes_fields["detected-by"], 80),
  nce_changes_order_maker(nce_changes_fields["ticket-change-id"]),
  nce_changes_order_maker(nce_changes_fields["root-cause-comments"], 500),
  nce_changes_order_maker(nce_changes_fields["followed-up-by"], 180),
  nce_changes_order_maker(nce_changes_fields["createdBy"], 180),
  nce_changes_order_maker(nce_changes_fields["creator"], 180),
];

export default nce_changes_order;
