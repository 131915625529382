import { maintenance_outage_order_maker, field_maker } from "helper";
import { alias_maker, select_options_maker } from "helper";

export const TISP_MAINTENANCE_FIELD_ENUM = {
    maintenance_starttime: "maintenance_starttime",
    maintenance_endtime: "maintenance_endtime",
    comments: "comments",
    url_affected: "url_affected",
};

const maintenance_fields = {
    ...field_maker("id", "-", "string"),
    ...field_maker(
        TISP_MAINTENANCE_FIELD_ENUM.maintenance_starttime,
        "Start Time (UTC)",
        "dateTime"
    ),
    ...field_maker(TISP_MAINTENANCE_FIELD_ENUM.maintenance_endtime, "End Time (UTC)", "dateTime"),
    ...field_maker(TISP_MAINTENANCE_FIELD_ENUM.comments, "Comments", "string"),
    ...field_maker(TISP_MAINTENANCE_FIELD_ENUM.url_affected, "URLs Affected", "string"),
    ...field_maker("__mark__", "Mark for Delete", "string"),
};

const TISP_MAINTENANCE_ORDER = [
    maintenance_outage_order_maker(
        maintenance_fields[TISP_MAINTENANCE_FIELD_ENUM.maintenance_starttime],
        200
    ),
    maintenance_outage_order_maker(
        maintenance_fields[TISP_MAINTENANCE_FIELD_ENUM.maintenance_endtime],
        200
    ),
    maintenance_outage_order_maker(maintenance_fields[TISP_MAINTENANCE_FIELD_ENUM.comments], 400),
    maintenance_outage_order_maker(
        maintenance_fields[TISP_MAINTENANCE_FIELD_ENUM.url_affected],
        400
    ),
];

export const OFF_NET_WORLD_URLS = [
    alias_maker("https://www.hotspotdrive.com/#/", "Jumpboard"),
    alias_maker("https://www.telekom.hotspotdrive.com/#/", "Volvo Polestar Country Selection"),
    alias_maker("https://www.rrmc.hotspotdrive.com/#/", "RR Country Selection"),
    alias_maker("https://www.daimlerna.hotspotdrive.com/#/", "Daimler Country Selection"),
];

export const OFF_NET_EU_URLS = [
    alias_maker("https://www.bmw.hotspotdrive.com/#/", "EU BMW Country Selection"),
];

export const OFF_NET_NA_URLS = [
    alias_maker("https://www.bmwna.hotspotdrive.com/#/", "NA BMW Country Selection"),
];

export const AAX_LOGIN_PAGE_URLS = [
    alias_maker("https://ccprod-gui-emea.aax2.net/aax2/login.jsf", "AAX Login Page"),
];

export const PROBE_SERVICES = {
    "OFF-NET-WORLD": OFF_NET_WORLD_URLS,
    "OFF-NET-EU": OFF_NET_EU_URLS,
    "OFF-NET-NA": OFF_NET_NA_URLS,
    "AAX-LOGIN-PAGE": AAX_LOGIN_PAGE_URLS,
};

export const NETWORK_SERVICES_OPTIONS = {
    tisp_network_probes_bmw_eu: [
        alias_maker("EU-LV-RIX-BMWRR-1m-1"),
        alias_maker("EU-DE-CGN-BMWRR-1m-2"),
        alias_maker("EU-LV-RIX-BMWRR-24h-1"),
        alias_maker("EU-LV-RIX-BMWRR-1"),
    ],
    tisp_network_probes_volvo_eu: [
        alias_maker("EU-LV-RIX-VOLVO-1m-1"),
        alias_maker("EU-DE-CGN-VOLVO-1m-2"),
        alias_maker("EU-DE-CGN-VOLVO-24h-2"),
        alias_maker("EU-LV-RIX-VOLVO-24h-1"),
        alias_maker("EU-LV-RIX-VOLVO-1"),
        alias_maker("EU-DE-CGN-VOLVO-2"),
    ],
    tisp_network_probes_bmw_na: [
        alias_maker("US-FL-MI-BMWNA-1m-1"),
        alias_maker("EU-DE-CGN-BMWNA-1m-2"),
        alias_maker("US-FL-MI-BMWNA-24h-1"),
        alias_maker("US-FL-MI-BMWNA-1"),
    ],
    tisp_network_probes_dagna: [
        alias_maker("US-FL-MI-DAGNA-1m-1"),
        alias_maker("EU-DE-CGN-DAGNA-1m-2"),
        alias_maker("US-FL-MI-DAGNA-24h-1"),
        alias_maker("EU-DE-CGN-DAGNA-24h-2"),
        alias_maker("US-FL-MI-DAGNA-1"),
        alias_maker("EU-DE-CGN-DAGNA-2"),
    ],
};

export const WEB_SERVICES_OPTIONS = select_options_maker(Object.keys(PROBE_SERVICES));

export const PROBE_OPTIONS = [
    alias_maker("tisp_web_probes", "Web probes"),
    alias_maker("tisp_network_probes_bmw_eu", "BMR/RR EU Network"),
    alias_maker("tisp_network_probes_volvo_eu", "VOLVO EU Network"),
    alias_maker("tisp_network_probes_bmw_na", "BMW NA Network"),
    alias_maker("tisp_network_probes_dagna", "DAGNA Network"),
];

export default TISP_MAINTENANCE_ORDER;
