import React, { useEffect, useState, useCallback } from "react";
import { useParams  } from "react-router-dom";
import { toast } from "react-toastify";

import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import Button from "@mui/material/Button";

import { getQueryStatus } from "api/trace";
import { Skeleton } from "@mui/material";
// import { Button } from "@mui/material";


const TraceView = () => {
  const { id } = useParams();
  const [form_data, setForm] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [csvData, setCsvData] = useState([]);

  const get_download = (path) => {
    if (path) {
      // https://1nce-data-lake-prod.s3.eu-central-1.amazonaws.com/traces/merged/06e33670-2202-44c3-b93f-7c5a52b2c109/ee14a383-5f12-4e46-b70d-902f3e33ed47.pcapng
      // const url = "https://logbook-files-upload.s3.eu-central-1.amazonaws.com/4c6b17eb-6bc4-4a78-8b63-d5bf3188b14f.pcapng"
      const url = path;
      window.open(url);
    }
  }


  
  const check_statuses_callback = useCallback(() => {
    const check_status = async() => {
      setLoading(true)
      try { 
        const resp = await getQueryStatus({ traces: [{ request_id: id }] });
        if (resp && resp[0]) {
          setForm(resp);
          toast.success("Statuses updated")
        }
      } catch (err) {
        console.trace(err);
        toast.error("Failed to check status")
      } finally {
        setLoading(false);
      }
      
    }
    check_status();
   
  }, [id]);


  useEffect(() => {
      check_statuses_callback();
    }, [check_statuses_callback])

  return (
   <div style={{ textAlign: "center"}}>
      <h3>Viewing Trace File Analysis Request <strong>{id}</strong> </h3>
      <Button onClick={() => check_statuses_callback()}> Recheck statuses </Button>
     <form style={{ marginTop: "2em"}}>
       {
         loading ? <Skeleton sx={{ margin: "auto"}}  height={80} width="80%" /> :
        
            <TableContainer sx={{ marginTop: "2em", textAlign: "center"}}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>QUERY STATUS </TableCell>
                    <TableCell>PCAP STATUS</TableCell>
                    <TableCell> PCAP DOWNLOAD </TableCell>
                    <TableCell>CSV STATUS</TableCell>
                    <TableCell> CSV DOWNLOAD </TableCell>
                    <TableCell>PROTOCOL</TableCell>
                  </TableRow>
                </TableHead>
            <TableBody>
            {form_data.map((row) => (
              <TableRow key={row.request_id} sx={{ textAlign: "center"}}>
                  <TableCell>{row.status} </TableCell>
                  <TableCell>
                    {row.pcap_status}
                  </TableCell>
                  <TableCell>
                    {row.pcap_path.map((p, i) => <Button key={p} onClick={() => get_download(p)}> File {i+1} </Button>)}
                  </TableCell>
                  <TableCell> 
                    {row.csv_status}
                  </TableCell>
                  <TableCell> 
                    {row.csv_path.map((p, i) => <Button key={p} onClick={() => get_download(p)}> File {i+1} </Button>)}
                  </TableCell>
                  <TableCell>{row.protocol}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
  }
     </form>
     {/* { form_data.csv_path.length > 0 && 
     <TableContainer sx={{ marginTop: "2em", textAlign: "center", backgroundColor: "white"}}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell> NAME</TableCell>
                  <TableCell>PROTOCOL </TableCell>
                  <TableCell> URL </TableCell>
                </TableRow>
              </TableHead>
          <TableBody>
          {form_data.csv_path.map((row) => (
            <TableRow key={row} sx={{ textAlign: "center"}}>
                <TableCell>{row} </TableCell>
                <TableCell>
                  {row}
                </TableCell>
                <TableCell> 
                  <Button onClick={() => window.open(row.url)}> Dowload </Button> 
                </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer> } */}
   </div>
  );
};

export default TraceView;
