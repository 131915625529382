import React from "react";
import Checkbox from "@mui/material/Checkbox";

import PropTypes from 'prop-types'

const CheckboxCell = ({ props, onCheck, field }) => {
  const { row } = props;
 return (
    <div>
      <Checkbox checked={row[field.field]} color="default" onClick={(e) => onCheck(e.target.checked, props)}  />
    </div>
 )
};

CheckboxCell.propTypes = {
  props: PropTypes.object,
  value: PropTypes.any,
  row: PropTypes.object,
  onCheck: PropTypes.func,
  field: PropTypes.object,
}

export default CheckboxCell;
