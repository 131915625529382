import React from "react";

import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";

import SimpleDateTimePicker from "components/atoms/simple_date_time_picker";

import GridField from "components/molecules/grid_field";

import PropTypes from "prop-types";

import {
  TRACE_EXPORT_TYPES,
  TRACE_PROTOCOLS, 
} from "constant_variables/drop_down_values";

import FIELD_SIZE  from "constant_variables";
import SubmitButton from "components/atoms/submit_button";
import { trace_field_enum } from "constant_variables/trace_fields";
import SimpleSelect from "components/atoms/simple_select";
import SimpleSingleSelect from "components/atoms/simple_single_select";


const LogbookForm = ({ submit, loading, form, handleChange, disabled, editMode }) => {

  return (
    <form
    noValidate
    autoComplete="off"
    style={{ width: "80%", margin: "auto" }}
    onSubmit={submit}
  >
    <Grid container spacing={3} alignItems="center">
      <GridField loading={loading} size={FIELD_SIZE.large}>
        <SimpleDateTimePicker
          id={trace_field_enum.start_time}
          label="Start date (UTC)"
          value={form[trace_field_enum.start_time]}
          handleChange={handleChange}
        />
      </GridField>
      <GridField loading={loading} size={FIELD_SIZE.large}>
        <SimpleDateTimePicker
          id={trace_field_enum.end_time}
          label="End date (UTC)"
          value={form[trace_field_enum.end_time]}
          handleChange={handleChange}
        />
      </GridField>
      <GridField loading={loading} size={FIELD_SIZE.medium}>
        <SimpleSingleSelect
          id={trace_field_enum.protocol}
          label="Protocol"
          value={form[trace_field_enum.protocol]}
          handleChange={handleChange}
          options={TRACE_PROTOCOLS}
        />
      </GridField>
      <GridField loading={loading} size={FIELD_SIZE.medium}>
        <SimpleSelect
          id={trace_field_enum.export_type}
          label="Export Type"
          value={form[trace_field_enum.export_type]}
          handleChange={handleChange}
          options={TRACE_EXPORT_TYPES}
        />
      </GridField>
      <GridField loading={loading}>
        <TextField
          id="imsi"
          label="IMSI"
          value={form["imsi"]}
          multiline
          onChange={(e) =>
            handleChange("imsi", e.target.value)
          }
          fullWidth
        />
      </GridField>
      <Grid item xs={12} sm={12} lg={12} sx={{ textAlign: "center"}}>
          <SubmitButton 
            label="submit"
            onClick={submit}
            disabled={!editMode && disabled}
          />
      </Grid>
    </Grid>
  </form>
  );
};

LogbookForm.propTypes = {
    loading: PropTypes.bool,
    form: PropTypes.object,
    submit: PropTypes.func,
    handleChange: PropTypes.func,
    disabled: PropTypes.bool,
    editMode: PropTypes.bool,
  };
  

export default LogbookForm;
