import React from "react";

import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";

import SimpleDateTimePicker from "components/atoms/simple_date_time_picker";
import SimpleSingleSelect from "components/atoms/simple_single_select";

import GridField from "components/molecules/grid_field";

import PropTypes from "prop-types";

import {
    MAIN_GOAL_VALUES,
    MAIN_REGION_VALUES,
  MAIN_TOPIC_VALUES,
  MAIN_VERTICAL_VALUES,
  MARKETING_AREA_SELECT_VALUES, 
  MARKETING_SUB_AREA_SELECT_VALUES, 

} from "constant_variables/drop_down_values";

import FIELD_SIZE  from "constant_variables";
import SubmitButton from "components/atoms/submit_button";

//     "area": [],
//     "sub-area": [],
//     "main-region": [],
//     "main-vertical": [],
//     "main-topic": [],
//     "main-goal": [],

const MarketingForm = ({ submit, loading, form, handleChange, disabled, editMode }) => {

  return (
    <form
    noValidate
    autoComplete="off"
    style={{ width: "80%", margin: "auto" }}
    onSubmit={submit}
  >
    <Grid container spacing={3} alignItems="center">
      <GridField loading={loading} size={FIELD_SIZE.half}>
        <SimpleDateTimePicker
          id="start-time-cetcest"
          label="Start date (UTC)"
          value={form["start-time-cetcest"]}
          handleChange={handleChange}
          disabled={disabled}
        />
      </GridField>
      <GridField loading={loading} size={FIELD_SIZE.half}>
        <TextField
          id="activity-name"
          label="Activity name"
          value={form["activity-name"]}
          onChange={(e) => handleChange("activity-name", e.target.value)}
          fullWidth
          disabled={disabled}
          multiline
        />
      </GridField>
      <GridField loading={loading} size={FIELD_SIZE.medium}>
        <SimpleSingleSelect
          id="area"
          label="Area"
          value={form["area"]}
          handleChange={handleChange}
          options={MARKETING_AREA_SELECT_VALUES}
          disabled={disabled}
        />
      </GridField>
      <GridField loading={loading} size={FIELD_SIZE.medium}>
        <SimpleSingleSelect
          id="sub-area"
          label="Sub area"
          value={form["sub-area"]}
          handleChange={handleChange}
          options={MARKETING_SUB_AREA_SELECT_VALUES}
          disabled={disabled}
        />
      </GridField>
      <GridField loading={loading} size={FIELD_SIZE.medium}>
        <SimpleSingleSelect
          id="main-region"
          label="Main Region"
          value={form["main-region"]}
          handleChange={handleChange}
          options={MAIN_REGION_VALUES}
          disabled={disabled}
        />
      </GridField>
      <GridField loading={loading} size={FIELD_SIZE.medium}>
        <SimpleSingleSelect
          id="main-vertical"
          label="Main Vertical"
          value={form["main-vertical"]}
          handleChange={handleChange}
          options={MAIN_VERTICAL_VALUES}
          disabled={disabled}
        />
      </GridField>
      <GridField loading={loading} size={FIELD_SIZE.medium}>
        <SimpleSingleSelect
          id="main-topic"
          label="Main Topic"
          value={form["main-topic"]}
          handleChange={handleChange}
          options={MAIN_TOPIC_VALUES}
          disabled={disabled}
        />
      </GridField>
      <GridField loading={loading} size={FIELD_SIZE.medium}>
        <SimpleSingleSelect
          id="main-goal"
          label="Main Goal"
          value={form["main-goal"]}
          handleChange={handleChange}
          options={MAIN_GOAL_VALUES}
          disabled={disabled}
        />
      </GridField>
    
      <Grid item xs={12} sm={12} lg={12} sx={{ textAlign: "center"}}>
          <SubmitButton 
            label="submit"
            onClick={submit}
            disabled={!editMode && disabled}
          />
      </Grid>
    </Grid>
  </form>
  );
};

MarketingForm.propTypes = {
    loading: PropTypes.bool,
    form: PropTypes.object,
    submit: PropTypes.func,
    handleChange: PropTypes.func,
    disabled: PropTypes.bool,
    editMode: PropTypes.bool,
  };
  

export default MarketingForm;
