/* eslint-disable no-unused-vars */

import React, { useEffect, useState, useCallback } from "react";
import Box from '@mui/material/Box';
import SimplePageTitle from "components/atoms/simple_page_title";
import SimpleDataGrid from "components/organisms/data_grid";
import TispApiProbeFields from "constant_variables/tisp_api_probes_fields";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { getProbeResults } from "../../../api/api_probes";
//WEBSOCKET 
const uuid = require("uuid");

const ApiProbeResults = () => {
  const [results, setResults] = useState([]);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [displayFailed, setDisplayFailed] = useState(false);
  
  const getViewData = useCallback(() => {
    const request = async () => {
      try {
        setLoading(true);
        const { results } = await getProbeResults();
        setResults(results);
        setLoading(false);
      } catch (err) {
        console.trace(err);
      }
    }

    request();

  }, []);

  useEffect(async () => {
    getViewData();
  }, [getViewData]);


  const getAllData = () => {
  };

  return (
    <>
      <SimplePageTitle title="API Probe Result Page" />
      <ToggleButtonGroup
            sx={{
              marginTop: "30px"
            }}
            color="primary"
            value={displayFailed}
            exclusive
            onChange={(e, newValue) => { 
              setDisplayFailed(newValue);
            }}
            aria-label="Platform"
          >
            <ToggleButton value={false}>Show All</ToggleButton>
            <ToggleButton value={true}>Failed Only</ToggleButton>
          </ToggleButtonGroup>
      <Box sx={{ width: "100%", margin: "auto", marginTop: "30px" }}>
        <SimpleDataGrid
          sx={{ marginTop: "50px" }}
          loading={loading}
          data={displayFailed ? [...results].filter(result => !result.availability) : results}
          order={TispApiProbeFields}
          id={false}
          getAllData={getAllData}
          page={page}
          setPage={setPage}
        />
      </Box>
    </>
  );
};

export default ApiProbeResults;
