import { Button, ButtonGroup } from "@mui/material";
import React, { useState, useEffect, useCallback } from "react";


import SimpleDataGrid from "components/organisms/data_grid";

import SimplePageTitle from "components/atoms/simple_page_title";

import web_probe_order from "constant_variables/web_probe_fields";
import getWebProbeData, { getWebProbeConfig } from "api/webprobe_api";

export default function WebProbeTable() {
  const [showData, setShowData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [config, setConfig] = useState([]);
  const [key, setKey] = useState("NOT FOUND")
  const [type, setType] = useState("error");
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(0);
  const setItem = (key ,type) => {
      setKey(key);
      setType(type);
  }
  const getViewData = useCallback(() => {
    const request = async() => {
      try {
        setLoading(true)
        const config = await getWebProbeConfig();
        const resp = await getWebProbeData({
          key: key,
          type: type,
          limit,
        });
        setShowData(resp);
        setConfig(config);
      } catch (err) {
        console.trace(err);
      } finally {
        setLoading(false);
      }
    }
    request();
   
  }, [key, type, limit]);
  
  const getAllData = () => {
    if (limit === 20) {
      setLimit(0);
      setPage(1)
    }
  }

  useEffect(() => {
    getViewData();
  }, [getViewData]);

  return (
    <>
      <SimplePageTitle  title="Webprobe Info Table" />
      {/* <div style={{ marginBottom: "1em" }}>
        <Button onClick={() => setLimit(0)} sx={{ backgroundColor: "#1e4d7d !important", color: "white !important"}} > Request all data </Button>
      </div> */}
      <ButtonGroup sx={{display: "flex", flexWrap: "wrap", justifyContent: "flex-start"}}>
      {
        ["NOT FOUND",  "TIMEOUT", "ERROR","SERVER ERROR", "STEP FUNCTION SERVER ERROR" ].map((k) => 
          <Button 
            variant="contained"
            key={k}
            onClick={() => setItem(k, "error")}
            sx={{ backgroundColor:  k === key ? "29abe2" : "#1e4d7d", color: "white"}}
          >
            {k}
          </Button>)
      }
      {
        config.map((k) => 
          <Button 
            variant="contained"
            key={k.type}
            onClick={() => setItem(k.url, "url")}
            sx={{ backgroundColor:  k.url === key ? "29abe2" : "#1e4d7d", color: "white"}}
          >
            {k.type}
          </Button>)
      }
      </ButtonGroup>
      <SimpleDataGrid
        loading={loading}
        initialState={{
          sorting: {
            sortModel: [{ field: 'start_time', sort: 'desc' }],
          },
        }}
        data={showData}
        order={web_probe_order}
        id={false}
        page={page}
        setPage={setPage}
        getAllData={getAllData}
      />
    </>
  );
}
