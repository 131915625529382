import { select_options_maker } from "helper";

const area_values = ["Global API", "Core", "Access", "BSS", "VPN", "Roaming", "Other"];

const nce_os_type_values = [
    "Important Incident",
    "Slack Alarm",
    "Migration Request",
    "Data Import Request",
    "System Validation",
    "Information Request",
    "Other",
];

const nce_os_trigger_values = [
    "Slack Message",
    "OpsGenie",
    "Incident Manager",
    "Service Desk Ticket",
];

const nce_os_area_values = [
    "Sim Card Pool",
    "Infrastructure",
    "API Endpoint",
    "Customer Integrations",
    "Data Broker [Endpoints]",
    "Data Broker [Bricks]",
    "1NCE Portal [Front-end]",
    "Other (Manual Input)",
];

const entry_type_values = ["Workoder", "Incident", "Change", "Others"];

const tenant_values = ["1NCE", "1NCE/DTAG", "DTAG", "DTAG/TMNL", "DTAG/TDG", "CTG"];

const where_values = [
    "vEPC",
    "NCE",
    "BSS",
    "Megaport",
    "DTAG",
    "1NCE",
    "OpenVPN",
    "Deutsche Telekom",
    "Roaming Partner",
    "Huricane",
    "CTG",
    "Monitoring",
    "NCE/GTP",
    "Opteamax",
    "NCE/CGW",
    "Website",
    "NCE/STC",
    "Network Coverage",
    "vEPC/PGW",
    "API",
    "ICSS",
    "NCE/DSC",
    "NCE/vEPC",
    "AWS Infra",
];

const detected_by_values = [
    "Others",
    "Customer",
    "Emnify",
    "Probe",
    "Alert",
    "Grafana",
    "Tableau +\r\nCustomer",
];

const customer_impact_values = ["yes", "no"];

const on_call_values = ["yes", "no"];

const marketing_area_values = [
    "BrandMarketing",
    "Communications",
    "EventMarketing",
    "OnlineMarketing",
];

const marketing_sub_area_values = [
    "Blog Posts",
    "Direct Mailing",
    "Newsletter",
    "Playbooks",
    "Social Media",
    "Trade Shows",
];

const main_region_values = ["GLOBAL", "IT"];

const main_vertical_values = ["Various Verticals", "Smart metering"];

const main_topic_values = ["Corporate", "Customer", "Event", "Product"];

const main_goal_values = ["Brand Awareness", "First Orders", "Lead Generation"];

const trace_export_types = ["csv", "pcap"];
const trace_protocols = ["gtp", "diameter", "ss7"];
const forecast_months = [
    { key: 1, alias: "January" },
    { key: 2, alias: "February" },
    { key: 3, alias: "March" },
    { key: 4, alias: "April" },
    { key: 5, alias: "May" },
    { key: 6, alias: "June" },
    { key: 7, alias: "July" },
    { key: 8, alias: "August" },
    { key: 9, alias: "September" },
    { key: 10, alias: "October" },
    { key: 11, alias: "November" },
    { key: 12, alias: "December" },
];

const tisp_countries = [
    { key: "at", alias: "Austria" },
    { key: "be", alias: "Belgium" },
    { key: "cz", alias: "Czech Republic" },
    { key: "dk", alias: "Denmark" },
    { key: "fi", alias: "Finland" },
    { key: "fr", alias: "France" },
    { key: "de", alias: "Germany" },
    { key: "ie", alias: "Ireland" },
    { key: "it", alias: "Italy" },
    { key: "lu", alias: "Luxembourg" },
    { key: "nl", alias: "Netherlands" },
    { key: "no", alias: "Norway" },
    { key: "pl", alias: "Poland" },
    { key: "pt", alias: "Portugal" },
    { key: "es", alias: "Spain" },
    { key: "se", alias: "Sweden" },
    { key: "ch", alias: "Switzerland" },
    { key: "gb", alias: "United Kingdom" },
];

const tisp_mandants = [
    { key: "10000286", alias: "BMW" },
    { key: "10000349", alias: "BMWNA" },
    { key: "10000355", alias: "RRMC" },
    { key: "10000347", alias: "TELEKOM" },
    { key: "10000475", alias: "DAIMLERNA" },
];

const tisp_app_clients = [
    { key: "bmw", alias: "BMW" },
    { key: "bmwna", alias: "BMWNA" },
    { key: "rr", alias: "RRMC" },
    { key: "dagna", alias: "DAGNA" },
    { key: "vps", alias: "TELEKOM" },
];

const forecast_regions = ["EU West", "EU Central", "EU North", "US", "Other"];

const on_call_status_values = ["No issues", "Minor issues", "Outage"];

export const AREA_SELECT_VALUES = select_options_maker(area_values);
export const CUSTOMER_IMPACT_SELECT_VALUES = select_options_maker(customer_impact_values);
export const DETECTED_BY_SELECT_VALUES = select_options_maker(detected_by_values);
export const ENTRY_TYPE_SELECT_VALUES = select_options_maker(entry_type_values);
export const ON_CALL_SELECT_VALUES = select_options_maker(on_call_values);
export const TENANT_SELECT_VALUES = select_options_maker(tenant_values);
export const WHERE_SELECT_VALUES = select_options_maker(where_values);
// marketing form dropdowns
export const MARKETING_AREA_SELECT_VALUES = select_options_maker(marketing_area_values);
export const MARKETING_SUB_AREA_SELECT_VALUES = select_options_maker(marketing_sub_area_values);
export const MAIN_REGION_VALUES = select_options_maker(main_region_values);
export const MAIN_VERTICAL_VALUES = select_options_maker(main_vertical_values);
export const MAIN_TOPIC_VALUES = select_options_maker(main_topic_values);
export const MAIN_GOAL_VALUES = select_options_maker(main_goal_values);
export const TRACE_EXPORT_TYPES = select_options_maker(trace_export_types);
export const TRACE_PROTOCOLS = select_options_maker(trace_protocols);
// forecast form dropdowns
export const FORECAST_MONTH_SELECT_VALUES = select_options_maker(forecast_months);
export const FORECAST_REGION_SELECT_VALUES = select_options_maker(forecast_regions);

//  TISP API Probes
export const TISP_COUNTRIES = select_options_maker(tisp_countries);
export const TISP_MANDANTS = select_options_maker(tisp_mandants);
export const TISP_APP_CLIENTS = select_options_maker(tisp_app_clients);

//NCE OS
export const NCE_OS_TYPE_SELECT_VALUES = select_options_maker(nce_os_type_values);
export const NCE_OS_TRIGGER_SELECT_VALUES = select_options_maker(nce_os_trigger_values);
export const NCE_OS_AREA_SELECT_VALUES = select_options_maker(nce_os_area_values);

// On Call
export const ON_CALL_STATUS_SELECT_VALUES = select_options_maker(on_call_status_values);
