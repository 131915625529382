import React from "react";
import { Alert, AlertTitle, Grid } from "@mui/material"
import SimpleCard from "components/molecules/card";
import Clock from "components/atoms/clock";

import DEFINED_CARDS from "constant_variables/cards";
import { user_state } from "components/root";
import { useRecoilState } from "recoil";



export const HomePage = () => {
  const [userState] = useRecoilState(user_state);
  const { permissions } = userState;
  const filtered_cards = { ...DEFINED_CARDS }

  const cologne_timezone = 'Europe/Berlin';
  const riga_timezone = 'Europe/Riga';
  const miami_timezone = 'America/New_York';
  const uruguay_timezone = 'America/Montevideo';
  const tokyo_timezone = 'Asia/Tokyo';

  if (permissions) {
    Object.keys(permissions).map(permission => {
      if (!permissions[permission]) delete filtered_cards[permission]
    })
  }
  return (
    <React.Fragment>
      <div>
        <Alert severity="info">
          <AlertTitle>
            Important information
          </AlertTitle>
          If you lack permissions to access a certain part of the application please contact <a href="mailto:nikita.pavlovs@1nce.com">Nikita Pavlovs</a> or <a href="mailto:arnis.zeps@1nce.com">Arnis Zeps</a>
        </Alert>
      </div>
      <div>
        <h3>Office times:</h3>
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <div>
            <Clock flag="US" city="Miami" timezone={miami_timezone} />
          </div>
          <div>
            <Clock flag="UY" city="Montevideo" timezone={uruguay_timezone} />
          </div>
          <div>
            <Clock flag="DE" city="Cologne" timezone={cologne_timezone} />
          </div>
          <div>
            <Clock flag="LV" city="Riga" timezone={riga_timezone} />
          </div>
          <div>
            <Clock flag="JP" city="Tokyo" timezone={tokyo_timezone} />
          </div>
        </div>
      </div>

      <Grid container spacing={3} justifyContent="center" alignItems="center" sx={{ marginTop: '2em' }}>
        {Object.values(filtered_cards)
          .map((c) => (
            <Grid key={c.title} item xs={12} sm={6} md={4} lg={4} xl={2} >
              <SimpleCard card={c} />
            </Grid>
          ))}
      </Grid>
    </React.Fragment>

  )
}

export default HomePage;
