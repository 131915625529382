/* eslint-disable no-unused-vars */
import { Stack, Typography, Switch } from '@mui/material'
import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { handleApi } from "../../../api/api";
import { definedApiMethods } from "../../../api";
import Radio from '@mui/material/Radio';

const JiraPage = () => {
    const [lang, setLang] = React.useState("en");
    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    React.useEffect(() => {
        const getApi = async() => {
            try { 
                const resp = await handleApi(`https://vozh7huad0.execute-api.eu-central-1.amazonaws.com/dev/compax/performance-page?language=${lang}`, definedApiMethods.get);
                const { data, fields } = resp;
                setData(data)
            } catch(err) {
                console.trace(err)
            }
        }
        getApi()
        setLoading(false);
    }, [lang])

    if (loading) {
        return <div> Loading </div>
    }

    return ( 
        <div style={{ marginTop: "50px"}}>
            <div>
             JIRA PAGE
            </div>
            <Stack direction="row" spacing={1} alignItems="center">
                <Typography>EN</Typography>
                    <Switch checked={lang === "de"} onClick={() => setLang(lang === "de" ? "en" : "de")} />
                <Typography>DE</Typography>
            </Stack>
                    <TableContainer component={Paper} >
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell> Ticket (dev purposes only)</TableCell>
                                    <TableCell> Created </TableCell>
                                    <TableCell> Component </TableCell>
                                    <TableCell> Information </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.map((d, i) => {
                                    return d.subtasks.map((r) => 
                                    <TableRow key={r.created} sx={{ backgroundColor: `${i % 2 ? "white" : "lightGray"}` }}> 
                                        <TableCell> {d.SK}</TableCell>
                                        <TableCell> {r.created}</TableCell>
                                        <TableCell> {d.serviceimpactarea}</TableCell>
                                        <TableCell> {r.description} </TableCell>
                                    </TableRow>
                                    )
                                })}
                            </TableBody>
                        </ Table>
                    </TableContainer>
               
        </div>
     );
}
 
export default JiraPage;    