
import React from 'react'

import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleIcon from "@mui/icons-material/People";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { NavLink } from 'react-router-dom';
import { useRecoilState } from "recoil";
import { user_state } from "components/root";

const MainListItems = () => {
    const [userState, setUserState] = useRecoilState(user_state);
    const [open, setOpen] = React.useState(false);

    const handleLogOut = () => {
        sessionStorage.removeItem("user");
        sessionStorage.removeItem("token")
        setUserState(null);
    }

    const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };

    return ( 
        <React.Fragment>
             <ListItem>
                <ListItemText primary={userState.email} />
            </ListItem>
            <ListItem component={NavLink} button to="/"activeStyle={{
                fontWeight: "bold",
                backgroundColor: "#e6f2ff"
                }} >
            <ListItemIcon>
                <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Home" />
            </ListItem>
            <ListItem button onClick={handleClickOpen}>
                <ListItemIcon>
                    <PeopleIcon />
                </ListItemIcon>
                <ListItemText primary="Logout" />
            </ListItem>
             <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                {"Are you sure you want to delete the entry?"}
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Are you sure you want to logout?
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={() => handleClose()}>Cancel</Button>
                <Button onClick={() => handleLogOut()} sx={{ color: "green" }} autoFocus>
                    Logout
                </Button>
                </DialogActions>
            </Dialog> 
        </React.Fragment>
    )
    };
 

export default MainListItems;