import React, { useState } from "react";
import Button from "@mui/material/Button";
import SimplePageTitle from "components/atoms/simple_page_title";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

import { importForecast, forecast_post_batch } from "api/forecast";

import { toast } from "react-toastify";
import FileDropZone from "components/molecules/file_drop_zone";
import SimpleDataGrid from "components/organisms/data_grid";

import forecast_order from "constant_variables/forecast_fields";
import { useRecoilState } from "recoil";
import { user_state } from "components/root";

const ForecastImport = () => {
    const [loading, setLoading] = useState(false);
    const [files, setFiles] = useState([]);
    const [preview, setPreview] = useState([]);
    const [userState] = useRecoilState(user_state);
    let read_only = true;
    if (userState !== null && userState.permissions) {
        read_only = userState.permissions.read_only;
    }
    const handle_import = async () => {
        setLoading(true);
        try {
            const resp = await importForecast(files);
            setPreview(resp);
            toast.success("Forecast entries parsed");
        } catch (err) {
            console.trace(err);
            toast.error("Failed");
        } finally {
            setLoading(false);
            setFiles([]);
        }
    };

    const handle_add_to_log = async () => {
        setLoading(true);
        try {
            await forecast_post_batch({ entries: preview });
            setPreview([]);
            toast.success("Forecast entries added");
        } catch (err) {
            console.trace(err);
            toast.error("Failed");
        } finally {
            setLoading(false);
            setPreview([]);
        }
    };

    const handle_add_file = (value) => {
        setFiles((old) => [...old, ...value]);
    };

    const handle_remove_file = (name) => {
        const newFiles = files.filter((f) => f.name !== name);
        setFiles(newFiles);
    };

    return (
        <React.Fragment>
            <SimplePageTitle title="Import Sales Forecast Entries" />
            <Alert severity="info">
                <AlertTitle>
                    <b>Please provide .xlsx file</b>
                </AlertTitle>
            </Alert>
            <FileDropZone
                loading={loading}
                disabled={loading}
                id="files"
                files={files}
                setFiles={handle_add_file}
                onRemove={handle_remove_file}
            />
            <Button
                disabled={read_only || loading || files.length === 0}
                variant="contained"
                onClick={() => handle_import()}
                sx={{ marginTop: "1em" }}
            >
                Import
            </Button> 
            {preview && preview.length > 0 && (
                <>
                    <SimpleDataGrid loading={loading} data={preview} order={forecast_order} />
                    <Button
                        disabled={read_only || loading}
                        variant="contained"
                        onClick={() => handle_add_to_log()}
                        sx={{ marginTop: "1em" }}
                    >
                        Add to Forecast Logs
                    </Button>
                </>
            )}
        </React.Fragment>
    );
};

export default ForecastImport;
