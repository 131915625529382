/* eslint-disable react-hooks/exhaustive-deps */

// import Button from "@mui/material/Button";
import React, { useState, useEffect, useCallback } from "react";

import { postOnCallQuery } from "api/on_call";

import SimpleDataGrid from "components/organisms/data_grid";
import SimplePageTitle from "components/atoms/simple_page_title";
import { on_call_table_state, user_state } from "components/root";
import { useRecoilState } from "recoil";
import { toast } from "react-toastify";
import on_call_order from "constant_variables/on_call_fields";

export default function OnCallTable() {
  const [showData, setShowData] = useRecoilState(on_call_table_state);
  const [userState] = useRecoilState(user_state);
  const [loading, setLoading] = useState(true);
  const [limit, setLimit] = useState(101);
  const [page, setPage] = useState(0);
  let read_only = true;
    if (userState !== null && userState.permissions) {
        read_only = userState.permissions.read_only;
    }

  const getViewData = useCallback(() => {
    const request = async() => {
      try {
        setLoading(true)
        const resp = await postOnCallQuery({
          PK: "OnCallLog",
          limit,
        });
        setShowData(resp);
      } catch (err) {
        console.trace(err);
        toast.error("Failed to gather data")
      } finally {
        setLoading(false);
      }
    }

    request();
   
  }, [limit]);
  
  const getAllData = () => {
    if (limit === 101) {
      setLimit(0);
      setPage(1)
    }
  }
  

  useEffect(() => {
    getViewData();
  }, [getViewData]);

  let adjustedOrder = on_call_order
  if (read_only) {
    adjustedOrder = on_call_order.filter(item => item.field !== "__delete__");
  }
  return (
    <>
      <SimplePageTitle  title="On Call Log Table" />
      <SimpleDataGrid
        loading={loading}
        data={showData}
        order={adjustedOrder}
        id={false}
        getAllData={getAllData}
        page={page}
        setPage={setPage}
      />
    </>
  );
}
