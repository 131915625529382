/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useRecoilState } from "recoil";

import { trace_form_state } from "components/root";
import TraceForm from "components/organisms/trace_form";
import SimplePageTitle from "components/atoms/simple_page_title";

import { toast } from "react-toastify";
import postTraceQuery from "api/trace";

import Alert from "@mui/material/Alert";

import { get_duration_value } from "helper";
import { trace_field_enum } from "constant_variables/trace_fields";

const Trace = () => {
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useRecoilState(trace_form_state);
  const [request, setRequest] = useState("");

  const handle_form_change = (key, value) => {
    setForm((old) => ({
      ...old,
      [key]: value,
    }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    let error = false;
    try {
      const resp = await postTraceQuery(form);
      setRequest(resp);
    } catch (err) {
      console.trace(err);
      error = true;
    } finally {
      setLoading(false);
      if (error) toast.error("Failed");
      else toast.success("Success");
    }
  };

  const duration = get_duration_value(form[trace_field_enum.start_time], form[trace_field_enum.end_time])
  const disabled = duration > 901 && form["export_type"].includes("pcap");
  return (
    <React.Fragment>
      <SimplePageTitle title="Trace Query" />
      {disabled && <Alert sx={{ marginBottom: "2em"}}severity="error"> 
        In order to export as PCAP duration has to be less than <b> 15 minutes </b> 
       </Alert> }
      <TraceForm
        form={form}
        loading={loading}
        submit={onSubmit}
        handleChange={handle_form_change}
        disabled={disabled}
      /> 
      { request && 
          <Alert severity="info"> {`Your request ID is ${request} `},  you can see results <b>  <a href={`/trace/request/${request}`}> here </a> </b> </Alert> 
        }
    </React.Fragment>
  );
};

export default Trace;
