import React, { useState } from "react";
import Button from "@mui/material/Button";
import SimplePageTitle from "components/atoms/simple_page_title";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

import { importContract } from "api/contract";

import { toast } from "react-toastify";
import FileDropZone from "components/molecules/file_drop_zone";

import { useRecoilState } from "recoil";
import { user_state } from "components/root";

const ContractImport = () => {
    const [loading, setLoading] = useState(false);
    const [files, setFiles] = useState([]);
    const [userState] = useRecoilState(user_state);
    let read_only = true;
    if (userState !== null && userState.permissions) {
        read_only = userState.permissions.read_only;
    }
    const handle_import = async () => {
        setLoading(true);
        try {
            await importContract(files);
            toast.success("Contract entries parsed");
        } catch (err) {
            console.trace(err);
            toast.error("Failed");
        } finally {
            setLoading(false);
            setFiles([]);
        }
    };

    const handle_add_file = (value) => {
        setFiles((old) => [...old, ...value]);
    };

    const handle_remove_file = (name) => {
        const newFiles = files.filter((f) => f.name !== name);
        setFiles(newFiles);
    };

    return (
        <React.Fragment>
            <SimplePageTitle title="Import Contract Entries" />
            <Alert severity="info">
                <AlertTitle>
                    <b>Please provide .xlsx file</b>
                </AlertTitle>
            </Alert>
            <FileDropZone
                loading={loading}
                disabled={loading}
                id="files"
                files={files}
                setFiles={handle_add_file}
                onRemove={handle_remove_file}
            />
            <Button
                disabled={read_only || loading || files.length === 0}
                variant="contained"
                onClick={() => handle_import()}
                sx={{ marginTop: "1em" }}
            >
                Import
            </Button> 
        </React.Fragment>
    );
};

export default ContractImport;
