import React from "react";

import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";

import SimpleDateTimePicker from "components/atoms/simple_date_time_picker";
import SimpleSingleSelect from "components/atoms/simple_single_select";

import GridField from "components/molecules/grid_field";

import PropTypes from "prop-types";
import FileDropZone from "components/molecules/file_drop_zone";

import {
  AREA_SELECT_VALUES, 
  CUSTOMER_IMPACT_SELECT_VALUES,
  DETECTED_BY_SELECT_VALUES,
  ENTRY_TYPE_SELECT_VALUES,
  ON_CALL_SELECT_VALUES,
  TENANT_SELECT_VALUES,
  WHERE_SELECT_VALUES
} from "constant_variables/drop_down_values";

import FIELD_SIZE  from "constant_variables";
import SubmitButton from "components/atoms/submit_button";
import DurationField from "components/atoms/duration_field";

const LogbookForm = ({ submit, files, addFile, onRemove, loading, form, handleChange, disabled, editMode }) => {

  return (
    <form
    noValidate
    autoComplete="off"
    style={{ width: "80%", margin: "auto" }}
    onSubmit={submit}
  >
    <Grid container spacing={3} alignItems="center">
      <GridField loading={loading} size={FIELD_SIZE.large}>
        <SimpleDateTimePicker
          id="start-time-cetcest"
          label="Start date (UTC)"
          value={form["start-time-cetcest"]}
          handleChange={handleChange}
          disabled={disabled}
        />
      </GridField>
      <GridField loading={loading} size={FIELD_SIZE.large}>
        <SimpleDateTimePicker
          id="end-time-cetcest"
          label="End date (UTC)"
          value={form["end-time-cetcest"]}
          handleChange={handleChange}
          disabled={disabled}
        />
      </GridField>
      <GridField loading={loading} size={FIELD_SIZE.large}>
        <DurationField from={form["start-time-cetcest"]} to={form["end-time-cetcest"]} handleChange={handleChange}/>
      </GridField>
      <GridField loading={loading} size={FIELD_SIZE.medium}>
        <SimpleSingleSelect
          id="area"
          label="Area"
          value={form["area"]}
          handleChange={handleChange}
          options={AREA_SELECT_VALUES}
          disabled={disabled}
        />
      </GridField>
      <GridField loading={loading} size={FIELD_SIZE.medium}>
        <SimpleSingleSelect
          id="entry-type"
          label="Entry Type"
          value={form["entry-type"]}
          handleChange={handleChange}
          options={ENTRY_TYPE_SELECT_VALUES}
          disabled={disabled}
        />
      </GridField>
      <GridField loading={loading} size={FIELD_SIZE.medium}>
        <SimpleSingleSelect
          id="tenant"
          label="Tenant"
          value={form["tenant"]}
          handleChange={handleChange}
          options={TENANT_SELECT_VALUES}
          disabled={disabled}
        />
      </GridField>
      <GridField loading={loading} size={FIELD_SIZE.medium}>
        <SimpleSingleSelect
          id="where"
          label="Where"
          value={form["where"]}
          handleChange={handleChange}
          options={WHERE_SELECT_VALUES}
          disabled={disabled}
        />
      </GridField>
      <GridField loading={loading} size={FIELD_SIZE.medium}>
        <SimpleSingleSelect
          id="customer-impact"
          label="Customer Impact"
          value={form["customer-impact"]}
          handleChange={handleChange}
          options={CUSTOMER_IMPACT_SELECT_VALUES}
          disabled={disabled}
        />
      </GridField>
      <GridField loading={loading} size={FIELD_SIZE.medium}>
        <SimpleSingleSelect
          id="detected-by"
          label="Detected By"
          value={form["detected-by"]}
          handleChange={handleChange}
          options={DETECTED_BY_SELECT_VALUES}
          disabled={disabled}
        />
      </GridField>
      <GridField loading={loading} size={FIELD_SIZE.medium}>
        <SimpleSingleSelect
          id="on-call"
          label="On Call"
          value={form["on-call"]}
          handleChange={handleChange}
          options={ON_CALL_SELECT_VALUES}
          disabled={disabled}
        />
      </GridField>
      <GridField loading={loading}>
        <TextField
          id="what"
          label="What"
          value={form["what"]}
          onChange={(e) => handleChange("what", e.target.value)}
          fullWidth
          disabled={disabled}
          multiline
        />
      </GridField>
      <GridField loading={loading}>
        <TextField
          id="ticket-change-id"
          label="Ticket / Change Id"
          value={form["ticket-change-id"]}
          onChange={(e) =>
            handleChange("ticket-change-id", e.target.value)
          }
          fullWidth
          disabled={disabled}
        />
      </GridField>
      <GridField loading={loading}>
        <TextField
          id="root-cause-comments"
          label="Root Cause / Comments"
          value={form["root-cause-comments"]}
          multiline
          onChange={(e) =>
            handleChange("root-cause-comments", e.target.value)
          }
          fullWidth
          disabled={disabled}
        />
      </GridField>
      <GridField loading={loading}>
        <TextField
          id="followed-up-by"
          label="Followed Up By"
          value={form["followed-up-by"]}
          multiline
          onChange={(e) =>
            handleChange("followed-up-by", e.target.value)
          }
          fullWidth
          disabled={disabled}
        />
      </GridField>
      <Grid item xs={12} sm={12} lg={12}> 
        <FileDropZone loading={loading} disabled={disabled} id="files" files={files} setFiles={addFile} onRemove={onRemove} />
      </Grid> 
      <Grid item xs={12} sm={12} lg={12} sx={{ textAlign: "center"}}>
          <SubmitButton 
            label="submit"
            onClick={submit}
            disabled={!editMode && disabled}
          />
      </Grid>
    </Grid>
  </form>
  );
};

LogbookForm.propTypes = {
    loading: PropTypes.bool,
    form: PropTypes.object,
    submit: PropTypes.func,
    handleChange: PropTypes.func,
    disabled: PropTypes.bool,
    editMode: PropTypes.bool,
    files: PropTypes.arrayOf(PropTypes.any),
    addFile: PropTypes.func,
    onRemove: PropTypes.func,
  };
  

export default LogbookForm;
