import { handleApi } from "./api";
import { ApiGateWayEndPoint, definedRouteTypes, definedApiMethods } from ".";

const getFind = async (query) => {
    const url = `${ApiGateWayEndPoint}/${definedRouteTypes.find}?${query}`;
    const resp = await handleApi(url, definedApiMethods.get);
    return resp;
};

export const postFind = async (PK, SK, MAINTENANCE = false) => {
    const url = `${ApiGateWayEndPoint}/${definedRouteTypes.find}`;
    const body = {
        PK,
        SK,
        MAINTENANCE,
    };
    const resp = await handleApi(url, definedApiMethods.post, body);
    return resp;
};

export default getFind;
