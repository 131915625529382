import { handleApi } from "./api";
import { ApiGateWayEndPoint, definedRouteTypes, definedApiMethods, definedMaintenanceResources, definedKpiResources } from ".";

const postMaintenance = async (body) => {
  const url = `${ApiGateWayEndPoint}/${definedRouteTypes.maintenances}`;
  const resp = await handleApi(url, definedApiMethods.post, { form: body });
  return resp;
};

export const runKpiUpdate = async() => {
  const url = `${ApiGateWayEndPoint}/${definedRouteTypes.maintenances}/${definedMaintenanceResources.kpi}/${definedKpiResources.run}`
  const resp = await handleApi(url, definedApiMethods.post, {});
  return resp;
}

export default postMaintenance;
