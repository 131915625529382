import { logbook_order_maker, field_maker } from "helper";

export const logbook_field_enum = {
    id: "id",
    files: "files",
    start_time_cetcest: "start-time-cetcest",
    end_time_cetcest: "end-time-cetcest",
    dur_hhmm: "dur-hhmm",
    area: "area",
    entry_type: "entry-type",
    tenant: "tenant",
    creator: "creator",
    on_call: "on-call",
    where: "where",
    customer_impact: "customer-impact",
    detected_by: "detected-by",
    ticket_change_id: "ticket-change-id",
    what: "what",
    root_cause_comments: "root-cause-comments",
    followed_up_by: "followed-up-by",
    created_by: "createdBy"
}

const logbook_fields = {
    ...field_maker("id", "-", "string"),
    ...field_maker("files", "Files", "string"),
    ...field_maker("start-time-cetcest", "Start Time (UTC)", "date"),
    ...field_maker("end-time-cetcest", "End Time (UTC)", "date"),
    ...field_maker("dur-hhmm", "Duration", "number"),
    ...field_maker("area", "Area", "string"),
    ...field_maker("entry-type", "Entry Type", "string"),
    ...field_maker("tenant", "Tenant", "string"),
    ...field_maker("creator", "Creator", "string"),
    ...field_maker("on-call", "On-Call", "boolean"),
    ...field_maker("where", "Where", "string"),
    ...field_maker("customer-impact", "Cust. Impact", "string"),
    ...field_maker("detected-by", "Detected By", "string"),
    ...field_maker("ticket-change-id", "Ticket / Change Id"),
    ...field_maker("what", "What", "string"),
    ...field_maker("root-cause-comments", "Root Cause Comments", "string"),
    ...field_maker("followed-up-by", "Followed Up By", "string"),
    ...field_maker("createdBy", "Created By", "string"),
    ...field_maker("__delete__", "-", "string"),
}

const logbook_order = [
    logbook_order_maker(logbook_fields["id"], 160),
    logbook_order_maker(logbook_fields["files"], 40),
    logbook_order_maker(logbook_fields["start-time-cetcest"], 200),
    logbook_order_maker(logbook_fields["end-time-cetcest"], 200),
    logbook_order_maker(logbook_fields["dur-hhmm"], 180),
    logbook_order_maker(logbook_fields["area"], 140),
    logbook_order_maker(logbook_fields["entry-type"], 180),
    logbook_order_maker(logbook_fields["tenant"], 130),
    logbook_order_maker(logbook_fields["what"], 500),
    logbook_order_maker(logbook_fields["on-call"], 150),
    logbook_order_maker(logbook_fields["where"], 120),
    logbook_order_maker(logbook_fields["customer-impact"], 140),
    logbook_order_maker(logbook_fields["detected-by"], 160),
    logbook_order_maker(logbook_fields["ticket-change-id"]),
    logbook_order_maker(logbook_fields["root-cause-comments"], 500),
    logbook_order_maker(logbook_fields["followed-up-by"], 200),
    logbook_order_maker(logbook_fields["createdBy"], 200),
    logbook_order_maker(logbook_fields["creator"], 180),
    logbook_order_maker(logbook_fields["__delete__"], 150),
]

export default logbook_order;




