import { handleApi } from "./api";
import {
    TISPApiProbesEndpoint,
    definedRouteTypes,
    definedApiMethods,
    definedApiProbeResources,
} from ".";

export const getProbeResults = () => {
    const url = `${TISPApiProbesEndpoint}/${definedRouteTypes.api_probes}/${definedApiProbeResources.get_results}`;
    return handleApi(url, definedApiMethods.get);
};

export const launchProbe = (params) => {
    const url = `${TISPApiProbesEndpoint}/${definedRouteTypes.api_probes}/${definedApiProbeResources.handle_tests}`;
    return handleApi(url, definedApiMethods.post, params);
};



