/* eslint-disable no-unused-vars */

import React from "react";
import DateTimePicker from '@mui/lab/DateTimePicker';
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField';
import { useGridApiContext } from "@mui/x-data-grid";
import { date_formatter } from "api";



const DateTimeCell = ({ props }) => {
  const inputProps = {
      sx: {fontSize: "14px", border: "none"}
  }
  const { id, value, field } = props;
  const apiRef = useGridApiContext();

  const handleChange = (value) => {
    apiRef.current.setEditCellValue({ id, field, value, debounceMs: 200 });
  };
  return (
      <DateTimePicker
    //   id={id}
      ampm={false}
      renderInput={(props) => <TextField {...props} fullWidth InputProps={inputProps} />}
      disableOpenPicker={true}
      value={value}
      mask={"____-__-__ __:__:__"}
      inputFormat={"yyyy-MM-dd HH:mm:ss"}
      onChange={(e)=> handleChange(e)}
    //   label={label}
    //   value={value}
    //   disabled={disabled}
    //   onChange={(newValue) => {
    //     handleChange(id, newValue);
    //   }}
      />
  )
};

DateTimeCell.propTypes = {
  props: PropTypes.object,
  value: PropTypes.string,
  source: PropTypes.string,
  row: PropTypes.object,
  id: PropTypes.number,
  field: PropTypes.string,
}

export default DateTimeCell;
