import kowalski from "assets/kowalski.png";
import traces from "assets/group5.png";
import webprobe from "assets/webprobe.png";
import marketing from "assets/marketing.png";
import Group from "assets/Group.png";
import skipper from "assets/skipper.png";

const DEFINED_CARDS = {
    logbook: {
        alt: "Logbook",
        image: kowalski,
        title: "Logbook",
        description: "Internal logs",
        route: "/logbook/table",
    },
    marketing: {
        alt: "marketing",
        image: marketing,
        title: "Marketing",
        description: "Marketing logs",
        route: "/marketing",
    },
    maintenance: {
        alt: "Maintenance",
        image: Group,
        title: "Maintenance / Outage",
        description: "Maintenance / Outage report",
        route: "/maintenance",
    },
    web_probe: {
        alt: "probes",
        image: webprobe,
        title: "Probe Analysis",
        description: "Webprobe error information",
        route: "/probe",
    },
    trace: {
        alt: "Traces",
        image: traces,
        title: "Traces",
        description: "Trace file analysis tool",
        route: "/trace",
    },
    forecast: {
        alt: "Forecast",
        image: skipper,
        title: "Sales",
        description: "Sales Forecast / Logs",
        route: "/forecast/table",
    },
};

export default DEFINED_CARDS;
