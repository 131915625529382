/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect, useCallback } from "react";

import postQuery from "../../../api/query";

import SimpleDataGrid from "components/organisms/data_grid";
import SimplePageTitle from "components/atoms/simple_page_title";
import { admin_table_state, user_state } from "components/root";
import { useRecoilState } from "recoil";
import { toast } from "react-toastify";
import _cloneDeep from "lodash.clonedeep";
import admin_order from "constant_variables/admin_fields";
import DialogButton from "components/molecules/dialog_button";
import { ButtonGroup, FormControlLabel, Switch, Alert, AlertTitle } from "@mui/material";
import CheckboxCell from "components/atoms/checkbox_cell";
import { get_venn_difference } from "helper";
import postAdmin from "api/admin";

export default function AdminTable() {
  const [showData, setShowData] = useRecoilState(admin_table_state);
  const [userState] = useRecoilState(user_state);
  const [editData, setEditData] = useState([]);
  const [loading, setLoading] = useState(true);
  // const [limit, setLimit] = useState(100);
  const [edit, setEdit]  = useState(false);

  let read_only = true;
  if (userState !== null && userState.permissions) {
      read_only = userState.permissions.read_only;
  }

  const getViewData = useCallback(() => {
    const request = async() => {
      try {
        setLoading(true)
        let resp = await postQuery({
          PK: "UserLog",
          DataAdjuster: "admin",
          limit: 200,
        });
        setShowData(resp);
        setEditData(_cloneDeep(resp))
      } catch (err) {
        console.trace(err);
        toast.error("Failed to gather data")
      } finally {
        setLoading(false);
      }
    }
    request();
   
  }, []);
  

  useEffect(() => {
    getViewData();
  }, [getViewData]);

  const handle_save = async() => {
    const updated_elements = get_venn_difference(editData, showData);
    if (updated_elements.length > 0) {
      try {
        setLoading(true);
        await postAdmin(updated_elements)
        toast.success("Entries updated!")
      } catch (err) {
        toast.error("Failed to updated entries!")
      } finally {
        setLoading(false);
        getViewData();
        setEdit(false);
      } 
    }
  }

  const handle_discard = () => {
    setEditData(showData);
  }

  const handle_check = (value, props) => {
    const admin_permissions = {
      read_only: false,
      logbook: true,
      nce_changes: true,
      nce_os_logbook: true,
      marketing: true,
      maintenance: true,
      tisp_maintenance: true,
      kpi: true,
      trace: true,
      forecast: true,
      web_probe: true,
      admin: true,
      contracts: true,
      customer_satisfaction: true,
      api_probes: true,
      redshift_exports: true,
      on_call: true,
    }
    const { row } = props;
    let new_element = {...editData[row.id], [props.field]: value }
    if (props.field === "admin" && value) {
      new_element = { ...editData[row.id], ...admin_permissions };
    }
    if (props.field === "read_only" && value) {
      new_element = {...new_element, admin: false}
    }
    const newData = [...editData];
    newData[row.id] = new_element;
    setEditData(newData)
  }

  const adjusted_order = admin_order.map(field => {
    if (edit && field.field !== "email") {
      return {...field, renderCell: (e) => <CheckboxCell props={e} field={field} onCheck={handle_check}/>}
    }
    return field;
  })

  return (
    <>
      <SimplePageTitle  title="Users Permissions Table" />
        <div>
          <Alert severity="info">
            <AlertTitle>
              Important information
            </AlertTitle>
              Read Only property disables editing rights i.e. you can only view categories that are allowed by your permissions, mutually exclusive with Admin.
              Admin allows to view and edit all categories including this table.
          </Alert>
        </div>
        <div>
          <FormControlLabel control={<Switch />} disabled={read_only} checked={edit} label="Edit" onClick={() => setEdit(!edit)} />
        </div>
         {edit  && <ButtonGroup>
           <DialogButton callToAction="Save Changes" textColor="limegreen" title="Save changes" text="Are you sure you want to save changes?" onConfirm={handle_save}/>
           <DialogButton callToAction="Discard"  textColor="red" title="Discard changes" text="Are you sure you want to discard changes?" onConfirm={handle_discard}/>
          </ButtonGroup>
          }
         
      <SimpleDataGrid
        loading={loading}
        data={edit ? editData : showData}
        order={adjusted_order}
        id={false}
      />
    </>
  );
}
