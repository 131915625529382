import { handleApi } from "./api";
import { ApiGateWayEndPoint, definedRouteTypes, definedApiMethods } from ".";

export const getNceChanges = async () => {
  const url = `${ApiGateWayEndPoint}/${definedRouteTypes.nce_changes}`;
  const resp = await handleApi(url, definedApiMethods.get);
  return resp;
};

export default getNceChanges;
