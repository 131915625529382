import { definedRouteTypes } from "api";
import { handleApi } from "./api";
import { ApiGateWayEndPoint, definedApiMethods } from ".";

const getVersion = () => {
    const url = `${ApiGateWayEndPoint}/${definedRouteTypes.version}`;
    return handleApi(url, definedApiMethods.get);
};

export default getVersion;
