import { handleApi } from "./api";
import { definedApiMethods, CustomerSatisfactionEndpoint } from ".";

export const getCustomerFeedbackUrl = () => {
    const url = `${CustomerSatisfactionEndpoint}/get-responses`;
    return handleApi(url, definedApiMethods.get);
};

export const saveTemplate = async (body) => {
    const url = `${CustomerSatisfactionEndpoint}/save-template`
    return handleApi(url, definedApiMethods.post, { ...body });
}

export const loadTemplates = async () => {
    const url = `${CustomerSatisfactionEndpoint}/load-templates`
    return handleApi(url, definedApiMethods.get);
}

export const getTemplate = async (params) => {
    const url = `${CustomerSatisfactionEndpoint}/get-template?id=${params.id}`
    return handleApi(url, definedApiMethods.get);
}

export const deleteTemplate = async (body) => {
    const url = `${CustomerSatisfactionEndpoint}/delete-template`
    return handleApi(url, definedApiMethods.post, { ...body });
}