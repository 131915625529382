/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect, useCallback } from "react";

import postQuery from "../../../api/query";

import SimpleDataGrid from "components/organisms/data_grid";
import SimplePageTitle from "components/atoms/simple_page_title";
import { marketing_table_state, user_state } from "components/root";
import { useRecoilState } from "recoil";
import { toast } from "react-toastify";
import marketing_order from "constant_variables/marketing_fields";

export default function MarketingTable() {
  const [showData, setShowData] = useRecoilState(marketing_table_state);
  const [userState] = useRecoilState(user_state);
  const [loading, setLoading] = useState(true);
  // const [limit, setLimit] = useState(100);

  let read_only = true;
    if (userState !== null && userState.permissions) {
        read_only = userState.permissions.read_only;
    }

  const getViewData = useCallback(() => {
    const request = async() => {
      try {
        setLoading(true)
        const resp = await postQuery({
          PK: "MarketingLog",
          DataAdjuster: "marketing",
          limit: 200,
        });
        setShowData(resp);
      } catch (err) {
        console.trace(err);
        toast.error("Failed to gather data")
      } finally {
        setLoading(false);
      }
    }
    request();
   
  }, []);
  

  useEffect(() => {
    getViewData();
  }, [getViewData]);

  let adjustedOrder = marketing_order
  if (read_only) {
    adjustedOrder = marketing_order.filter(item => item.field !== "__delete__");
  }
  return (
    <>
      <SimplePageTitle  title="Marketing Activity Table" />
      {/* <Button onClick={() => setLimit(null)} sx={{ backgroundColor: "#1e4d7d !important", color: "white !important"}} > Request all data </Button> */}
      <SimpleDataGrid
        loading={loading}
        data={showData}
        order={adjustedOrder}
        id={false}
      />
    </>
  );
}
