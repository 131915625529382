import React from "react";
import BuilderHelper from "../builderHelper"
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";

function Options({ item, index, moveOption, deleteOption }) {

    return (
        <Grid
            container
            key={`${index}-option-grid`}
            rowSpacing={2}
            columns={{ xs: 6, md: 6 }}
            columnSpacing={{ xs: 3, sm: 3, md: 3 }}
        >
            <Grid item xs={5}>
                <TextField
                    sx={{
                        marginTop: "15px",
                        width: "100%"
                    }}
                    id="outlined-read-only-input"
                    label={`Option ${index + 1}`}
                    value={item}
                    InputProps={{
                        readOnly: true,
                    }}
                />
            </Grid>
            <Grid
                item
                key={index}
                xs={1}
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <BuilderHelper
                    moveElement={moveOption}
                    deleteElement={deleteOption}
                    index={index} />
            </Grid>
        </Grid>
    );
}

Options.propTypes = {
    item: PropTypes.string,
    index: PropTypes.number,
    moveOption: PropTypes.func,
    deleteOption: PropTypes.func
};

export default Options;
