/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Box from '@mui/material/Box';

//FORM
import SubmitButton from "components/atoms/submit_button";
import Grid from "@mui/material/Grid";
import {
    TISP_COUNTRIES,
    TISP_APP_CLIENTS
} from "constant_variables/drop_down_values";
import SimpleSingleSelect from "components/atoms/simple_single_select";
import GridField from "components/molecules/grid_field";
import FIELD_SIZE from "constant_variables";

const ApiProbesForm = ({ submit, form, handleChange, disabled }) => {
    return (
        <Box
            sx={{ width: "80%", margin: "auto" }}
        >
            <Grid container spacing={3} alignItems="center">
                <GridField size={FIELD_SIZE.medium}>
                    <SimpleSingleSelect
                        id="country"
                        label="Country"
                        value={form["country"]}
                        handleChange={handleChange}
                        options={TISP_COUNTRIES}
                    />
                </GridField>
                <GridField size={FIELD_SIZE.large}>
                    <SimpleSingleSelect
                        id="app_client"
                        label="TISP App Client"
                        value={form["app_client"]}
                        handleChange={handleChange}
                        options={TISP_APP_CLIENTS}
                    />
                </GridField>
                <GridField size={FIELD_SIZE.medium}>
                    <SubmitButton
                        onClick={submit}
                        label="Launch Probe"
                    />
                </GridField>
            </Grid>
        </Box>
    );
};

ApiProbesForm.propTypes = {
    loading: PropTypes.bool,
    form: PropTypes.object,
    submit: PropTypes.func,
    handleChange: PropTypes.func,
    disabled: PropTypes.bool,
    editMode: PropTypes.bool,
};

export default ApiProbesForm;
