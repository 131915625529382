/* eslint-disable no-unused-vars */

import React, { useState, useCallback } from "react";
import Box from '@mui/material/Box';
import SimplePageTitle from "components/atoms/simple_page_title";
import defienedTests from "constant_variables/tisp_api_tests";
import { useRecoilState } from "recoil";
import { launchProbe } from "../../../api/api_probes";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
//FORM
import ApiProbesForm from "components/organisms/api_probes_form";
import { api_probes_form_state } from "components/root";
//DATA GRID
import SimpleDataGrid from "components/organisms/data_grid";
import TispApiProbeFields from "constant_variables/tisp_api_probes_fields";
//WEBSOCKET 
const uuid = require("uuid");

const ApiProbes = () => {

  const [form, setForm] = useRecoilState(api_probes_form_state);
  const [results, setResults] = useState([]);
  const [displayFailed, setDisplayFailed] = useState(false);
  const [launched, setLaunched] = useState(false);
  const [loading, setLoading] = useState(true);
  const [probeId, setProbeId] = useState("");
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(0);

  const onSocketMessage = useCallback((event) => {
    const { data } = event;
    const testResult = JSON.parse(data);
    testResult.availability = !["404", "500"].includes(testResult.status_code);
    setResults((old) => [...old, testResult]);
    setLoading(false);
  })
  
  const handle_probe_launch = async () => {
    const { app_client, country } = form;
    const tests = defienedTests(app_client, country);
    const id = uuid.v4()
    setProbeId(id);
    const socket = new WebSocket(`wss://u0t7ma47dc.execute-api.eu-central-1.amazonaws.com/prod?probeId=${id}`);
    socket.addEventListener("message", onSocketMessage);
    await launchProbe({
      tests,
      probeId: id
    });
    setLaunched(true)
  };

  const handle_form_change = (key, value) => {
    setForm((old) => ({
      ...old,
      [key]: value,
    }));
  };

  const getAllData = () => {
    if (limit === 20) {
      setLimit(0);
      setPage(1);
    }
  };

  return (
    <>
      <SimplePageTitle title="API Probes" />

      <ApiProbesForm
        handleChange={handle_form_change}
        form={form}
        submit={handle_probe_launch}
      />

      {launched ? (
        <>
          <ToggleButtonGroup
            sx={{
              marginTop: "30px"
            }}
            color="primary"
            value={displayFailed}
            exclusive
            onChange={(e, newValue) => { 
              setDisplayFailed(newValue);
            }}
            aria-label="Platform"
          >
            <ToggleButton value={false}>Show All</ToggleButton>
            <ToggleButton value={true}>Failed Only</ToggleButton>
          </ToggleButtonGroup>
          <Box sx={{ width: "100%", margin: "auto", marginTop: "30px" }}>
            <SimpleDataGrid
              sx={{ marginTop: "50px" }}
              loading={loading}
              data={displayFailed ? [...results].filter(result => !result.availability) : results}
              order={TispApiProbeFields}
              id={false}
              getAllData={getAllData}
              page={page}
              setPage={setPage}
            />
          </Box>
        </>
      ) : null}
    </>
  );
};

export default ApiProbes;
