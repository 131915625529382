import React from "react";
import FormLabel from "@mui/material/FormLabel";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import PropTypes from "prop-types";

function RadioItem({ question, updateQuestion }) {

    return (
        <>
            <FormLabel
                sx={{
                    color: "rgba(0, 0, 0, 0.6) !important",
                }}
            >
                {question.questionLabel}
            </FormLabel>
            <RadioGroup
                id="answer-radion-select"
                value={question.value}
                onChange={(e) => {
                    question.value = e.target.value
                    updateQuestion(question)
                }}
                sx={{
                    maxWidth: "350px",
                    minWidth: "350px",
                    marginBottom: "30px",
                }}
            >
                {question.options.map((item, index) => {
                    return (
                        <FormControlLabel value={item} key={`${index}-radio-item`} control={<Radio />} label={item} />
                    )
                })}
            </RadioGroup>
        </>
    );
}

RadioItem.propTypes = {
    question: PropTypes.object,
    updateQuestion: PropTypes.func
};

export default RadioItem;
