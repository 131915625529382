import { marketing_order_maker, field_maker } from "helper";

export const marketing_field_enum = {
    id: "id",
    files: "files",
    start_time_cetcest: "start-time-cetcest",
    end_time_cetcest: "end-time-cetcest",
    activity_name: "activity-name",
    area: "area",
    sub_area: "sub-area",
    main_region: "main-region",
    main_vertical: "main-vertical",
    main_topic: "main-topic",
    main_goal: "main-goal",
    comments: "root-cause-comments",
    creator: "creator",
}

const marketing_fields = {
    ...field_maker(marketing_field_enum.id, "-", "string"),
    ...field_maker(marketing_field_enum.files, "Files", "string"),
    ...field_maker(marketing_field_enum.start_time_cetcest, "Start Time (UTC)", "date"),
    ...field_maker(marketing_field_enum.end_time_cetcest, "End Time (UTC)", "date"),
    ...field_maker(marketing_field_enum.area, "Area", "string"),
    ...field_maker(marketing_field_enum.sub_area, "Sub Area", "string"),
    ...field_maker(marketing_field_enum.main_region, "Main Region", "string"),
    ...field_maker(marketing_field_enum.main_vertical, "Main Vertical", "string"),
    ...field_maker(marketing_field_enum.main_topic, "Main Topic", "string"),
    ...field_maker(marketing_field_enum.main_goal, "Main Goal", "string"),
    ...field_maker(marketing_field_enum.comments, "Comments", "string"),
    ...field_maker(marketing_field_enum.creator, "Creator", "string"),
    ...field_maker("__delete__", "-", "string"),
}

const marketing_order = [
    marketing_order_maker(marketing_fields[marketing_field_enum.id], 160),
    marketing_order_maker(marketing_fields[marketing_field_enum.files], 40),
    marketing_order_maker(marketing_fields[marketing_field_enum.start_time_cetcest], 200),
    // marketing_order_maker(marketing_fields[marketing_field_enum.end_time_cetcest], 200),
    marketing_order_maker(marketing_fields[marketing_field_enum.area], 200),
    marketing_order_maker(marketing_fields[marketing_field_enum.sub_area], 200),
    marketing_order_maker(marketing_fields[marketing_field_enum.main_region], 180),
    marketing_order_maker(marketing_fields[marketing_field_enum.main_vertical], 130),
    marketing_order_maker(marketing_fields[marketing_field_enum.main_topic], 200),
    marketing_order_maker(marketing_fields[marketing_field_enum.main_goal], 150),
    // marketing_order_maker(marketing_fields[marketing_field_enum.comments], 120),
    // marketing_order_maker(marketing_fields[marketing_field_enum.creator], 180),
    marketing_order_maker(marketing_fields["__delete__"], 150),
  ]

  export default marketing_order;




