import React from 'react'
import Typography from "@mui/material/Typography";

import PropTypes from 'prop-types';

const SimplePageTitle = ({ title, color = "#1e4d7d" }) => (
    <Typography variant="h4" component="h4" sx={{ 
        paddingTop: "15px", 
        paddingBottom: "25px", 
        color: color,
         textAlign: "center"
    }}>{title}</Typography>
);
 
SimplePageTitle.propTypes = {
    title: PropTypes.string.isRequired,
    color: PropTypes.string,
};

export default SimplePageTitle;