/* eslint-disable no-unused-vars */

import React from "react";

import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListSubheader from "@mui/material/ListSubheader";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import ChevronLeftRounded from "@mui/icons-material/ChevronLeftRounded";

import SecondaryListItems from "components/molecules/navigation_list";
import MainListItems from "components/molecules/navigation_list/main_list";
import secondaryLists from "components/molecules/navigation_list/routes";

import PropTypes from "prop-types";
import StyledDrawer from "./styles";

// import { Link } from "@mui/material";

import DownloadIcon from '@mui/icons-material/Download';
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Tooltip from "@mui/material/Tooltip";

import { useRecoilState } from "recoil";
import { user_state } from "components/root";

const MenuDrawer = ({ open, toggleDrawer }) => {


  const [userState] = useRecoilState(user_state);
  const { permissions } = userState;
  const filteredLists = permissions
    ? secondaryLists.filter(
        (list) => permissions[list.permission_name] === true
      )
    : [];
  return (
    <StyledDrawer variant="permanent" open={open}>
      <Toolbar
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          px: [1],
        }}
      >
        <IconButton onClick={toggleDrawer}>
          <ChevronLeftRounded />
        </IconButton>
      </Toolbar>
      <Divider />
      <List>
        <MainListItems />
      </List>
      <Divider />
      <List>
        {filteredLists.map((s) => {
          return (
            <React.Fragment key={s.name}>
              <List
                subheader={
                  <ListSubheader component="div" id="nested-list-subheader">
                    {s.name}
                  </ListSubheader>
                }
              >
                <SecondaryListItems list={s.items} />
              </List>
              <Divider />
            </React.Fragment>
          ) 
        })}        
      </List>
    </StyledDrawer>
  );
};

MenuDrawer.propTypes = {
  open: PropTypes.bool,
  toggleDrawer: PropTypes.func,
};

export default MenuDrawer;
