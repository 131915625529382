import React from "react";
import PropTypes from 'prop-types'
import { TextField } from "@mui/material";
import { get_duration } from "helper";


const DurationField= ({ from, to, duration  }) => {
  
  let duration_value = duration;
  if (!duration) duration_value = get_duration(from, to)
  return <TextField
    sx= {{
      textAlign: "left"
    }}
    id="durr-hhmm"
    label="Duration"
    value={duration_value}
    disabled
    fullWidth
  />
};

DurationField.propTypes = {
  from: PropTypes.date,
  to: PropTypes.date,
  duration: PropTypes.string,
  handleChange: PropTypes.func,
}

export default DurationField;
