import { logbook_order_maker, field_maker } from "helper";

const nce_os_logbook_fields = {
    ...field_maker("nce_os_id", "-", "string"),
    ...field_maker("files", "Files", "string"),
    ...field_maker("start-time-cetcest", "Start Time (UTC)", "date"),
    ...field_maker("end-time-cetcest", "End Time (UTC)", "date"),
    ...field_maker("dur-hhmm", "Dur.", "number"),
    ...field_maker("entry_type", "Entry Type", "string"),
    ...field_maker("trigger", "Trigger", "string"),
    ...field_maker("area_final", "Area", "string"),
    ...field_maker("on_call", "On Call Overtime", "string"),
    ...field_maker("customer_impact", "Customer Impacted", "string"),
    ...field_maker("runbook_needed", "Runbook needed", "string"),
    ...field_maker("runbook_url", "Runbook URL", "string"),
    ...field_maker("root_cause_comments", "Root Cause / Comments", "string"),
    ...field_maker("details", "Details", "string"),
    ...field_maker("solve_closing_comments", "Solve / Closing Comments", "string"),
    ...field_maker("createdBy", "Created By", "string"),
    ...field_maker("__delete__", "-", "string"),
}

const nce_os_logbook_order = [
    logbook_order_maker(nce_os_logbook_fields["nce_os_id"], 160),
    logbook_order_maker(nce_os_logbook_fields["files"], 40),
    logbook_order_maker(nce_os_logbook_fields["start-time-cetcest"], 150),
    logbook_order_maker(nce_os_logbook_fields["end-time-cetcest"], 150),
    logbook_order_maker(nce_os_logbook_fields["entry_type"], 150),
    logbook_order_maker(nce_os_logbook_fields["trigger"], 140),
    logbook_order_maker(nce_os_logbook_fields["area_final"], 180),
    logbook_order_maker(nce_os_logbook_fields["on_call"], 130),
    logbook_order_maker(nce_os_logbook_fields["customer_impact"], 120),
    logbook_order_maker(nce_os_logbook_fields["runbook_needed"], 120),
    logbook_order_maker(nce_os_logbook_fields["runbook_url"], 120),
    logbook_order_maker(nce_os_logbook_fields["root_cause_comments"], 180),
    logbook_order_maker(nce_os_logbook_fields["details"], 160),
    logbook_order_maker(nce_os_logbook_fields["solve_closing_comments"]),
    logbook_order_maker(nce_os_logbook_fields["createdBy"], 150),
    logbook_order_maker(nce_os_logbook_fields["__delete__"], 150),
  ]

  export default nce_os_logbook_order;




