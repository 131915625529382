import { handleApi } from "./api";
import { ApiGateWayEndPoint, definedRouteTypes, definedApiMethods } from ".";

const postAdmin = async (body) => {
    const url = `${ApiGateWayEndPoint}/${definedRouteTypes.admin}`;
    const resp = await handleApi(url, definedApiMethods.post, { form: body });
    return resp;
};

export default postAdmin;
