import React from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormLabel from "@mui/material/FormLabel";
import PropTypes from "prop-types";

function DropdownItem({ question, updateQuestion }) {
    return (
        <>
            <FormLabel
                sx={{
                    color: "rgba(0, 0, 0, 0.6) !important",
                }}
            >
                {question.questionLabel}
            </FormLabel>
            <Select
                labelId="answer-select"
                id="answer-select"
                value={question.value}
                onChange={(e) => {
                    question.value = e.target.value
                    updateQuestion(question)
                }}
                sx={{
                    maxWidth: "350px", 
                    minWidth: "350px",
                    marginBottom: "30px",
                }}
            >
                {question.options.map((item, index) => {
                    return (
                        <MenuItem value={item} key={`${index}-menu-item`}>{item}</MenuItem>
                    )
                })}
            </Select>
        </>

    )
}

DropdownItem.propTypes = {
    question: PropTypes.object,
    updateQuestion: PropTypes.func
};

export default DropdownItem;