
import React, { useState } from "react";

import { useRecoilState } from "recoil";

import { marketing_form_state, user_state } from "components/root";
import MarketingForm from "components/organisms/marketing_form";
import SimplePageTitle from "components/atoms/simple_page_title";
// import postLogbook from "api/logbook";

import { toast } from "react-toastify";
import postLogbook from "api/logbook";


const Marketing = () => {


  const [loading, setLoading] = useState(false);
  const [form, setForm] = useRecoilState(marketing_form_state);
  const [userState] = useRecoilState(user_state);

  let read_only = true;
    if (userState !== null && userState.permissions) {
        read_only = userState.permissions.read_only;
    }

  const handle_form_change = (key, value) => {
    setForm((old) => ({
      ...old,
      [key]: value,
    }));
  };

  const submit = async (e) => {
    setLoading(true);
    try {
      e.preventDefault();
      // post Marketing(form)
      await postLogbook(form, []);
      setForm(() => marketing_form_state);
      toast.success("Marketing entry added") 
    } catch(err) {
      console.trace(err)
      toast.error("Failed") 
    } finally {
      setLoading(false);
    }
  };


  return (
    <React.Fragment>
      <SimplePageTitle title="New Marketing Entry" />


      <MarketingForm
        form={form}
        loading={loading}
        submit={submit}
        handleChange={handle_form_change}
        disabled={read_only}
      /> 
    </React.Fragment>

  );
};

export default Marketing;
