import React from "react";
import TextField from "@mui/material/TextField";
import { Box } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";

function OptionAddBlock({options, option, setOptions, setOption}) {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                width: "100%"
            }}>
            <TextField
                id="outlined-basic"
                variant="outlined"
                label="Add option"
                sx={{
                    marginTop: "10px",
                    width: "100%"
                }}
                onChange={(event) => {
                    setOption(event.target.value)
                }}
            />
            <IconButton
                onClick={() => {
                    setOptions([...options, option]);
                }}
            >
                <AddIcon />
            </IconButton>
        </Box>
    )
}

OptionAddBlock.propTypes = {
    options: PropTypes.array,
    option: PropTypes.string,
    setOptions: PropTypes.func,
    setOption: PropTypes.func,
};

export default OptionAddBlock