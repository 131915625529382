import React, { useState } from "react";

import { useRecoilState } from "recoil";

import { nce_os_form_state, user_state } from "components/root";
import NceOSForm from "components/organisms/nce_os_form";
import SimplePageTitle from "components/atoms/simple_page_title";
import postLogbook from "api/logbook";

import { toast } from "react-toastify";


const NceOS = () => {
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useRecoilState(nce_os_form_state);
  const [userState] = useRecoilState(user_state);
  const { permissions: { read_only } } = userState;
  const [files, setFiles] = useState([]);
  const handle_form_change = (key, value) => {
    if (key === "area" || key === "area_other") {
      setForm((old) => ({
        ...old,
        [key]: value,
        area_final: value
      }));
    } else {
      setForm((old) => ({
        ...old,
        [key]: value,
      }));
    }
  };

  const clear_form = () => {
    const keys = Object.keys(form);
    keys.forEach(key => {
      if(key === "PK") return;
      if(key === "start-time-cetcest") return;
      if(key === "end-time-cetcest") return;
      setForm((old) => ({
        ...old,
        [key]: "",
      }))
    });
  }

  const handle_add_file = (value) => {
    setFiles((old) => ([
      ...old,
      ...value
    ]));
  };

  const remove_file = (name) => {
    const newFiles = files.filter((f) => f.name !== name)
    setFiles(newFiles);
  };

  const submit = async (e) => {
    e.preventDefault();
    if (!form["start-time-cetcest"]) {
      toast.error("Start Date is mandatory!")
      return;
    }
    if (!form["entry_type"]) {
      toast.error("Entry type is mandatory!")
      return;
    }
    if (!form["trigger"]) {
      toast.error("Trigger is mandatory!")
      return;
    }
    if (!form["area"]) {
      toast.error("Affected area is mandatory!")
      return;
    }
    if (form["area"] === "Other (Manual Input)" && !form["area_other"]) {
      toast.error("Affected area is mandatory!")
      return;
    }
    if (!form["on_call"]) {
      toast.error("On call Overtime is mandatory!")
      return;
    }
    if (!form["customer_impact"]) {
      toast.error("Customer impact is mandatory!")
      return;
    }
    if (!form["runbook_needed"]) {
      toast.error("Runbook needed is mandatory!")
      return;
    }
    setLoading(true);
    try {
      await postLogbook(form, files)
      clear_form();      
      setFiles([]);
      toast.success("Logbook entry added")
    } catch (err) {
      console.trace(err)
      toast.error("Failed")
    } finally {
      setLoading(false);
    }
  };
  return (
    <React.Fragment>
      <SimplePageTitle title="New Entry" />


      <NceOSForm
        form={form}
        files={files}
        addFile={handle_add_file}
        loading={loading}
        submit={submit}
        handleChange={handle_form_change}
        onRemove={remove_file}
        disabled={read_only}
      />
    </React.Fragment>

  );
};

export default NceOS;
