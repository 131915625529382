import { handleApi } from "./api";
import {
    ApiGateWayEndPoint,
    definedRouteTypes,
    definedApiMethods,
    definedContractResources,
} from ".";
import uploadFiles from "./file_api";

export const importContract = async (files) => {
    let add_files = [];
    if (files && files.length > 0) {
        const { upload_files } = await uploadFiles(files);
        add_files = upload_files;
    }
    const url = `${ApiGateWayEndPoint}/${definedRouteTypes.contracts}/${definedContractResources.import}`;
    const resp = await handleApi(url, definedApiMethods.post, { files: add_files });
    return resp;
};


export default importContract;


