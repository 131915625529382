import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import HorizontalRule  from "@mui/icons-material/HorizontalRule";

import React from "react";

import PropTypes from 'prop-types'


const TripleStateCell = ({ props }) => {
    const { value } = props;
    if (value === "yes") {
       return  <CheckIcon fontSize="small" sx={{ color: "green" }}/>
    } 
    if (value === "n/a" || value === undefined || value === "") {
       return <HorizontalRule fontSize="small" sx={{ color: "grey" }}/>
    }

    return <CloseIcon fontSize="small" sx={{ color: "red" }}/>
};

TripleStateCell.propTypes = {
    props: PropTypes.object,
    value: PropTypes.string,
};

export default TripleStateCell;
